export class HistoryStatusQC {
  action: string;
  data: string | null;
  fullName: string;
  createdAt: string;
  evaluationId: string;

  constructor(data: { action: string; data: string | null; fullName: string; createdAt: string; evaluationId: string }) {
    this.action = data.action || 'N/A';
    this.data = data.data;
    this.fullName = data.fullName || 'N/A';
    this.createdAt = data.createdAt || 'N/A';
    this.evaluationId = data.evaluationId || 'N/A';
  }
}
