import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';

import { v4 as uuidv4 } from 'uuid';
import { Subject, Subscription, take, takeUntil, timer } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { REPORT_BY } from '@admin/constant/report/volumn';
import { DOWNLOAD } from '@shared/constant/download';
import { SHOW_BY } from '@admin/constant/report/violation-sumary';

import { FormatTimeService } from '@shared/service/formatTime.service';
import { DownloadService } from '@shared/service/download.service';
import { SocketService } from '@shared/service/socket.service';
import { VolumnService } from '@admin/services/report-internal/volumn.service';
import { startOfToday, subDays } from 'date-fns';
import { OrganizationsGlobalService } from '@shared/service/organizations-global.service';
import { RIGHTS_CODE } from '@shared/constant/rights-code';
import { PermissionsService } from '@shared/service/permission.service';

interface SelectOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-search-volumn',
  templateUrl: './search-volumn.component.html',
  styleUrls: ['./search-volumn.component.scss']
})
export class SearchVolumnComponent implements OnInit, OnDestroy {
  REPORT_BY = REPORT_BY;
  RIGHTS_CODE = RIGHTS_CODE;
  @Output() dataSubmitForm = new EventEmitter<any>();
  @Input() lastSyncAt: string;
  searchVolumnForm: FormGroup;
  submitted = false;

  listShowBy: SelectOption[] = [
    { label: 'report.violation.show_by.day', value: SHOW_BY.DAY },
    { label: 'report.violation.show_by.month', value: SHOW_BY.MONTH },
    { label: 'report.violation.show_by.quarter', value: SHOW_BY.QUARTER }
  ];
  listReportBy: SelectOption[] = [
    { label: 'report.volumn.report_by.updated_date', value: REPORT_BY.UPLOAD_DATE },
    { label: 'report.volumn.report_by.contact_date', value: REPORT_BY.CONTACT_DATE }
  ];

  destroyObservable = new Subject<void>();
  exportId = uuidv4();
  exportParams: any = {};
  defaultEndDate = startOfToday();
  defaultStartDate = subDays(this.defaultEndDate, 6);
  dataOrganizations: string[];
  isDisabledKW = false;
  private subscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    public formatTimeService: FormatTimeService,
    public downloadService: DownloadService,
    private socketService: SocketService,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
    private volumnService: VolumnService,
    private organizationsGlobalService: OrganizationsGlobalService,
    public permissionsService: PermissionsService
  ) {}

  get f(): { [key: string]: AbstractControl } {
    return this.searchVolumnForm.controls;
  }

  ngOnInit(): void {
    this.initForm();
    this.handleAutoGetDataSelect('reportBy');
    this.handleAutoGetDataSelect('showBy');
    this.initDownloadService();
    this.checkOrganizations();
  }

  checkOrganizations() {
    this.subscription = this.organizationsGlobalService.valueOrganizations$.pipe(takeUntil(this.destroyObservable)).subscribe({
      next: result => {
        if (result.org) {
          this.dataOrganizations = result.org;
          this.isDisabledKW = result.IsOrg;
          this.emitDataForm();
        }
      }
    });
  }

  initForm(): void {
    this.searchVolumnForm = this.formBuilder.group({
      reportBy: [[]],
      contactDate: [null],
      showBy: [[]]
    });
    this.searchVolumnForm.get('reportBy')?.setValue(REPORT_BY.UPLOAD_DATE);
    this.searchVolumnForm.get('showBy')?.setValue(SHOW_BY.DAY);
    this.searchVolumnForm.patchValue({
      contactDate: [this.defaultStartDate, this.defaultEndDate]
    });
  }

  handleAutoGetDataSelect(selectForm: string): void {
    this.searchVolumnForm
      ?.get(selectForm)
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe(() => {
        this.emitDataForm();
      });
  }

  emitDataForm(): void {
    const formData = {
      reportBy: this.searchVolumnForm.get('reportBy')?.value || null,
      showBy: this.searchVolumnForm.get('showBy')?.value || null,
      dateFrom: this.searchVolumnForm.get('contactDate')?.value?.['0']
        ? this.searchVolumnForm.get('contactDate')?.value?.['0']
        : this.defaultStartDate,
      dateTo: this.searchVolumnForm.get('contactDate')?.value?.['1']
        ? this.searchVolumnForm.get('contactDate')?.value?.['1']
        : this.defaultEndDate,
      engineIds: this.dataOrganizations
    };
    this.dataSubmitForm.emit(formData);
  }

  onSubmit() {
    this.submitted = true;
    const dateFrom = this.searchVolumnForm.get('contactDate')?.value?.['0'];
    const dateTo = this.searchVolumnForm.get('contactDate')?.value?.['1'];
    const dataForm = {
      reportBy: this.searchVolumnForm.get('reportBy')?.value,
      showBy: this.searchVolumnForm.get('showBy')?.value,
      dateFrom: dateFrom ? dateFrom : this.defaultStartDate,
      dateTo: dateTo ? dateTo : this.defaultEndDate,
      engineIds: this.dataOrganizations
    };
    this.dataSubmitForm.emit(dataForm);
  }

  setupParams() {
    const dateFrom = this.searchVolumnForm.get('contactDate')?.value?.['0'];
    const dateTo = this.searchVolumnForm.get('contactDate')?.value?.['1'];
    const dataForm = {
      reportBy: this.searchVolumnForm.get('reportBy')?.value,
      showBy: this.searchVolumnForm.get('showBy')?.value,
      dateFrom: dateFrom ? dateFrom : this.defaultStartDate,
      dateTo: dateTo ? dateTo : this.defaultEndDate,
      engineIds: this.dataOrganizations
    };
    return dataForm;
  }

  resetForm() {
    const defaultValues = {
      showBy: SHOW_BY.DAY,
      contactDate: [this.defaultStartDate, this.defaultEndDate],
      reportBy: REPORT_BY.UPLOAD_DATE
    };
    this.submitted = false;
    this.searchVolumnForm.patchValue(defaultValues);
  }

  initDownloadService() {
    this.downloadService.isDownloadHandling = false;
    this.downloadService.downloadFileObservable.pipe(takeUntil(this.destroyObservable)).subscribe(data => {
      this.downloadService.handleDownloadData(data);
      this.socketService.reportLeaveRoom(this.exportId);
      timer(DOWNLOAD.DELAY_TIME)
        .pipe(take(1))
        .subscribe(() => {
          this.downloadService.isDownloadHandling = false;
        });
    });
  }

  async exportToExcel() {
    this.socketService.reportEmitRoom(this.exportId);
    this.exportParams = this.setupParams();
    if (this.exportId) {
      this.exportParams['exportId'] = this.exportId;
    }
    this.volumnService.exportVolumn(this.exportParams).subscribe({
      next: (res: any) => {
        this.downloadService.isDownloadHandling = true;
      },
      error: (err: any) => {
        this.notificationService.create(
          'error',
          this.translateService.instant('report.export.title'),
          this.translateService.instant('report.export.error')
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyObservable.next();
    this.destroyObservable.complete();
    this.socketService.reportLeaveRoom(this.exportId);
    this.downloadService.isDownloadHandling = false;
    this.subscription.unsubscribe();
  }
}
