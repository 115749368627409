import { Injectable } from '@angular/core';
import { endOfDay, startOfDay, startOfToday, subDays } from 'date-fns';
import { FormGroup } from '@angular/forms';
import { ENDPOINT } from '@shared/config/endpoint';
import { BaseService } from '@shared/service/base.service';
import { StringHelper } from '@shared/helper/string.helper';

@Injectable()
export class DataFormService {
  constructor(private baseService: BaseService) {}

  getValueFilterAdvanced(queryParam) {
    return this.baseService.postData(`${ENDPOINT.report.filterAdvanced}`, queryParam, undefined, 150 * 1000);
  }

  removeEmptyValues(obj: any) {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([key, value]) =>
          value !== null &&
          value !== undefined &&
          !(Array.isArray(value) && value.length === 0) &&
          !(typeof value === 'object' && Object.keys(value).length === 0) &&
          !(typeof value === 'string' && value.trim() === '')
      )
    );
  }

  getFilteredValue(value: any): any {
    if (Array.isArray(value) && value.length > 0) {
      return value;
    }
    return [];
  }

  getDataFormCallAnalysis(valueForm: FormGroup, addParams?: {}) {

    const dataForm = {
      contactDateFrom: valueForm.get('contactDateFrom')?.value.toISOString(),
      contactDateTo: valueForm.get('contactDateTo')?.value.toISOString(),
      callId: valueForm.get('callId')?.value,
      keywordStatus: valueForm.get('keywordStatus')?.value,
      evaluatedDateFrom: valueForm.get('evaluationDate')?.value ? startOfDay(valueForm.get('evaluationDate')?.value).toISOString() : null,
      evaluatedDateTo: valueForm.get('evaluationDate')?.value ? endOfDay(valueForm.get('evaluationDate')?.value).toISOString() : null,
      kpiName: valueForm.get('kpiName')?.value,
      kpiStatus: valueForm.get('kpiStatus')?.value,
      kpiVersion: valueForm.get('kpiVersion')?.value,
      keywordVersion: valueForm.get('keywordVersion')?.value,
      filter: {
        agency: this.getFilteredValue(valueForm.get('agency')?.value),
        bucket: this.getFilteredValue(valueForm.get('bucket')?.value),
        team_leader: this.getFilteredValue(valueForm.get('teamLeader')?.value),
        unit_head: this.getFilteredValue(valueForm.get('unitHead')?.value),
        product: this.getFilteredValue(valueForm.get('products')?.value),
        keyword_version: this.getFilteredValue(valueForm.get('keyword_version')?.value),
        kpi_version: this.getFilteredValue(valueForm.get('kpi_version')?.value)
      },
      ...addParams
    };
    return dataForm;
  }

  getDataFormViolationSummary(valueForm: FormGroup, addParams?: {}) {
    const defaultEndDate = startOfToday();
    const defaultStartDate = subDays(defaultEndDate, 6);

    const dataForm = {
      showBy: valueForm.get('showBy')?.value || null,
      keywordVersion: valueForm.get('kwVersion')?.value,
      contactDateFrom: valueForm.get('contactDate')?.value?.['0']
        ? valueForm.get('contactDate')?.value?.['0'].toISOString()
        : defaultStartDate.toISOString(),
      contactDateTo: valueForm.get('contactDate')?.value?.['1']
        ? valueForm.get('contactDate')?.value?.['1'].toISOString()
        : defaultEndDate.toISOString(),
      filter: {
        agency: this.getFilteredValue(valueForm.get('agency')?.value),
        bucket: this.getFilteredValue(valueForm.get('bucket')?.value),
        team_leader: this.getFilteredValue(valueForm.get('teamLeader')?.value),
        unit_head: this.getFilteredValue(valueForm.get('unitHead')?.value),
        product: this.getFilteredValue(valueForm.get('products')?.value)
      },
      ...addParams
    };

    return dataForm;
  }

  getDataFormViolationAgent(valueForm: FormGroup, addParams?: {}) {
    const defaultEndDate = startOfToday();
    const defaultStartDate = subDays(defaultEndDate, 6);

    const dataForm = {
      contactDateFrom: valueForm.get('contactDate')?.value?.['0']
        ? valueForm.get('contactDate')?.value?.['0'].toISOString()
        : defaultStartDate.toISOString(),
      contactDateTo: valueForm.get('contactDate')?.value?.['1']
        ? valueForm.get('contactDate')?.value?.['1'].toISOString()
        : defaultEndDate.toISOString(),
      agentIds:
        typeof valueForm.get('agentId')?.value === 'string' && valueForm.get('agentId')?.value
          ? StringHelper.stringToArrayByCommaWithoutEmptySpace(valueForm.get('agentId')?.value)
          : [],
      keywordVersion: valueForm.get('kwVersion')?.value,
      filter: {
        agency: this.getFilteredValue(valueForm.get('agency')?.value),
        bucket: this.getFilteredValue(valueForm.get('bucket')?.value),
        team_leader: this.getFilteredValue(valueForm.get('teamLeader')?.value),
        unit_head: this.getFilteredValue(valueForm.get('unitHead')?.value),
        product: this.getFilteredValue(valueForm.get('products')?.value)
      },
      ...addParams
    };

    return dataForm;
  }

  getDataFormAttitudeKWBucket(valueForm: FormGroup, addParams?: {}) {
    const defaultEndDate = startOfToday();
    const defaultStartDate = subDays(defaultEndDate, 6);
    const dataForm = {
      contactDateFrom: valueForm.get('contactDate')?.value?.['0']
        ? valueForm.get('contactDate')?.value?.['0'].toISOString()
        : defaultStartDate.toISOString(),
      contactDateTo: valueForm.get('contactDate')?.value?.['1']
        ? valueForm.get('contactDate')?.value?.['1'].toISOString()
        : defaultEndDate.toISOString(),
      showBy: valueForm.get('showBy')?.value || null,
      keywordVersion: valueForm.get('kwVersion')?.value,
      filter: {
        agency: this.getFilteredValue(valueForm.get('agency')?.value),
        bucket: this.getFilteredValue(valueForm.get('bucket')?.value),
        team_leader: this.getFilteredValue(valueForm.get('teamLeader')?.value),
        unit_head: this.getFilteredValue(valueForm.get('unitHead')?.value),
        product: this.getFilteredValue(valueForm.get('products')?.value),
        agent_id: this.getFilteredValue(valueForm.get('agentId')?.value)
      },
      ...addParams
    };
    return dataForm;
  }
}
