export class LatestEvaluationData {
  id: string;
  version: string;
  updatedAt: string;

  constructor(data: { _id: string; version: string; updatedAt: string }) {
    this.id = data._id;
    this.version = data.version || '';
    this.updatedAt = data.updatedAt || '';
  }
}

export class ListReportViolationByAgent {
  agentName: string;
  agentId: string;
  totalEvaluatedCalls: number;
  totalViolatedCalls: number;
  totalUnviolatedCalls: number;
  violatedCallRatio: number;
  totalAttitudeKeywords: number;
  totalInsultingWords: number;
  insultingWordRatio: string;
  totalSwearingWords: number;
  swearingWordRatio: string;
  totalScaryWords: number;
  scaryWordRatio: string;
  totalIncitingWords: number;
  incitingWordRatio: string;

  constructor(data: any) {
    this.agentName = data.agentName ? data.agentName : '';
    this.agentId = data.agentId ? data.agentId : '';
    this.totalEvaluatedCalls = data.totalEvaluatedCalls ? data.totalEvaluatedCalls : 0;
    this.totalViolatedCalls = data.totalViolatedCalls ? data.totalViolatedCalls : 0;
    this.totalUnviolatedCalls = data.totalUnviolatedCalls ? data.totalUnviolatedCalls : 0;
    this.violatedCallRatio = data.violatedCallRatio ? data.violatedCallRatio : '';
    this.totalAttitudeKeywords = data.totalAttitudeKeywords ? data.totalAttitudeKeywords : 0;
    this.totalInsultingWords = data.totalInsultingWords ? data.totalInsultingWords : 0;
    this.insultingWordRatio = data.insultingWordRatio ? data.insultingWordRatio : '';
    this.totalSwearingWords = data.totalSwearingWords ? data.totalSwearingWords : 0;
    this.swearingWordRatio = data.swearingWordRatio ? data.swearingWordRatio : '';
    this.totalScaryWords = data.totalScaryWords ? data.totalScaryWords : 0;
    this.scaryWordRatio = data.scaryWordRatio ? data.scaryWordRatio : '';
    this.totalIncitingWords = data.totalIncitingWords ? data.totalIncitingWords : 0;
    this.incitingWordRatio = data.incitingWordRatio ? data.incitingWordRatio : '';
  }
}

export interface IContentTable {
  header: {
    title: string;
    width: string;
    align: string;
  };
  record: {
    align?: string;
    field: string;
    isNumber?: boolean;
  };
  footer: {
    title?: string;
    align: string;
    field: string;
    isNumber?: boolean;
  };
}

export const HEADER_TITLE = [
  {
    header: { title: 'common.table.agent_name', width: '240px', align: 'left' },
    record: { align: 'left', field: 'agentName' },
    footer: { title: 'common.table.est_kpi.total', align: 'left', field: 'agentName' }
  },
  {
    header: { title: 'common.table.agent_id', width: '150px', align: 'left' },
    record: { align: 'left', field: 'agentId' },
    footer: { align: 'left', field: 'totalAgents', isNumber: true }
  },
  {
    header: {
      title: 'common.table.violation_by_agent.num_of_evaluated_calls',
      width: '150px',
      align: 'right'
    },
    record: { align: 'right', field: 'totalEvaluatedCalls', isNumber: true },
    footer: { align: 'right', field: 'totalEvaluatedCalls', isNumber: true }
  },
  {
    header: {
      title: 'common.table.violation_by_agent.num_of_violated_calls',
      width: '150px',
      align: 'right'
    },
    record: { align: 'right', field: 'totalViolatedCalls', isNumber: true },
    footer: { align: 'right', field: 'totalViolatedCalls', isNumber: true }
  },
  {
    header: {
      title: 'common.table.violation_by_agent.num_of_non_violated_calls',
      width: '150px',
      align: 'right'
    },
    record: { align: 'right', field: 'totalUnviolatedCalls', isNumber: true },
    footer: { align: 'right', field: 'totalUnviolatedCalls', isNumber: true }
  },
  {
    header: {
      title: 'common.table.violation_by_agent.ratio_of_calls_violated_attitude_keywords',
      width: '150px',
      align: 'right'
    },
    record: { align: 'right', field: 'violatedCallRatio' },
    footer: { align: 'right', field: 'violatedCallRatio' }
  },
  {
    header: {
      title: 'common.table.violation_by_agent.num_of_attitude_keywords',
      width: '150px',
      align: 'right'
    },
    record: { align: 'right', field: 'totalAttitudeKeywords', isNumber: true },
    footer: { align: 'right', field: 'totalAttitudeKeywords', isNumber: true }
  },
  {
    header: { title: 'common.table.violation_by_agent.insulting', width: '150px', align: 'right' },
    record: { align: 'right', field: 'totalInsultingWords', isNumber: true },
    footer: { align: 'right', field: 'totalInsultingWords', isNumber: true }
  },
  {
    header: { title: 'common.table.violation_by_agent.insulting_ratio', width: '150px', align: 'right' },
    record: { align: 'right', field: 'insultingWordRatio' },
    footer: { align: 'right', field: 'insultingWordRatio' }
  },
  {
    header: { title: 'common.table.violation_by_agent.swearing', width: '150px', align: 'right' },
    record: { align: 'right', field: 'totalSwearingWords', isNumber: true },
    footer: { align: 'right', field: 'totalSwearingWords', isNumber: true }
  },
  {
    header: { title: 'common.table.violation_by_agent.swearing_ratio', width: '150px', align: 'right' },
    record: { align: 'right', field: 'swearingWordRatio' },
    footer: { align: 'right', field: 'swearingWordRatio' }
  },
  {
    header: { title: 'common.table.violation_by_agent.scary', width: '150px', align: 'right' },
    record: { align: 'right', field: 'totalScaryWords', isNumber: true },
    footer: { align: 'right', field: 'totalScaryWords', isNumber: true }
  },
  {
    header: { title: 'common.table.violation_by_agent.scary_ratio', width: '150px', align: 'right' },
    record: { align: 'right', field: 'scaryWordRatio' },
    footer: { align: 'right', field: 'scaryWordRatio' }
  },
  {
    header: { title: 'common.table.violation_by_agent.inciting', width: '150px', align: 'right' },
    record: { align: 'right', field: 'totalIncitingWords', isNumber: true },
    footer: { align: 'right', field: 'totalIncitingWords', isNumber: true }
  },
  {
    header: { title: 'common.table.violation_by_agent.inciting_ratio', width: '150px', align: 'right' },
    record: { align: 'right', field: 'incitingWordRatio' },
    footer: { align: 'right', field: 'incitingWordRatio' }
  }
];

export interface IParam {
  page?: number;
  maxSize?: number;
  contactDateFrom?: string;
  contactDateTo?: string;
  agentIds?: string[];
  keywordVersion?: number;
  filter?: {};
  engineIds?: string[];
}
