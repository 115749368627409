import { Component, Input, OnInit } from '@angular/core';
import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { HistoryStatusQC } from '@admin/models/evaluation/history';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  @Input() idTicketDetail: string;
  titleHeader = [
    'common.table.history.time',
    'common.table.history.account',
    'common.table.history.behavior',
    'common.table.history.content'
  ];
  tabs = [
    {
      name: 'evaluation.ticket_details.kpi_est.status_history',
      component: 'STATUS'
    },
    {
      name: 'evaluation.ticket_details.kpi_est.score',
      component: 'POINT'
    }
  ];
  isLoading = false;
  dataTable: HistoryStatusQC[];
  totalPages: number;
  pageIndex = 1;
  pageIndexPoint = 1;
  pageSize = 10;
  nameTab = 'STATUS';

  constructor(private evaluationService: EvaluationService) {}

  ngOnInit(): void {
    this.getHistoryStatus(1, 7, 'STATUS');
  }

  handleClickTab(evt = 'STATUS') {
    this.nameTab = evt ? evt : 'STATUS';
    if (evt === 'STATUS') {
      this.getHistoryStatus(1, 7, 'STATUS');
    } else {
      this.getHistoryStatus(1, 7, 'POINT');
    }
  }

  getHistoryStatus(pageIndex: number, pageSize: number, typeHistory: string, reset = false) {
    if (reset) {
      this.pageIndex = 1;
      this.pageIndexPoint = 1;
    }
    const params = {
      page: pageIndex,
      maxSize: pageSize,
      type: typeHistory === 'STATUS' ? 'evaluationStatus' : 'evaluationScore'
    };
    this.evaluationService.isSuccessAPI(false);
    this.evaluationService.getHistory(this.idTicketDetail, params).subscribe({
      next: result => {
        this.totalPages = result['data'].total;
        this.dataTable = result['data'].data.map((item: any) => {
          return new HistoryStatusQC(item);
        });
        this.evaluationService.isSuccessAPI(true);
      },
      error: err => {
        this.dataTable = [];
        this.evaluationService.isSuccessAPI(true);
      }
    });
  }
}
