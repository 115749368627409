import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, Observable, throwError, timeout } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ENDPOINT } from '@shared/config/endpoint';

const TIMEOUT_REQUEST = 15 * 1000;

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  flagCheckToken = true;
  constructor(private http: HttpClient) {}

  paramStringArray(params: {}) {
    let paramsObj = new HttpParams();
    Object.keys(params).forEach(key => {
      if (params[key]) {
        if (Array.isArray(params[key])) {
          params[key].forEach(value => {
            paramsObj = paramsObj.append(`${key}[]`, value);
          });
        } else {
          paramsObj = paramsObj.append(key, params[key]);
        }
      }
    });
    return paramsObj;
  }

  convertParams(queryParam) {
    const validParams = Object.keys(queryParam).reduce((value, key) => {
      if (queryParam[key] !== undefined) {
        return { ...value, [key]: queryParam[key] };
      }
      return value;
    }, {});
    let queryParams = '';
    Object.keys(validParams).forEach(key => {
      queryParams += `${key}=${validParams[key]}&`;
    });
    return queryParams;
  }

  private getRequest(path?: string, timeoutMs?: number, options?: any): Observable<object> {
    return timeoutMs === 0
      ? this.http.get(`${path}`, options).pipe(
          map(res => {
            return res;
          }),
          catchError(error => {
            return throwError(error);
          })
        )
      : this.http.get(`${path}`, options).pipe(
          timeout(timeoutMs || TIMEOUT_REQUEST),
          map(res => {
            return res;
          }),
          catchError(error => {
            return throwError(error);
          })
        );
  }

  private postRequest(path?: string, timeoutMs?: number, options?: any, body?: any): Observable<object> {
    return timeoutMs === 0
        ? this.http.post(`${path}`, body, options).pipe(
          map(res => {
            return res;
          }),
          catchError(error => {
            return throwError(error);
          })
        )
        : this.http.post(`${path}`, body, options).pipe(
          timeout(timeoutMs || TIMEOUT_REQUEST),
          map(res => {
            return res;
          }),
          catchError(error => {
            return throwError(error);
          })
        );
  }

  private putRequest(path?: string, options?: any, body?: any): Observable<object>{
    return this.http.put(`${path}`, body, options).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  private deleteRequest(path?: string, options?: any): Observable<object>{
    return this.http.delete(`${path}`, options).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getData(path?: string, timeoutMs?: number): Observable<object> {
    const options = this.getHeaders();
    if(this.flagCheckToken){
      return this.getRequest(path, timeoutMs, options);
    }
    const data = this.getRefreshToken();
    const path2 = ENDPOINT.auth.refreshToken;
    return this.http.post(`${path2}`, data, options).pipe(
      timeout(TIMEOUT_REQUEST),
      switchMap((res: any) => {
          const user = res.data && typeof res.data === 'object' ? JSON.stringify(res.data) : JSON.stringify({});
          localStorage.setItem('user_info', user);
          localStorage.setItem('token', res.data.accessToken.token);
          this.flagCheckToken = true;
          const options = this.getHeaders();
          return this.getRequest(path, timeoutMs, options)
        }),
      catchError(error => {
        this.cleanAllCache();
        return throwError(error);
      })
    );
  }

  postData(path?: string, body?: any, headersPairs?: any, timeoutMs?: number): Observable<any> {
    const options = this.getHeaders(headersPairs);
    if(this.flagCheckToken) {
      return this.postRequest(path, timeoutMs, options, body);
    }
    const data = this.getRefreshToken();
    const path2 = ENDPOINT.auth.refreshToken;
    return this.http.post(`${path2}`, data, options).pipe(
      timeout(TIMEOUT_REQUEST),
      switchMap((res: any) => {
          const user = res.data && typeof res.data === 'object' ? JSON.stringify(res.data) : JSON.stringify({});
          localStorage.setItem('user_info', user);
          localStorage.setItem('token', res.data.accessToken.token);
          this.flagCheckToken = true;
          const options = this.getHeaders();
          return this.postRequest(path, timeoutMs, options, body);
        }),
      catchError(error => {
        this.cleanAllCache();
        return throwError(error);
      })
    );
  }

  postUpload(path?: string, body?: any, headersPairs?: any): Observable<any> {
    const options = this.getUploadHeaders(headersPairs);
    if(this.flagCheckToken) {
      return this.postRequest(path, 0, options, body);
    }
    const data = this.getRefreshToken();
    const path2 = ENDPOINT.auth.refreshToken;
    return this.http.post(`${path2}`, data, options).pipe(
      timeout(TIMEOUT_REQUEST),
      switchMap((res: any) => {
          const user = res.data && typeof res.data === 'object' ? JSON.stringify(res.data) : JSON.stringify({});
          localStorage.setItem('user_info', user);
          localStorage.setItem('token', res.data.accessToken.token);
          this.flagCheckToken = true;
          const options = this.getHeaders();
          return this.postRequest(path, 0, options, body);
        }),
      catchError(error => {
        this.cleanAllCache();
        return throwError(error);
      })
    );
  }

  putData(path?: string, body?: any, headersPairs?: any): Observable<any> {
    const options = this.getHeaders(headersPairs);
    if(this.flagCheckToken) {
      return this.putRequest(path, options, body);
    }
    const data = this.getRefreshToken();
    const path2 = ENDPOINT.auth.refreshToken;
    return this.http.post(`${path2}`, data, options).pipe(
      timeout(TIMEOUT_REQUEST),
      switchMap((res: any) => {
          const user = res.data && typeof res.data === 'object' ? JSON.stringify(res.data) : JSON.stringify({});
          localStorage.setItem('user_info', user);
          localStorage.setItem('token', res.data.accessToken.token);
          this.flagCheckToken = true;
          const options = this.getHeaders();
          return this.putRequest(path, options, body);
        }),
      catchError(error => {
        this.cleanAllCache();
        return throwError(error);
      })
    );
  }

  delete(path?: string, headersPairs?: any, body?: {}) {
    const options = this.getHeaders(headersPairs);
    if (body) {
      options['body'] = body;
    }
    if(this.flagCheckToken) {
      return this.deleteRequest(path, options);
    }
    const data = this.getRefreshToken();
    const path2 = ENDPOINT.auth.refreshToken;
    return this.http.post(`${path2}`, data, options).pipe(
      timeout(TIMEOUT_REQUEST),
      switchMap((res: any) => {
          const user = res.data && typeof res.data === 'object' ? JSON.stringify(res.data) : JSON.stringify({});
          localStorage.setItem('user_info', user);
          localStorage.setItem('token', res.data.accessToken.token);
          this.flagCheckToken = true;
          const options = this.getHeaders();
          return this.deleteRequest(path, options);
        }),
      catchError(error => {
        this.cleanAllCache();
        return throwError(error);
      })
    );
  }

  private async checkTokenExpired(){
    const userInfoString = localStorage.getItem('user_info');
    if (userInfoString) {
      const userInfo = JSON.parse(userInfoString)
      const expires = new Date(userInfo.accessToken.expiredTime);
      const timeout = expires.getTime() - Date.now();
      this.flagCheckToken = timeout > 0;
    }
  }

  private getHeaders(headersPairs?: any) {
    this.checkTokenExpired();
    const httpOptions = {
      headers: new HttpHeaders()
    };
    if (localStorage.getItem('token')) {
      const token: string | any = localStorage.getItem('token');
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${token}`);
    }
    httpOptions.headers = httpOptions.headers.set('locale', localStorage.getItem('locale') || 'vi');
    httpOptions.headers = httpOptions.headers.set('Content-Type', 'application/json');
    httpOptions.headers = httpOptions.headers.set('App-Name', 'Virtual QC');

    if (headersPairs) {
      headersPairs.forEach((element: any) => {
        httpOptions.headers = httpOptions.headers.set(element.key, element.value);
      });
    }
    return httpOptions;
  }

  private getUploadHeaders(headersPairs?: any) {
    this.checkTokenExpired();
    const httpOptions = {
      headers: new HttpHeaders()
    };
    if (localStorage.getItem('token')) {
      const token: string | any = localStorage.getItem('token');
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${token}`);
    }
    httpOptions.headers = httpOptions.headers.set('locale', localStorage.getItem('locale') || 'vi');
    httpOptions.headers = httpOptions.headers.set('App-Name', 'Virtual QC');

    if (headersPairs) {
      headersPairs.forEach((element: any) => {
        httpOptions.headers = httpOptions.headers.set(element.key, element.value);
      });
    }
    return httpOptions;
  }

  private getRefreshToken() {
    const userInfoString = localStorage.getItem('user_info');
    const userInfo = (userInfoString) ? JSON.parse(userInfoString) : {};
    return JSON.stringify({ refreshToken: userInfo.refreshToken });
  }

  private cleanAllCache() {
    localStorage.removeItem('user_info');
    localStorage.removeItem('token');
    this.flagCheckToken = true;
    window.location.href = '/auth/login';
  }

  postDataNoAuthen(path?: string, body?: any, headersPairs?: any, timeoutMs?: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders()
    };
    httpOptions.headers = httpOptions.headers.set('locale', localStorage.getItem('locale') || 'vi');
    httpOptions.headers = httpOptions.headers.set('Content-Type', 'application/json');
    httpOptions.headers = httpOptions.headers.set('App-Name', 'Virtual QC');
    return this.postRequest(path, timeoutMs, httpOptions, body);
  }
}
