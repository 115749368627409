import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AUTH_NOTIFICATION_CONFIG } from '@core/config/notification/auth-notification.config';

@Component({
  selector: 'app-auth-notification',
  templateUrl: './auth-notification.component.html',
  styleUrls: ['./auth-notification.component.scss']
})
export class AuthNotificationComponent implements OnInit {
  dropdown = false;
  @Input() notificationType = '';
  AUTH_NOTIFICATION_CONFIG = { ...AUTH_NOTIFICATION_CONFIG };

  constructor(private title: Title, public router: Router, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.initNotificationType();
  }

  initNotificationType(): any {
    if (!this.notificationType) {
      const state = history.state;
      this.notificationType = state.authNotificationType;
    }
    if (!this.notificationType) {
      return this.router.navigate(['/auth/login']);
    }
    this.title.setTitle(this.translateService.instant(AUTH_NOTIFICATION_CONFIG[this.notificationType].browserTitle || ''));
  }
}
