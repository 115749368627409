export enum STATUS_CALL_ANALYTICS {
  PASS = 'PASS',
  FAIL = 'FAIL',
  NA = 'NA'
}

export const ERROR_MESSAGE_CALL_ANALYSIS = {
  'TOOL-00001002': 'ERROR_CALL_ENGINE_CONFIG',
  'TOOL-00001003': 'NOT_FOUND_EMAIL',
  'TOOL-00001004': 'ERROR_FROM_AUTH_FOR_GET_EMAIL',
  'TOOL-00001005': 'LIMIT_CREATED_JOB'
};
