import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {
  constructor() {}

  async copyClipboard(str: string) {
    await navigator.clipboard.writeText(str);
  }
}
