export enum RIGHTS_CODE {
  'EVAL_000' = 'EVAL-000',
  'EVAL_001' = 'EVAL-001',
  'EVAL_002' = 'EVAL-002',
  'EVAL_003' = 'EVAL-003',
  'EVAL_004' = 'EVAL-004',
  'EVAL_005' = 'EVAL-005',
  'EVAL_006' = 'EVAL-006',
  'EVAL_012' = 'EVAL-012',
  'EVAL_011' = 'EVAL-011',
  'EVAL_018' = 'EVAL-018',
  'EVAL_019' = 'EVAL-019',
  'EVAL_013' = 'EVAL-013',
  'ORG_000' = 'ORG-000',
  'ORG_001' = 'ORG-001',
  'USER_003' = 'USER-003',
  'USER_000' = 'USER-000',
  'USER_001' = 'USER-001',
  'ROLE_000' = 'ROLE-000',
  'ROLE_001' = 'ROLE-001',
  'RPT_001' = 'RPT-001',
  'RPT_002' = 'RPT-002',
  'RPT_003' = 'RPT-003',
  'RPT_004' = 'RPT-004',
  'RPT_005' = 'RPT-005',
  'RPT_006' = 'RPT-006',
  'RPT_007' = 'RPT-007',
  'RPT_008' = 'RPT-008',
  'RPT_009' = 'RPT-009',
  'RPT_010' = 'RPT-010',
  'RPT_011' = 'RPT-011',
  'RPT_012' = 'RPT-012',
  'RPT_013' = 'RPT-013',
  'RPT_014' = 'RPT-014',
  'LOG_000' = 'LOG-000',
  'EVAL_007' = 'EVAL-007',
  'EVAL_008' = 'EVAL-008',
  'EVAL_009' = 'EVAL-009',
  'EVAL_010' = 'EVAL-010',
  'EVAL_014' = 'EVAL-014',
  'EVAL_015' = 'EVAL-015',
  'QC_001' = 'QC-001',
  'QC_002' = 'QC-002'
}
