import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dialog-notification',
  templateUrl: './dialog-notification.component.html',
  styleUrls: ['./dialog-notification.component.scss']
})
export class DialogNotificationComponent implements OnInit {
  @Input() isShow: boolean;
  isOkLoading = false;
  @Input() tittleDialog: string;
  @Input() titleContent: string;
  @Input() textContent: string;
  @Input() iconContent: string;
  @Output() handleSubmitDialog = new EventEmitter();
  @Output() handleCancelDialog = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleOk(event: any): void {
    this.handleSubmitDialog.emit(event);
  }

  handleCancel(): void {
    this.isShow = false;
    this.handleCancelDialog.emit(false);
  }
}
