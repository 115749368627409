import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadAudioComponent } from './upload-audio/upload-audio.component';
import { NgZorroAppModule } from '@ng-zorro/ng-zorro-app.module';
import { TranslateModule } from '@ngx-translate/core';
import { PartialModule } from '../partial/partial.module';
import { DialogNotificationComponent } from './dialog-notification/dialog-notification.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { AgreeComponent } from './agree/agree.component';

@NgModule({
  declarations: [UploadAudioComponent, DialogNotificationComponent, ErrorModalComponent, ConfirmComponent, AgreeComponent],
  imports: [CommonModule, NgZorroAppModule, TranslateModule.forChild(), PartialModule, FormsModule, ReactiveFormsModule, NzButtonModule],
  exports: [UploadAudioComponent, DialogNotificationComponent, ConfirmComponent, AgreeComponent]
})
export class DialogModule {}
