import { Injectable } from '@angular/core';

@Injectable()
export class FormatTimeService {
  constructor() {}

  formatDateTime(date: string) {
    const [datePart, timePart] = date.split('T');
    const formattedDate = `${timePart.slice(0, 8)} ${datePart.replace(/-/g, '/')} `;
    return formattedDate;
  }

  formatTimeSecond(time: number): string {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  // hh:mm:ss -> milliseconds
  formatToMilliseconds(hours: number, minutes: number, seconds: number) {
    const hourMs = hours * 60 * 60 * 1000;
    const minuteMs = minutes * 60 * 1000;
    const secondMs = seconds * 1000;
    return hourMs + minuteMs + secondMs;
  }

  // indochina hh:mm:ss time -> milliseconds
  formatToMilliseconds2(time: string) {
    const dateObj = new Date(time);
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const seconds = dateObj.getSeconds();
    return (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
  }

  // Milliseconds -> mm:ss:msms
  formatTimeMillisecondsTo(seconds: number): string {
    const date = new Date(seconds * 1000);
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const secondsString = date.getSeconds().toString().padStart(2, '0');
    const milliseconds = Math.floor(date.getMilliseconds() / 10)
      .toString()
      .padStart(2, '0');
    return `${minutes}:${secondsString}.${milliseconds}`;
  }

  // 2023-04-25T03:29:31.953Z ==> DD/MM/YYYY HH:mm:ss
  convertTime(originalTime) {
    const date = new Date(originalTime);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    const formattedTime = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year} ${hour < 10 ? '0' + hour : hour}:${
      minute < 10 ? '0' + minute : minute
    }:${second < 10 ? '0' + second : second}`;

    return formattedTime;
  }

  // 2023-04-25T03:29:31.953Z ==> DD/MM/YY HH:mm
  convertTimeHour(timeString) {
    const date = new Date(timeString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
}
