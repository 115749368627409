import { AUDIO_STATUS } from '@admin/constant/audio/audio-status.constant';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-search-audio',
  templateUrl: './search-audio.component.html',
  styleUrls: ['./search-audio.component.scss']
})
export class SearchAudioComponent implements OnInit {
  audioStatus = Object.values(AUDIO_STATUS);
  formGroup: FormGroup;
  numOfDoneFrom = 0;

  @Output() submitFilter: EventEmitter<{}> = new EventEmitter();

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      fileName: [''],
      uploadDate: [undefined],
      contactDate: [undefined],
      callId: [''],
      numOfDoneFrom: [0],
      numOfDoneTo: [0],
      status: [[]],
      agentId: [''],
      updateDate: [undefined]
    });

    this.formGroup.get('numOfDoneFrom')?.valueChanges.subscribe(x => {
      this.numOfDoneFrom = x;
      if (this.formGroup.get('numOfDoneTo')?.value < x) {
        this.formGroup.get('numOfDoneTo')?.setValue(x);
      }
    });

    this.numOfDoneFrom = 0;
  }

  trimInput() {
    const filter = this.formGroup.value;
    for (const k in filter) {
      if (Object.prototype.hasOwnProperty.call(filter, k) && filter[k] && typeof filter[k] === 'string') {
        filter[k] = filter[k].trim();
      }
    }
    this.formGroup.setValue(filter);
  }

  handleSubmit() {
    this.trimInput();
    const filterParams = {};
    const filter = this.formGroup.value;
    if (filter.fileName) {
      filterParams['fileName'] = filter.fileName.trim();
    }
    if (filter.uploadDate && filter.uploadDate.length) {
      filterParams['uploadDateStart'] = filter.uploadDate[0].getTime();
      filterParams['uploadDateEnd'] = filter.uploadDate[1].getTime();
    }
    if (filter.contactDate && filter.contactDate.length) {
      filterParams['contactDateStart'] = filter.contactDate[0].getTime();
      filterParams['contactDateEnd'] = filter.contactDate[1].getTime();
    }
    if (filter.callId) {
      filterParams['callId'] = filter.callId.trim();
    }
    if (filter.numOfDoneFrom) {
      filterParams['numOfSuccessFrom'] = filter.numOfDoneFrom;
    }
    if (filter.numOfDoneTo) {
      filterParams['numOfSuccessTo'] = filter.numOfDoneTo;
    }
    if (filter.status.length) {
      filterParams['lastStatus'] = filter.status;
    }
    if (filter.agentId) {
      filterParams['agentId'] = filter.agentId.trim();
    }
    if (filter.updateDate && filter.updateDate.length) {
      filterParams['updateDateStart'] = filter.updateDate[0].getTime();
      filterParams['updateDateEnd'] = filter.updateDate[1].getTime();
    }
    this.submitFilter.emit(filterParams);
  }

  reset() {
    this.initForm();
  }
}
