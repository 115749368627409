import { Injectable } from '@angular/core';
import { ENDPOINT } from '@shared/config/endpoint';
import { BaseService } from '@shared/service/base.service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Injectable()
export class EvaluationService {
  private valueKeywords = new BehaviorSubject<any>(null);
  behaviorValueKeywords = this.valueKeywords.asObservable();
  private success = new BehaviorSubject<any>(null);
  currentSuccess = this.success.asObservable();
  private jumpAudioData = new BehaviorSubject<any>(null);
  behaviorJumpAudioData = this.jumpAudioData.asObservable();
  private dataReceiver = new BehaviorSubject<any>(null);
  dataTransfer = this.dataReceiver.asObservable();

  constructor(private baseService: BaseService, private http: HttpClient) {}

  // get flag isDoneCallAPI in component parent
  isSuccessAPI(isSuccess: any) {
    this.success.next(isSuccess);
  }

  // send metadata
  sendData(data: any) {
    this.dataReceiver.next(data);
  }

  // Send keyword.
  sendKeyword(data: any) {
    this.valueKeywords.next(data);
  }

  jumpAudio(data: any) {
    this.jumpAudioData.next(data);
  }

  getTicketList(params: any) {
    const queryString = this.baseService.paramStringArray(params);
    return this.baseService.getData(`${ENDPOINT.evaluation.ticketList}?${queryString.toString()}`);
  }

  getBucketAndProduct() {
    return this.baseService.getData(ENDPOINT.audio.bucketAndProduct);
  }

  getTicketDetail(id: string) {
    return this.baseService.getData(ENDPOINT.evaluation.ticketDetail.replace(':id', id));
  }

  getTranscript(id: string) {
    return this.baseService.getData(ENDPOINT.evaluation.transcript.replace('id', id));
  }

  getAudioDetails(id: string) {
    return this.baseService.getData(ENDPOINT.evaluation.audioInfo.replace(':id', id));
  }

  getKeywordsAttitude(id: string) {
    return this.baseService.getData(ENDPOINT.evaluation.keyword.replace(':id', id));
  }

  getEstKpi(id: string) {
    return this.baseService.getData(ENDPOINT.evaluation.estKpi.replace(':id', id));
  }

  getKpiResult(id: string) {
    return this.baseService.getData(ENDPOINT.evaluation.kpiResult.replace(':id', id));
  }

  getHistory(id: string, params: { page: number; maxSize: number; type: string }) {
    const queryString = `?page=${params.page}&maxSize=${params.maxSize}&type=${params.type}`;
    return this.baseService.getData(`${ENDPOINT.evaluation.history.replace(':id', id)}${queryString}`);
  }

  changeStatusQCKpi(param: {}) {
    return this.baseService.putData(ENDPOINT.evaluation.statusQCKpi, param);
  }

  getComments(id: string) {
    return this.baseService.getData(ENDPOINT.evaluation.comments.replace(':id', id));
  }

  changeComments(id: string, param: {}) {
    return this.baseService.putData(ENDPOINT.evaluation.comments.replace(':id', id), param);
  }

  reEvaluatedTicket(id: string) {
    return this.baseService.putData(ENDPOINT.audio.reEvaluatedAudio.replace(':id', id));
  }

  resetTicket(id: string) {
    return this.baseService.putData(ENDPOINT.evaluation.resetTicket.replace(':id', id));
  }

  exportExcelTicketDetails(id: string) {
    return this.baseService.getData(ENDPOINT.evaluation.exportTicketDetails.replace(':id', id));
  }

  downloadAudioFile(audioUrl: string, nameAudio?: string) {
    const headers = new HttpHeaders().set('Content-Type', 'audio/mpeg');
    this.http.get(audioUrl, { headers, responseType: 'blob' }).subscribe(response => {
      const filename = `${nameAudio ? nameAudio : 'audio'}.mp3`;
      saveAs(response, filename);
    });
  }

  logDownloadAudioAction(params) {
    return this.baseService.postData(`${ENDPOINT.actionHistory.logEvaluationAction.downloadAudio}`, params);
  }

  downloadTicketInfo(params: any) {
    let paramsObj = new HttpParams();
    Object.keys(params).forEach(key => {
      if (params[key]) {
        if (Array.isArray(params[key])) {
          params[key].forEach(value => {
            paramsObj = paramsObj.append(`${key}[]`, value);
          });
        } else {
          paramsObj = paramsObj.append(key, params[key]);
        }
      }
    });
    return this.baseService.getData(`${ENDPOINT.evaluation.downloadTicketInfo}?${paramsObj}`);
  }

  checkReEvaluated(audioId: string) {
    return this.baseService.getData(ENDPOINT.audio.checkReEvaluated.replace(':audioId', audioId));
  }

  getRevaluationJobs(params: any) {
    const queryString = `?page=${params.page}&maxSize=${params.maxSize}`;
    return this.baseService.getData(`${ENDPOINT.evaluation.revaluationJobs}${queryString}`);
  }

  RevaluationJobsMulti(params: any) {
    return this.baseService.postData(`${ENDPOINT.evaluation.revaluationJobs}`, params);
  }

  getListUnassigned(params: any) {
    const queryString = this.baseService.paramStringArray(params);
    return this.baseService.getData(`${ENDPOINT.evaluation.ticketUnassigned}?${queryString.toString()}`);
  }

  getTicketAssigned(params: any) {
    const queryString = this.baseService.paramStringArray(params);
    return this.baseService.getData(`${ENDPOINT.evaluation.ticketAssigned}?${queryString.toString()}`);
  }

  getTicketAssignedDone(params: any) {
    const queryString = this.baseService.paramStringArray(params);
    return this.baseService.getData(`${ENDPOINT.evaluation.ticketAssignedDone}?${queryString.toString()}`);
  }

  getFlagContent(id: string) {
    return this.baseService.getData(`${ENDPOINT.evaluation.baseUrlEvaluation}/${id}/flag-note`);
  }

  getNumOfEnableAssign(data: any) {
    return this.baseService.postData(`${ENDPOINT.evaluation.numOfEnableAssign}`, data);
  }

  getNumOfAssigned(data: any) {
    return this.baseService.postData(`${ENDPOINT.evaluation.numOfAssigned}`, data);
  }

  getNextStatus(id: string) {
    return this.baseService.getData(`${ENDPOINT.evaluation.baseUrlEvaluation}/${id}/next-statuses`);
  }

  updateTicketStatus(data: {}, id: string) {
    return this.baseService.putData(`${ENDPOINT.evaluation.baseUrlEvaluation}/${id}/change-status`, data);
  }

  assignEvaluation(data: any) {
    return this.baseService.putData(`${ENDPOINT.evaluation.assignEvaluation}`, data);
  }

  unassignEvaluation(data: any) {
    return this.baseService.putData(`${ENDPOINT.evaluation.unassignEvaluation}`, data);
  }
}
