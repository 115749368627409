import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';

import WaveSurfer from 'wavesurfer.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { FormatTimeService } from '@shared/service/formatTime.service';
import { ConvertFileZipService } from '@shared/service/convertFileZip.service';
import { WaveformAudioService } from '@admin/services/evaluation/waveformAudio.service';

import { HistoryEvaluatedComponent } from '@admin/components/evaluation/components/history-evaluated/history-evaluated.component';
import { TICKET_STATUS } from '@admin/constant/evaluation/evaluation.constant';
import { PermissionsService } from '@shared/service/permission.service';
import { RIGHTS_CODE } from '@shared/constant/rights-code';

interface AudioInfo {
  audioId: string;
  callId: string;
  statusTicket: string;
}

@Component({
  selector: 'app-waveform-ticket',
  templateUrl: './waveform-ticket.component.html',
  styleUrls: ['./waveform-ticket.component.scss']
})
export class WaveformTicketComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Output() isDoneCallAPI = new EventEmitter<boolean>();
  @Input() audioInfo: AudioInfo = {
    audioId: '',
    callId: '',
    statusTicket: ''
  };
  @Input() idTicketDetail: string;
  @Output() changeStatusQC: EventEmitter<any> = new EventEmitter();
  @ViewChild('waveform', { static: false }) waveform: ElementRef;
  TICKET_STATUS = TICKET_STATUS;
  RIGHTS_CODE = RIGHTS_CODE;
  isPlay = false;
  duration: number;
  currentTime: number;
  playbackSpeed = 1;
  playbackSpeeds = [
    { label: '0.5x', value: 0.5 },
    { label: '0.75x', value: 0.75 },
    { label: '1x', value: 1 },
    { label: '1.25x', value: 1.25 },
    { label: '1.75x', value: 1.75 }
  ];
  urlAudio: string;
  wavesurfer: WaveSurfer;
  timeStartTranscript: number;
  nameAudio: string;
  private jumpAudioDataSubscription: Subscription;
  listCheckReEvaluated: any[] = [];
  isVisible = false;
  evaluationIndex;
  evaluationTime: any;
  evaluationDate;
  isShowUpdateStatus = false;

  constructor(
    private evaluationService: EvaluationService,
    public formatTimeService: FormatTimeService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private notification: NzNotificationService,
    private translateService: TranslateService,
    private convertFileZipService: ConvertFileZipService,
    private waveformAudioService: WaveformAudioService,
    private modalService: NzModalService,
    private activatedRoute: ActivatedRoute,
    public permissionsService: PermissionsService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['audioInfo'] && changes['audioInfo'].currentValue?.audioId) {
      this.audioInfo = changes['audioInfo'].currentValue;
      this.evaluationService.isSuccessAPI(false);
      this.evaluationService.getAudioDetails(this.audioInfo.audioId).subscribe({
        next: (result: any) => {
          this.nameAudio = result?.data?.fileName;
          this.urlAudio = result?.data?.fileUrl;
          this.wavesurfer.load(`${this.urlAudio}`);
          this.isPlay = false;
          this.evaluationService.isSuccessAPI(true);
          this.evaluationService.sendData(result?.data?.metadata);
        },
        error: err => {
          this.evaluationService.isSuccessAPI(true);
        }
      });
    }
    this.getAudioHistory();
  }

  getAudioHistory() {
    if (!this.audioInfo?.audioId) {
      return;
    }
    this.evaluationService.isSuccessAPI(false);
    if (!this.permissionsService?.canView([RIGHTS_CODE.EVAL_007])) {
      return;
    }
    this.evaluationService.checkReEvaluated(this.audioInfo?.audioId).subscribe({
      next: (result: any) => {
        this.listCheckReEvaluated = result.data;
        const currentEvaluationId = this.activatedRoute?.['snapshot'].paramMap.get('id');
        this.evaluationIndex =
          this.listCheckReEvaluated.length - this.listCheckReEvaluated.map(e => e.evaluationId).indexOf(currentEvaluationId);
        this.listCheckReEvaluated.forEach(data => {
          if (data.evaluationId === currentEvaluationId) {
            this.evaluationDate = data.evaluatedDate;
          }
        });
        this.evaluationService.isSuccessAPI(true);
      },
      error: (err: any) => {
        this.evaluationService.isSuccessAPI(true);
      }
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.loadWaveForm();
    this.wavesurfer.on('ready', () => {
      this.duration = this.wavesurfer.getDuration();
      this.jumpAudioDataSubscription = this.evaluationService.behaviorJumpAudioData.subscribe({
        next: res => {
          this.timeStartTranscript = res?.['timeStart'];
          if (res?.['timeStart'] && this.urlAudio) {
            if (this.wavesurfer.isReady) {
              this.jumpToPosition(res?.['timeStart']);
            } else if (this.wavesurfer.isPlaying()) {
              this.jumpToPosition(res?.['timeStart']);
            } else {
              this.wavesurfer.on('ready', () => {
                this.jumpToPosition(res?.['timeStart']);
              });
            }
            this.wavesurfer.on('audioprocess', time => {
              const currentTime = Number(time.toFixed(2));
              this.waveformAudioService.setCurrentTime(currentTime);
            });
          } else {
            this.resetAudio();
          }
        }
      });
    });
  }

  jumpToPosition(startInMs: number): void {
    const percentage = startInMs / this.duration;
    this.wavesurfer.pause();
    this.wavesurfer.setCurrentTime(startInMs);
    this.wavesurfer.seekTo(percentage);
    this.wavesurfer.play();
    this.isPlay = true;
    if (this.isPlay) {
      this.wavesurfer.play();
    }
  }

  loadWaveForm() {
    this.evaluationService.isSuccessAPI(false);
    this.wavesurfer = WaveSurfer.create({
      container: this.waveform.nativeElement,
      height: 80,
      waveColor: '#2475cb',
      progressColor: '#f0f5fb',
      barWidth: 1,
      barHeight: 1,
      barGap: 1,
      backend: 'WebAudio',
      minPxPerSec: 70,
      fillParent: true,
      scrollParent: true,
      responsive: true,
      cursorWidth: 2
    });
    this.evaluationService.isSuccessAPI(true);
    this.wavesurfer.on('ready', () => {
      this.duration = this.wavesurfer.getDuration();
    });
    setInterval(() => {
      this.currentTime = this.wavesurfer.getCurrentTime();
    }, 1000);
    this.wavesurfer.on('audioprocess', time => {
      this.currentTime = Math.round(time);
      // this.waveformAudioService.setCurrentTime(time);
    });
    this.wavesurfer.on('finish', () => {
      this.wavesurfer.stop();
      this.wavesurfer.seekTo(0);
      this.isPlay = false;
    });
    this.wavesurfer.setPlaybackRate(this.playbackSpeed);
  }

  playPause(): void {
    if (this.wavesurfer.isPlaying()) {
      this.wavesurfer.pause();
      this.isPlay = false;
      this.wavesurfer.on('audioprocess', time => {
        const currentTime = Number(time.toFixed(2));
        this.waveformAudioService.setCurrentTime(currentTime);
      });
    } else {
      this.wavesurfer.play();
      this.isPlay = true;
      this.wavesurfer.on('audioprocess', time => {
        const currentTime = Number(time.toFixed(2));
        this.waveformAudioService.setCurrentTime(currentTime);
      });
    }
  }

  setSpeed(event: number): void {
    this.playbackSpeed = event;
    if (this.wavesurfer.isPlaying()) {
      this.wavesurfer.pause();
      this.wavesurfer.setPlaybackRate(event);
      this.wavesurfer.play();
      return;
    }
    this.wavesurfer.setPlaybackRate(event);
  }

  resetAudio() {
    this.wavesurfer.stop();
    this.wavesurfer.seekTo(0);
  }

  handleDownloadTicket() {
    this.spinner.show();
    this.evaluationService.exportExcelTicketDetails(this.idTicketDetail).subscribe({
      next: (result: any) => {
        const fileBuffer = result.data.fileBuffer;
        const fileName = result.data.fileName;
        this.convertFileZipService.saveExcelFile(fileBuffer, fileName);
        this.spinner.hide();
      },
      error: err => {
        this.notification.create(
          'error',
          this.translateService.instant('evaluation.ticket_details.waveform.download_evaluation_ticket'),
          this.translateService.instant('evaluation.ticket_details.waveform.download_evaluation_ticket_failed')
        );
        this.spinner.hide();
      }
    });
  }

  handleDownloadAudio() {
    this.evaluationService.getAudioDetails(this.audioInfo.audioId).subscribe((res: any) => {
      this.evaluationService.downloadAudioFile(res?.data?.fileUrl, res?.data?.fileName);
    });
    this.logDownloadAudioAction(this.idTicketDetail);
  }

  logDownloadAudioAction(evaluationId) {
    this.evaluationService.logDownloadAudioAction({ evaluationIds: [evaluationId] }).subscribe({
      error: err => {
        this.notification.create(
          'error',
          this.translateService.instant('action_history.log_action.download_evaluation_audio.title'),
          this.translateService.instant('action_history.log_action.download_evaluation_audio.error')
        );
      }
    });
  }

  ngOnDestroy() {
    this.resetAudio();
    if (this.jumpAudioDataSubscription) {
      this.jumpAudioDataSubscription.unsubscribe();
    }
  }

  handleReevaluateTicket() {
    this.isVisible = true;
  }

  handleConfirm() {
    this.isVisible = false;
    this.spinner.show();
    this.evaluationService.reEvaluatedTicket(this.audioInfo.audioId).subscribe({
      next: (result: any) => {
        this.router.navigate(['/admin/evaluation/ticket-info', result.data._id]);
        this.spinner.hide();
      },
      error: (err: any) => {
        this.notification.create(
          'error',
          this.translateService.instant('common.message.title.notification'),
          this.translateService.instant('common.message.re_evaluated')
        );
        this.spinner.hide();
      }
    });
  }

  handleClose() {
    this.isVisible = false;
  }

  handleHistoryEvaluated() {
    this.modalService.create({
      nzTitle: this.translateService.instant('evaluation.ticket_details.history_evaluated.revaluation_history'),
      nzContent: HistoryEvaluatedComponent,
      nzComponentParams: {
        data: this.listCheckReEvaluated
      }
    });
  }

  updateTicketStatus() {
    this.isShowUpdateStatus = true;
  }
}
