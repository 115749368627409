export class VolumnData {
  date: string;
  totalCallDurationsHour: number;
  totalCallDurationsMS: number;
  totalCalls: number;
  totalSuccessCallDurationsHour: number;
  totalSuccessCallDurationsMS: number;
  totalSuccessCalls: number;
  totalSilentCalls: number;
  totalSilentCallDurationsHour: number;
  totalErrorCalls: number;
  totalErrorCallDurationsHour: number;

  constructor(data: any) {
    this.date = data.date;
    this.totalCallDurationsHour = data.totalCallDurationsHour;
    this.totalCallDurationsMS = data.totalCallDurationsMS;
    this.totalCalls = data.totalCalls;
    this.totalSuccessCallDurationsHour = data.totalSuccessCallDurationsHour;
    this.totalSuccessCallDurationsMS = data.totalSuccessCallDurationsMS;
    this.totalSuccessCalls = data.totalSuccessCalls;
    this.totalSilentCalls = data.totalSilentCalls;
    this.totalSilentCallDurationsHour = data.totalSilentCallDurationsHour;
    this.totalErrorCalls = data.totalErrorCalls;
    this.totalErrorCallDurationsHour = data.totalErrorCallDurationsHour;
  }
}
