import { IInternalReportSetting } from '@admin/models/side-bar/side-bar.model';
import { RIGHTS_CODE } from '@shared/constant/rights-code';

export const INTERNAL_REPORTS: IInternalReportSetting[] = [
  {
    field: 'callStatisticReportConfig',
    level: 1,
    title: 'report.call_analysis.title',
    open: false,
    selected: false,
    disabled: false,
    url: 'report-internal/call-analysis',
    rights: [RIGHTS_CODE.RPT_001]
  },
  {
    field: 'violationCallsSummaryReportConfig',
    level: 1,
    title: 'report.violation.title',
    open: false,
    selected: false,
    disabled: false,
    url: 'report-internal/violation-summary',
    rights: [RIGHTS_CODE.RPT_003]
  },
  {
    field: 'volumeReportConfig',
    level: 1,
    title: 'report.volumn.title',
    open: false,
    selected: false,
    disabled: false,
    url: 'report-internal/volume',
    rights: [RIGHTS_CODE.RPT_005]
  },
  {
    field: 'violationCallsByAgentReportConfig',
    level: 1,
    title: 'report.violation_by_agent.title',
    open: false,
    selected: false,
    disabled: false,
    url: 'report-internal/violation-by-agent',
    rights: [RIGHTS_CODE.RPT_007]
  },
  {
    field: 'violationCallsByBucketReportConfig',
    level: 1,
    title: 'common.filter.attitude_kw.title',
    open: false,
    selected: false,
    disabled: false,
    url: 'report-internal/attitude-kw-bucket',
    rights: [RIGHTS_CODE.RPT_009]
  },
  {
    field: 'assignedCallsReportConfig',
    level: 1,
    title: 'report.assigned_call.title',
    open: false,
    selected: false,
    disabled: false,
    url: 'report-internal/assigned-call',
    rights: [RIGHTS_CODE.RPT_011]
  },
  {
    field: 'assignedCallsByQCReportConfig',
    level: 1,
    title: 'report.assigned_call_by_qc.title',
    open: false,
    selected: false,
    disabled: false,
    url: 'report-internal/assigned-call-by-qc',
    rights: [RIGHTS_CODE.RPT_013]
  }
];
