import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AudioManagementComponent } from './audio-management/audio-management.component';
import { AudioMetadataComponent } from '@admin/components/audio/components/audio-metadata/audio-metadata.component';

const routes: Routes = [
  { 
    path: '', 
    component: AudioManagementComponent
  },
  {
    path: 'upload-audio',
    component: AudioMetadataComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AudioRoutingModule {}
