import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { differenceInCalendarDays } from 'date-fns';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FORM_CONTROL_STATUS } from '@shared/constant/form.constant';

@Component({
  selector: 'app-upload-audio',
  templateUrl: './upload-audio.component.html',
  styleUrls: ['./upload-audio.component.scss']
})
export class UploadAudioComponent implements OnInit, OnChanges {
  @Input() isVisible = false;

  @Input() isUploadToMetadata = false;

  @Input() limitItems = 10;

  @Output() changeVisible = new EventEmitter();

  @Output() submitFormData = new EventEmitter();

  @Output() redirectToMetadata = new EventEmitter();

  formGroup: FormGroup;
  items: FormArray;
  tempValue = {};

  fileNames = '';

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initFormGroup();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['isVisible'].isFirstChange() && changes['isVisible'] && !changes['isVisible'].currentValue) {
      this.resetUploadData();
    } 
  }

  initFormGroup(): void {
    this.formGroup = new FormGroup({
      items: this.formBuilder.array([], [Validators.required])
    });
    this.items = this.formGroup.get('items') as FormArray;
  }

  addItem(file: NzUploadFile): void {
    this.items.push(
      this.formBuilder.group({
        id: file.uid,
        fileName: [file.name, [Validators.pattern(RegExp(/^[^,]+$/))]],
        contactDate: [new Date(), [Validators.required]],
        agentId: ['', [Validators.required, Validators.pattern(RegExp(/^[^,]+$/))]],
        file,
        edit: false,
        submitEdit: false
      })
    );
  }

  handleOk(): void {
    this.submitFormData.emit(this.items);
  }

  handleCancel(): void {
    this.hideDialog();
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    if (this.items.controls.length >= this.limitItems) {
      return false;
    }
    this.addItem(file);
    this.getFileNames();
    return false;
  };

  hideDialog() {
    this.isVisible = false;
    this.changeVisible.emit(this.isVisible);
    this.resetUploadData();
  }

  isDisableOk(): boolean {
    return !this.items.controls.length;
  }

  startEdit(i: number): void {
    this.tempValue[i] = this.items.controls[i].value;
    this.items.controls[i].get('edit')?.setValue(true);
    this.items.controls[i].get('submitEdit')?.setValue(false);
  }

  cancelEdit(i: number): void {
    this.items.controls[i].setValue(this.tempValue[i]);
  }

  saveEdit(i: number): void {
    this.items.controls[i].get('submitEdit')?.setValue(true);
    if (this.items.controls[i].status === FORM_CONTROL_STATUS.INVALID) {
      return;
    }
    this.items.controls[i].get('edit')?.setValue(false);
  }

  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, new Date()) > 0;

  deleteRow(i: number): void {
    this.items.removeAt(i);
    this.getFileNames();
  }

  getFileNames() {
    this.fileNames = '';
    this.items.value.forEach(e => {
      this.fileNames += `${e.fileName}, `;
    });
    this.fileNames = this.fileNames.slice(0, -2);
  }

  resetUploadData() {
    this.fileNames = '';
    this.items.clear();
  }
}
