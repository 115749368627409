import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { endOfDay, startOfDay, startOfToday, subDays } from 'date-fns';
import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription, take, takeUntil, timer } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { NgxSpinnerService } from 'ngx-spinner';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TranslateService } from '@ngx-translate/core';
import { FormatTimeService } from '@shared/service/formatTime.service';
import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { ValidatorFormService } from '@shared/service/validatorForm.service';
import { AttitudeKwBucketService } from '@admin/services/report-internal/attitude-kw-bucket.service';
import { ReportSummaryService } from '@admin/services/report-internal/report-summary.service';
import { DownloadService } from '@shared/service/download.service';
import { SocketService } from '@shared/service/socket.service';

import { ATTITUDE_KW } from '@admin/constant/report/attitude-kw-bucket';
import { DOWNLOAD } from '@shared/constant/download';
import { DataFormService } from '@admin/services/report-internal/dataForm.service';
import { CallAnalysisService } from '@admin/services/report-internal/call-analysis.service';
import { OrganizationsGlobalService } from '@shared/service/organizations-global.service';
import { RIGHTS_CODE } from '@shared/constant/rights-code';
import { PermissionsService } from '@shared/service/permission.service';

@Component({
  selector: 'app-search-attitude-kw-bucket',
  templateUrl: './search-attitude-kw-bucket.component.html',
  styleUrls: ['./search-attitude-kw-bucket.component.scss']
})
export class SearchAttitudeKwBucketComponent implements OnInit, OnDestroy {
  @Output() dataSubmitForm = new EventEmitter<any>();
  @Input() lastSyncAt: string;
  RIGHTS_CODE = RIGHTS_CODE;
  searchViolationForm: FormGroup;
  submitted = false;
  isEngine = true;
  listKpiVersion: any;
  listProduct: string[] = [];
  listAgency: string[] = [];
  listBucket: string[] = [];
  listUnitHead: string[] = [];
  listTeamLeader: string[] = [];
  listAgentID: any[] = [];
  listShowBy: { label: string; value: string }[] = [
    { label: 'common.filter.attitude_kw.leader', value: ATTITUDE_KW.TEAM_LEADER },
    { label: 'common.filter.attitude_kw.contact_date', value: ATTITUDE_KW.CONTACT_DATE }
  ];
  listKeyWordVersion: number[] = [];
  isShowFilter = false;
  defaultEndDate = startOfToday();
  defaultStartDate = subDays(this.defaultEndDate, 6);

  destroyObservable = new Subject<any>();
  exportId = uuidv4();
  exportParams = {};
  dataOrganizations: string[];
  isDisabledKW = false;
  private subscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private evaluationService: EvaluationService,
    private validatorFormService: ValidatorFormService,
    private attitudeKwBucketService: AttitudeKwBucketService,
    private reportInternalService: ReportSummaryService,
    public downloadService: DownloadService,
    private socketService: SocketService,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
    private callAnalysisService: CallAnalysisService,
    public formatTimeService: FormatTimeService,
    private dataFormService: DataFormService,
    private organizationsGlobalService: OrganizationsGlobalService,
    public permissionsService: PermissionsService
  ) {}

  get f(): { [key: string]: AbstractControl } {
    return this.searchViolationForm.controls;
  }

  ngOnInit(): void {
    this.initSearchViolationForm();
    this.checkOrganizations();
    this.handleAutoGetDataSelect('showBy');
    this.handleAutoGetDataSelect('kwVersion');
    this.initDownloadService();
  }

  initSearchViolationForm(): void {
    this.searchViolationForm = this.formBuilder.group({
      contactDate: [null],
      showBy: [ATTITUDE_KW.TEAM_LEADER],
      products: [[]],
      agentId: [[]],
      agency: [[]],
      bucket: [[]],
      teamLeader: [[]],
      unitHead: [[]],
      kwVersion: [null]
    });
    this.searchViolationForm.patchValue({
      contactDate: [this.defaultStartDate, this.defaultEndDate]
    });
  }

  getValueFilterAdvanced() {
    const newParam = {
      fields: ['agency', 'bucket', 'team_leader', 'unit_head', 'product', 'agent_id', 'keyword_version'],
      engineIds: this.dataOrganizations
    };
    const dataForm = this.dataFormService.getDataFormAttitudeKWBucket(this.searchViolationForm, newParam);
    const params: any = this.dataFormService.removeEmptyValues(dataForm);
    this.dataFormService.getValueFilterAdvanced(params).subscribe({
      next: (res: any) => {
        this.listAgency = res.data.filterValues?.['agency'];
        this.listBucket = res.data.filterValues?.['bucket'];
        this.listTeamLeader = res.data.filterValues?.['team_leader'];
        this.listUnitHead = res.data.filterValues?.['unit_head'];
        this.listProduct = res.data.filterValues?.['product'];
        this.listAgentID = res.data.filterValues?.['agent_id'];
        this.listKeyWordVersion = res.data.filterValues?.['keyword_version'];
      },
      error: (error: any) => {
        this.listAgency = [];
        this.listProduct = [];
        this.listBucket = [];
        this.listUnitHead = [];
        this.listTeamLeader = [];
        this.listKeyWordVersion = [];
      }
    });
  }

  handleShowFilter() {
    if (this.isEngine) {
      this.isShowFilter = !this.isShowFilter;
    }
  }

  emitDataForm(): void {
    const newParam = {
      page: 1,
      engineIds: this.dataOrganizations
    };
    const dataForm: any = this.dataFormService.getDataFormAttitudeKWBucket(this.searchViolationForm, newParam);
    this.dataSubmitForm.emit(dataForm);
    this.getValueFilterAdvanced();
  }

  checkOrganizations() {
    this.subscription = this.organizationsGlobalService.valueOrganizations$.pipe(takeUntil(this.destroyObservable)).subscribe({
      next: result => {
        if (result.org) {
          this.dataOrganizations = result.org;
          this.isDisabledKW = result.org.length > 1 ? true : false;
          if (result.org.length > 1) {
            this.searchViolationForm.controls['kwVersion'].patchValue(null);
            this.searchViolationForm.controls['showBy'].patchValue(ATTITUDE_KW.TEAM_LEADER);
          } else {
            this.emitDataForm();
          }
        }
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    this.emitDataForm();
  }

  handleAutoGetDataSelect(selectForm: string): void {
    this.searchViolationForm
      ?.get(selectForm)
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe(() => {
        this.emitDataForm();
      });
  }

  resetForm() {
    this.submitted = false;
    const defaultValues = {
      showBy: ATTITUDE_KW.TEAM_LEADER,
      contactDate: [this.defaultStartDate, this.defaultEndDate],
      products: [],
      agency: [],
      agentId: [],
      bucket: [],
      teamLeader: [],
      unitHead: [],
      kwVersion: null
    };
    this.searchViolationForm.patchValue(defaultValues);
    this.submitted = false;
  }

  initDownloadService() {
    this.downloadService.isDownloadHandling = false;
    this.downloadService.downloadFileObservable.pipe(takeUntil(this.destroyObservable)).subscribe(data => {
      this.downloadService.handleDownloadData(data);
      this.socketService.reportLeaveRoom(this.exportId);
      timer(DOWNLOAD.DELAY_TIME)
        .pipe(take(1))
        .subscribe(() => {
          this.downloadService.isDownloadHandling = false;
        });
    });
  }

  async exportToExcel() {
    this.socketService.reportEmitRoom(this.exportId);
    this.exportParams = this.setupParams();
    if (this.exportId) {
      this.exportParams['exportId'] = this.exportId;
    }
    this.attitudeKwBucketService.exportReportAttitudeKwBucket(this.exportParams).subscribe({
      next: (res: any) => {
        this.downloadService.isDownloadHandling = true;
      },
      error: (err: any) => {
        this.notificationService.create(
          'error',
          this.translateService.instant('report.export.title'),
          this.translateService.instant('report.export.error')
        );
      }
    });
  }

  setupParams(): {} {
    const newParam = {
      page: 1,
      engineIds: this.dataOrganizations
    };
    const dataForm: any = this.dataFormService.getDataFormAttitudeKWBucket(this.searchViolationForm, newParam);
    return dataForm;
  }

  ngOnDestroy(): void {
    this.destroyObservable.next(null);
    this.destroyObservable.complete();
    this.socketService.reportLeaveRoom(this.exportId);
    this.downloadService.isDownloadHandling = false;
    this.subscription.unsubscribe();
  }
}
