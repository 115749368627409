import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { FormatTimeService } from '@shared/service/formatTime.service';
import { AudioService } from '@admin/services/audio-manager/audio-manager.service';

@Component({
  selector: 'app-search-audio-error',
  templateUrl: './search-audio-error.component.html',
  styleUrls: ['./search-audio-error.component.scss']
})
export class SearchAudioErrorComponent implements OnInit {
  @Output() dataSubmitForm = new EventEmitter();
  searchTicketForm: FormGroup;
  submitted = false;
  causeTicket: Array<{ label: string; value: string }> = [
    { label: 'audio_manager.audio_error.status_kpi.stt_server_error', value: 'STT_SERVER_ERROR' },
    { label: 'audio_manager.audio_error.status_kpi.nlp_error', value: 'NLP_ERROR' },
    { label: 'audio_manager.audio_error.status_kpi.evaluation_error', value: 'EVALUATION_ERROR' }
  ];

  constructor(private formBuilder: FormBuilder, private formatTimeService: FormatTimeService, private audioService: AudioService) {}

  get f(): { [key: string]: AbstractControl } {
    return this.searchTicketForm.controls;
  }

  ngOnInit(): void {
    this.searchTicketForm = this.formBuilder.group({
      fileName: [''],
      callId: [''],
      agentId: [''],
      cause: [[]],
      contactDate: [null],
      evaluatedDate: [null],
      updateDate: [null]
    });
  }

  trimInputs(data: any): void {
    Object.keys(data).forEach(key => {
      const control = data[key];
      if (typeof control.value === 'string') {
        control.setValue(control.value.trim());
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    this.trimInputs(this.searchTicketForm.controls);
    // Contact date
    const contactDateFromMs = Date.parse(this.searchTicketForm.get('contactDate')?.value?.['0']);
    const contactDateToMs = Date.parse(this.searchTicketForm.get('contactDate')?.value?.['1']);
    // upload Date
    const uploadDateFromMs = Date.parse(this.searchTicketForm.get('evaluatedDate')?.value?.['0']);
    const uploadDateToMs = Date.parse(this.searchTicketForm.get('evaluatedDate')?.value?.['1']);
    // Update Date
    const updateDateFromMs = Date.parse(this.searchTicketForm.get('updateDate')?.value?.['0']);
    const updateDateToMs = Date.parse(this.searchTicketForm.get('updateDate')?.value?.['1']);

    const dataForm = {
      page: 0,
      maxSize: 10,
      fileName: this.searchTicketForm.get('fileName')?.value || '',
      callId: this.searchTicketForm.get('callId')?.value || '',
      agentId: this.searchTicketForm.get('agentId')?.value || '',
      errorType: this.searchTicketForm.get('cause')?.value || null,
      uploadAtStart: uploadDateFromMs ? uploadDateFromMs : '',
      uploadAtEnd: uploadDateToMs ? uploadDateToMs : '',
      updatedDateStart: updateDateFromMs ? updateDateFromMs : '',
      updatedDateEnd: updateDateToMs ? updateDateToMs : '',
      contactDateStart: contactDateFromMs ? contactDateFromMs : '',
      contactDateEnd: contactDateToMs ? contactDateToMs : ''
    };
    this.dataSubmitForm.emit(dataForm);
  }

  resetForm() {
    this.submitted = false;
    this.searchTicketForm.reset();
  }
}
