import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TicketListComponent } from '@admin/components/evaluation/ticket-list/ticket-list.component';
import { TicketDetailComponent } from '@admin/components/evaluation/ticket-detail/ticket-detail.component';
import { TicketReEvaluationComponent } from '@admin/components/evaluation/ticket-re-evaluation/ticket-re-evaluation.component';
import { TicketUnassignedComponent } from '@admin/components/evaluation/ticket-unassigned/ticket-unassigned.component';
import { TicketAssignedComponent } from '@admin/components/evaluation/ticket-assigned/ticket-assigned.component';
import { TicketCompleteAssignedComponent } from '@admin/components/evaluation/ticket-complete-assigned/ticket-complete-assigned.component';

const routes: Routes = [
  { path: '', redirectTo: 'ticket-list', pathMatch: 'full' },
  {
    path: 'ticket-list',
    component: TicketListComponent
  },
  {
    path: 'ticket-info/:id',
    component: TicketDetailComponent
  },
  {
    path: 're-evaluation-ticket',
    component: TicketReEvaluationComponent
  },
  {
    path: 'ticket-unassigned',
    component: TicketUnassignedComponent
  },
  {
    path: 'ticket-assigned',
    component: TicketAssignedComponent
  },
  {
    path: 'ticket-complete-assigned',
    component: TicketCompleteAssignedComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EvaluationRoutingModule {}
