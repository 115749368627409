import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TicketDetailModel } from '@admin/models/evaluation/ticketDetail';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from '@shared/service/clipboard.service';
import { format, parseISO } from 'date-fns';

@Component({
  selector: 'app-ticket-information',
  templateUrl: './ticket-information.component.html',
  styleUrls: ['./ticket-information.component.scss']
})
export class TicketInformationComponent implements OnInit, OnChanges {
  @Input() ticketDetail: TicketDetailModel;
  @Input() tabName: string;
  infoAgent: { title: string; content: string; type: string; allowCopy: boolean }[];

  constructor(private translateService: TranslateService, public clipboardService: ClipboardService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ticketDetail'] && changes['ticketDetail'].currentValue) {
      this.setupInfoAgent(changes['ticketDetail'].currentValue);
    }
  }

  setupInfoAgent(ticketDetail) {
    this.ticketDetail = ticketDetail;
    if (this.tabName === 'CALL_INFO') {
      this.infoAgent = [
        { 
          title: 'evaluation.ticket_details.call_info.call_time', 
          content: format(parseISO(this.ticketDetail.callTime), 'dd/MM/yyyy HH:mm:ss'), 
          type: 'string', 
          allowCopy: false 
        },
        {
          title: 'evaluation.ticket_details.call_info.evaluated_date',
          content: format(parseISO(this.ticketDetail.evaluatedDate), 'dd/MM/yyyy HH:mm:ss'),
          type: 'string',
          allowCopy: false
        },
        { title: 'evaluation.ticket_details.call_info.call_id', content: this.ticketDetail.callId, type: 'string', allowCopy: true },
        { title: 'evaluation.ticket_details.call_info.agent_name', content: this.ticketDetail.agentName, type: 'string', allowCopy: false },
        { title: 'evaluation.ticket_details.call_info.agent_id', content: this.ticketDetail.agentId, type: 'string', allowCopy: true },
        {
          title: 'evaluation.ticket_details.call_info.customer_name',
          content: this.ticketDetail.customerName,
          type: 'string',
          allowCopy: false
        },
        {
          title: 'evaluation.ticket_details.call_info.customer_id',
          content: this.ticketDetail.customerId,
          type: 'string',
          allowCopy: true
        },
        {
          title: 'evaluation.ticket_details.call_info.duration',
          content: this.ticketDetail.duration,
          type: 'string',
          allowCopy: false
        },
        { 
          title: 'evaluation.ticket_details.call_info.call_start', 
          content: format(parseISO(this.ticketDetail.callStart), 'dd/MM/yyyy HH:mm:ss'), 
          type: 'string', 
          allowCopy: false 
        },
        { 
          title: 'evaluation.ticket_details.call_info.call_end', 
          content: format(parseISO(this.ticketDetail.callEnd), 'dd/MM/yyyy HH:mm:ss'), 
          type: 'string', 
          allowCopy: false 
        }
      ];
      if (this.ticketDetail.metadata && this.ticketDetail.metadata.length > 0) {
        this.ticketDetail.metadata.forEach((item) => {
          this.infoAgent.push({
            title: item.key,
            content: item.value,
            type: 'string',
            allowCopy: false
          });
        });
      }
      return;
    }
    this.infoAgent = [
      { title: 'evaluation.ticket_details.ticket_info.ticket_id', content: this.ticketDetail.id, type: 'string', allowCopy: true },
      {
        title: 'evaluation.ticket_details.ticket_info.evaluated_date',
        content: this.ticketDetail.evaluatedDate,
        type: 'date',
        allowCopy: false
      },
      { title: 'evaluation.ticket_details.ticket_info.updated_date', content: this.ticketDetail.updatedAt, type: 'date', allowCopy: false },
      { 
        title: 'evaluation.ticket_details.ticket_info.qc_username', 
        content: this.ticketDetail.qcUsername, 
        type: 'string', 
        allowCopy: false 
      },
      {
        title: 'evaluation.ticket_details.ticket_info.qc_id',
        content: this.ticketDetail.qcId,
        type: 'string',
        allowCopy: false
      },
      {
        title: 'evaluation.ticket_details.ticket_info.supervisor_name',
        content: this.ticketDetail.supervisorName,
        type: 'string',
        allowCopy: false
      },
      {
        title: 'evaluation.ticket_details.ticket_info.supervisor_id',
        content: this.ticketDetail.supervisorId,
        type: 'string',
        allowCopy: true
      },
      {
        title: 'evaluation.ticket_details.ticket_info.ticket_status',
        content: this.ticketDetail.status
          ? this.translateService.instant(`evaluation.ticket_details.statusTicket.${this.ticketDetail.status.toLocaleLowerCase()}`)
          : '',
        type: 'string',
        allowCopy: false
      },
      {
        title: 'evaluation.ticket_details.ticket_info.automation_status',
        content: this.ticketDetail.automationStatus
          ? this.translateService.instant(
            `evaluation.ticket_details.statusEvaluation.${this.ticketDetail.automationStatus.toLocaleLowerCase()}`
          )
          : '',
        type: 'string',
        allowCopy: false
      },
      {
        title: 'evaluation.ticket_details.ticket_info.qc_status',
        content: this.ticketDetail.qcStatus
          ? this.translateService.instant(`evaluation.ticket_details.statusEvaluation.${this.ticketDetail.qcStatus.toLocaleLowerCase()}`)
          : '',
        type: 'string',
        allowCopy: false
      }
    ];
  }

  ngOnInit(): void {}
}
