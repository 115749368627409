export class TicketList {
  id: string;
  fileName: string;
  fileUrl: string;
  callId: string;
  agentId: string | null;
  agentName: string;
  totalScoreAuto: number;
  totalScoreByQc: number | null;
  qcId: string;
  duration: string;
  status: string;
  contactDate: string;
  evaluatedDate: string;
  updatedDate: string;
  isEvaluatedError: string;
  qcManagerStatus: string;
  bucket: string;
  product: string;
  flag?: string;
  engineName?: string;
  qcUsername?: string;

  constructor(data: any = {}) {
    this.id = data?.ID || data?.id || data?.user_id || data?._id || '';
    this.fileName = data.fileName || '';
    this.fileUrl = data.fileUrl || '';
    this.agentId = data.agentId || '';
    this.agentName = data.agentName || '';
    this.qcId = data.qcId || '';
    this.callId = data.callId || '';
    this.status = data.status || '';
    this.totalScoreAuto = data.totalScoreAuto || '';
    this.totalScoreByQc = data.totalScoreByQc || '';
    this.duration = data.duration || '';
    this.contactDate = data.contactDate || '';
    this.evaluatedDate = data.evaluatedDate || '';
    this.updatedDate = data.updatedDate || null;
    this.isEvaluatedError = data.isEvaluatedError;
    this.qcManagerStatus = data.qcManagerStatus;
    this.bucket = data.bucket;
    this.product = data.product;
    this.flag = data?.flag || '';
    this.engineName = data?.engineName || '';
    this.qcUsername = data?.qcUsername || '';
  }
}
