import { FormGroup } from '@angular/forms';

export class FromGroupHelper {
  public static trimInputData(formGroup: FormGroup) {
    const filter = formGroup.value;
    for (const k in filter) {
      if (Object.prototype.hasOwnProperty.call(filter, k) && filter[k] && typeof filter[k] === 'string') {
        filter[k] = filter[k].trim();
      }
    }
    return filter;
  }
}
