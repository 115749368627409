type Sentiment = {
  value: string;
  confidence: number | null;
};

type Intent = {
  value: string;
  confidence: number;
};

type Amplitude = {
  avg_dB: number;
};

type Entity = {
  key: string;
  value: string[];
}

export class TranscriptModel {
  _id: string;
  file: null;
  timeStart: number;
  timeEnd: number;
  speakingSpeed: number;
  speaker: string;
  text: string;
  entities: Entity[];
  overlaps: boolean;
  isElegant: boolean;
  amp: Amplitude;
  sentiment: Sentiment[];
  intents: Intent[];

  constructor(data: {
    _id: string;
    file: null;
    start: number;
    end: number;
    speakingSpeed: number;
    speaker: string;
    text: string;
    entities: Entity[];
    overlaps: boolean;
    isElegant: boolean;
    amp: Amplitude;
    sentiment: Sentiment[];
    intents: Intent[];
  }) {
    this._id = data._id;
    this.file = data.file;
    this.timeStart = data.start;
    this.timeEnd = data.end;
    this.speakingSpeed = data.speakingSpeed;
    this.speaker = data.speaker;
    this.text = data.text;
    this.entities = data.entities;
    this.overlaps = data.overlaps;
    this.isElegant = data.isElegant;
    this.amp = data.amp;
    this.sentiment = data.sentiment;
    this.intents = data.intents;
  }
}
