import { Injectable } from '@angular/core';
import { BaseService } from '@shared/service/base.service';
import { ENDPOINT } from '@shared/config/endpoint';

@Injectable()
export class AssignedCallByQcReportService {
  constructor(private baseService: BaseService) {}

  getAssignedCallByQcReport(queryParam: {}) {
    return this.baseService.postData(`${ENDPOINT.report.assignedCallByQcReport}`, queryParam);
  }

  exportReport(queryParam = {}) {
    return this.baseService.postData(`${ENDPOINT.report.assignedCallByQcReport}/export`, queryParam);
  }

  getFilter(queryParam = {}) {
    return this.baseService.postData(`${ENDPOINT.report.callEvaluationAssignmentReport}/call-assignment-filter-values`, queryParam);
  }
}
