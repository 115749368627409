import { NgModule } from '@angular/core';
import { ModalAssignmentService } from '@shared/service/dialog/modal-assignment.service';
import { ModalAcceptAssignmentComponent } from '@shared/components/dialog/assignment/modal-accept-assignment/modal-accept-assignment.component';
import { CommonModule } from '@angular/common';
import { NgZorroAppModule } from '@ng-zorro/ng-zorro-app.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ModalAcceptAssignmentComponent],
  exports: [ModalAcceptAssignmentComponent],
  imports: [CommonModule, NgZorroAppModule, TranslateModule.forChild(), FormsModule, ReactiveFormsModule],
  providers: [ModalAssignmentService]
})
export class AssigmentModule {}
