import { ERROR_MESSAGE_CALL_ANALYSIS } from './../../../../constant/report/call-analysis';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, Subscription, timer } from 'rxjs';
import { addDays, differenceInDays, endOfDay, startOfDay } from 'date-fns';
import { debounceTime, finalize, map, take, takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';

import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { DataFormService } from '@admin/services/report-internal/dataForm.service';
import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { FormatTimeService } from '@shared/service/formatTime.service';
import { ReportSummaryService } from '@admin/services/report-internal/report-summary.service';
import { SocketService } from '@shared/service/socket.service';
import { ValidatorFormService } from '@shared/service/validatorForm.service';
import { DownloadService } from '@shared/service/download.service';
import { CallAnalysisService } from '@admin/services/report-internal/call-analysis.service';
import { STATUS_CALL_ANALYTICS } from '@admin/constant/report/call-analysis';
import { DOWNLOAD } from '@shared/constant/download';
import { OrganizationsGlobalService } from '@shared/service/organizations-global.service';
import { IParamKpiName } from '@admin/models/report-internal/call-analysis';
import { RIGHTS_CODE } from '@shared/constant/rights-code';
import { PermissionsService } from '@shared/service/permission.service';

@Component({
  selector: 'app-search-call-analysis',
  templateUrl: './search-call-analysis.component.html',
  styleUrls: ['./search-call-analysis.component.scss']
})
export class SearchCallAnalysisComponent implements OnInit, OnDestroy {
  @Output() dataSubmitForm = new EventEmitter<any>();
  @Input() lastSyncAt: string;
  RIGHTS_CODE = RIGHTS_CODE;
  searchViolationForm: FormGroup;
  submitted = false;
  isCheckEngine = true;

  listProduct: string[] = [];
  listAgency: string[] = [];
  listBucket: string[] = [];
  listUnitHead: string[] = [];
  listTeamLeader: string[] = [];
  listKpiName: string[] = [];
  listKeyWordVersion: number[] = [];
  isShowFilter = false;
  listKpiVersion: any;
  listKeywordStatus: { label: string; value: string }[] = [
    { label: STATUS_CALL_ANALYTICS.PASS, value: STATUS_CALL_ANALYTICS.PASS },
    { label: STATUS_CALL_ANALYTICS.PASS, value: STATUS_CALL_ANALYTICS.FAIL },
    { label: STATUS_CALL_ANALYTICS.PASS, value: STATUS_CALL_ANALYTICS.NA }
  ];
  currentDate = new Date();
  endDate = endOfDay(this.currentDate);
  startDate = startOfDay(addDays(this.endDate, -7));
  destroyObservable = new Subject<any>();
  exportId = uuidv4();
  exportParams = {};
  dataOrganizations: string[];
  isDisabledKWAndKPI = false;
  exportQueue: { email: string; totalJobs: number } = { email: '', totalJobs: 0 };
  visiblePopup = false;
  SECONDS_COUNTDOWN = 5;
  secondsCountDown = this.SECONDS_COUNTDOWN;
  popupType: 'SuccessPopupContent' | 'ConfirmPopupContent' | 'NoEmailContent' = 'ConfirmPopupContent';
  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent;
  private subscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private socketService: SocketService,
    public downloadService: DownloadService,
    private translateService: TranslateService,
    public formatTimeService: FormatTimeService,
    private notificationService: NzNotificationService,
    private validatorFormService: ValidatorFormService,
    private dataFormService: DataFormService,
    private evaluationService: EvaluationService,
    private reportInternalService: ReportSummaryService,
    private callAnalysisService: CallAnalysisService,
    private organizationsGlobalService: OrganizationsGlobalService,
    public permissionsService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.initSearchViolationForm();
    this.checkOrganizations();
    this.handleAutoGetDataSelect('kpiVersion');
    this.handleAutoGetDataSelect('keywordVersion');
    this.initDownloadService();
  }

  initSearchViolationForm(): void {
    this.searchViolationForm = this.formBuilder.group({
      contactDateFrom: [addDays(new Date(), -6)],
      contactDateTo: [new Date()],
      callId: [''],
      keywordStatus: [[]],
      evaluationDate: [null],
      kpiName: [[]],
      kpiStatus: [[]],
      products: [[]],
      agency: [[]],
      bucket: [[]],
      teamLeader: [[]],
      unitHead: [[]],
      kpiVersion: [null],
      keywordVersion: [null]
    });
  }

  disabledContactDateFrom = (startValue: Date): boolean => {
    const endValue = this.searchViolationForm.get('contactDateTo')?.value;
    if (!startValue || !endValue) {
      return false;
    }
    const diffInDays = differenceInDays(endValue, startValue);
    return diffInDays < 0 || diffInDays > 6;
  };

  disabledContactDateTo = (endValue: Date): boolean => {
    const startValue = this.searchViolationForm.get('contactDateFrom')?.value;
    if (!endValue || !startValue) {
      return false;
    }
    const diffInDays = differenceInDays(endValue, startValue);
    return diffInDays < 0 || diffInDays > 6;
  };

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endDatePicker.open();
      const startValue = this.searchViolationForm.get('contactDateFrom')?.value;
      const endValue = this.searchViolationForm.get('contactDateTo')?.value;
      if (startValue && endValue) {
        const diffInDays = Math.round((endValue.getTime() - startValue.getTime()) / (24 * 60 * 60 * 1000));
        if (diffInDays > 6) {
          const defaultEndDate = addDays(startValue, 6);
          this.searchViolationForm.get('contactDateTo')?.setValue(defaultEndDate);
        }
      }
    }
  }

  // handleEndOpenChange(open: boolean): void {
  //   console.log(open);
  // }

  getValueFilterAdvanced() {
    const newParam = {
      fields: ['agency', 'bucket', 'team_leader', 'unit_head', 'product', 'kpi_version', 'keyword_version'],
      engineIds: this.dataOrganizations
    };
    if (!this.searchViolationForm.get('contactDateFrom')?.value || !this.searchViolationForm.get('contactDateTo')?.value) {
      this.notificationService.create(
        'error',
        this.translateService.instant('report.error.contact_date_required'),
        this.translateService.instant('report.error.contact_date_required')
      );
    }
    const dataForm = this.dataFormService.getDataFormCallAnalysis(this.searchViolationForm, newParam);
    const params: any = this.dataFormService.removeEmptyValues(dataForm);
    this.dataFormService.getValueFilterAdvanced(params).subscribe({
      next: (res: any) => {
        this.listBucket = res.data.filterValues?.['bucket'];
        this.listTeamLeader = res.data.filterValues?.['team_leader'];
        this.listUnitHead = res.data.filterValues?.['unit_head'];
        this.listProduct = res.data.filterValues?.['product'];
        this.listKeyWordVersion = res.data.filterValues?.['keyword_version'];
        this.listKpiVersion = res.data.filterValues?.['kpi_version'];
        this.listAgency = res.data.filterValues?.['agency'];
      },
      error: (error: any) => {
        this.listAgency = [];
        this.listProduct = [];
        this.listBucket = [];
        this.listUnitHead = [];
        this.listTeamLeader = [];
        this.listKpiVersion = [];
        this.listKeyWordVersion = [];
      }
    });
  }

  handleShowFilter() {
    if (this.isCheckEngine) {
      this.isShowFilter = !this.isShowFilter;
    }
  }

  emitDataForm(): void {
    this.getValueFilterAdvanced();
    this.getKpiName();
    const newParam = {
      page: 1,
      engineIds: this.dataOrganizations
    };
    const dataForm = this.dataFormService.getDataFormCallAnalysis(this.searchViolationForm, newParam);
    this.dataSubmitForm.emit(dataForm);
  }

  checkOrganizations() {
    this.subscription = this.organizationsGlobalService.valueOrganizations$.pipe(takeUntil(this.destroyObservable)).subscribe({
      next: result => {
        if (result.org) {
          this.dataOrganizations = result.org;
          this.isDisabledKWAndKPI = result.org.length > 1 ? true : false;
          if (result.org.length > 1) {
            this.searchViolationForm.controls['kpiVersion'].patchValue(null);
            this.searchViolationForm.controls['keywordVersion'].patchValue(null);
          } else {
            this.emitDataForm();
          }
        }
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    this.emitDataForm();
  }

  handleAutoGetDataSelect(selectName: string): void {
    this.searchViolationForm
      .get(selectName)
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe(() => {
        this.emitDataForm();
      });
  }

  resetForm() {
    this.submitted = false;
    const resetValues = {
      // contactDateFrom: [addDays(new Date(), -6)],
      // contactDateTo: [new Date()],
      callId: '',
      evaluationDate: null,
      keywordStatus: [],
      kpiName: [],
      kpiStatus: [],
      products: [],
      agency: [],
      bucket: [],
      teamLeader: [],
      unitHead: [],
      kpiVersion: null,
      keywordVersion: null
    };
    this.searchViolationForm.get('contactDateFrom')?.setValue(addDays(this.currentDate, -6));
    this.searchViolationForm.get('contactDateTo')?.setValue(this.currentDate);
    this.searchViolationForm.patchValue(resetValues);
    this.submitted = false;
  }

  getKpiName() {
    const params: IParamKpiName = {
      contactDateFrom: startOfDay(this.searchViolationForm.get('contactDateFrom')?.value).toISOString(),
      contactDateTo: endOfDay(this.searchViolationForm.get('contactDateTo')?.value)?.toISOString(),
      filter: {},
      fields: ['kpi_name'],
      engineIds: this.dataOrganizations,
      kpiVersion: this.searchViolationForm.get('kpiVersion')?.value
    };

    this.callAnalysisService.getKpiName(params).subscribe({
      next: (res: any) => {
        this.listKpiName = res?.data?.kpi_name;
      },
      error: (error: any) => {
        this.listKpiName = [];
      }
    });
  }

  showPopupExport() {
    this.spinner.show();
    this.popupType = 'ConfirmPopupContent';
    this.callAnalysisService
      .getExportQueue()
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe({
        next: (res: any) => {
          this.exportQueue.email = res.data.email;
          this.exportQueue.totalJobs = res.data.totalJobs;
          this.visiblePopup = true;
        },
        error: (err: any) => {
          let message = ERROR_MESSAGE_CALL_ANALYSIS[err.errors.message];
          if (message === ERROR_MESSAGE_CALL_ANALYSIS['TOOL-00001003']) {
            this.popupType = 'NoEmailContent';
            this.visiblePopup = true;
            return;
          }
          if (!message) {
            message = 'EXPORT_HAS_ERROR';
          }
          this.notificationService.create(
            'error',
            this.translateService.instant('report.export_queue.title'),
            this.translateService.instant(`report.export_queue.${message.toLowerCase()}`)
          );
        }
      });
  }

  closePopup() {
    this.visiblePopup = false;
  }

  confirmPopup() {
    this.exportToExcel();
  }

  initDownloadService() {
    this.downloadService.isDownloadHandling = false;
    this.downloadService.downloadFileObservable.pipe(takeUntil(this.destroyObservable)).subscribe(data => {
      this.downloadService.handleDownloadData(data);
      this.socketService.reportLeaveRoom(this.exportId);
      timer(DOWNLOAD.DELAY_TIME)
        .pipe(take(1))
        .subscribe(() => {
          this.downloadService.isDownloadHandling = false;
        });
    });
  }

  async exportToExcel() {
    this.spinner.show();
    this.socketService.reportEmitRoom(this.exportId);
    this.exportParams = this.setupParams();
    if (this.exportId) {
      this.exportParams['exportId'] = this.exportId;
    }
    this.callAnalysisService
      .exportCallAnalysis(this.exportParams)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe({
        next: (res: any) => {
          this.popupType = 'SuccessPopupContent';
          this.secondsCountDown = this.SECONDS_COUNTDOWN;
          this.destroyObservable.next(null);
          this.countDown();
        },
        error: (err: any) => {
          this.notificationService.create(
            'error',
            this.translateService.instant('report.export.title'),
            this.translateService.instant('report.export.error')
          );
        }
      });
  }

  countDown() {
    timer(1000, 1000)
      .pipe(
        takeUntil(timer(this.secondsCountDown * 1000)),
        takeUntil(this.destroyObservable),
        map(() => {
          this.secondsCountDown -= 1;
        }),
        finalize(() => {
          if (this.secondsCountDown === 1) {
            this.visiblePopup = false;
          }
        })
      )
      .subscribe();
  }

  setupParams(): {} {
    const newParam = {
      page: 1,
      engineIds: this.dataOrganizations
    };
    const dataForm = this.dataFormService.getDataFormCallAnalysis(this.searchViolationForm, newParam);
    return dataForm;
  }

  ngOnDestroy(): void {
    this.destroyObservable.next(null);
    this.destroyObservable.complete();
    this.socketService.reportLeaveRoom(this.exportId);
    this.downloadService.isDownloadHandling = false;
    this.subscription.unsubscribe();
  }
}
