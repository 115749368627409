export class AudiosItems {
  id: string;
  fileName: string;
  callId: string;
  agentId: string;
  agentName: string;
  contactDate: string;
  uploadDate: string;
  status: string;
  updatedAt: string;
  numOfDone: number;
  engineName: string;

  constructor(data: any = {}) {
    this.id = data?._id;
    this.fileName = data?.fileName || '';
    this.callId = data?.callId || '';
    this.agentId = data?.agentId || '';
    this.agentName = data?.agentName || '';
    this.contactDate = data?.contactDate || true;
    this.uploadDate = data?.uploadDate;
    this.status = data?.['audioStatusIds'].slice(-1)[0]?.status || '';
    this.updatedAt = data?.['audioStatusIds'].slice(-1)[0]?.updatedAt || data.updatedAt || '';
    this.numOfDone = data.numOfDone || 0;
    this.engineName = data?.engineName || '';
  }
}
