import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  @Input() isVisible = false;
  @Input() title = '';
  @Input() description = '';
  @Input() customFooter: TemplateRef<any> | null;
  @Output() isVisibleChange = new EventEmitter<any>();
  @Output() confirmEnter = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  // Handler
  handleHideModal() {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }
}
