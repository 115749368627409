export class ReEvaluationModel {
  _id: string;
  status: string;
  createdAt: string;
  datetimeFrom: string;
  datetimeTo: string;

  constructor(data: { _id: string; status: string; createdAt: string; datetimeFrom: string; datetimeTo: string }) {
    this._id = data._id;
    this.status = data.status;
    this.createdAt = data.createdAt;
    this.datetimeFrom = data.datetimeFrom;
    this.datetimeTo = data.datetimeTo;
  }
}
