import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SwitchLanguageComponent } from '@partial/switch-language/switch-language.component';
import { NgZorroAppModule } from '@ng-zorro/ng-zorro-app.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { PaginatorComponent } from './paginator/paginator.component';

@NgModule({
  declarations: [SwitchLanguageComponent, PaginatorComponent],
  imports: [CommonModule, FormsModule, NzButtonModule, TranslateModule.forChild(), NgZorroAppModule],

  exports: [SwitchLanguageComponent, PaginatorComponent]
})
export class PartialModule {}
