export enum EVALUATION_STATUS {
  FAIL = 'FAIL',
  PASS = 'PASS'
}

export enum REVALUATION_STATUS {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  FAILED = 'FAILED'
}

export enum KPI_REASON_TYPE {
  SILENT = 'SILENT',
  KEYWORD = 'KEYWORD',
  NLP = 'NLP',
  REFER_INFORMATION = 'REFER_INFORMATION'
}

export enum KPI_REFERINFORMATION_TRACE {
  ENTITIES = 'REFER_INFORMATION_ENTITIES',
  METADATA = 'REFER_INFORMATION_METADATA'
}

export enum TICKET_STATUS {
  AUTO_EVALUATED = 'AUTO_EVALUATED',
  ASSIGNED = 'ASSIGNED',
  DONE = 'DONE'
}

export enum FLAG_EVALUATION {
  NULL = 'NULL',
  RED = 'RED',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW'
}

export const FLAG_EVALUATION_ARRAY = [
  { value: 'NULL', translateKey: 'completed_assigned.no_label' },
  { value: 'RED', translateKey: 'completed_assigned.red' },
  { value: 'GREEN', translateKey: 'completed_assigned.green' },
  { value: 'YELLOW', translateKey: 'completed_assigned.yellow' }
];
