import { io } from 'socket.io-client';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  rights: string[] = [];

  constructor(private storageService: StorageService) {
    this.rights = storageService.getRoleV1()?.rights;
    this.initStorageChanged();
  }

  initStorageChanged() {
    this.storageService.getStorageChanged().subscribe(() => {
      this.rights = this.storageService.getRoleV1()?.rights;
    });
  }

  canView(featureCodes: string[]) {
    if (!featureCodes || !featureCodes.length) {
      return true;
    }
    if (!this.rights || !this.rights.length) {
      return false;
    }
    for (let i = 0; i < this.rights.length; i++) {
      const r = this.rights[i];
      for (let j = 0; j < featureCodes.length; j++) {
        const c = featureCodes[j];
        if (r === c) {
          return true;
        }
      }
    }
    return false;
  }
}
