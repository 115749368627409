import { cloneDeep } from 'lodash';
import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { UserService } from '@admin/services/user/user.service';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { REGEX_PATTERN } from '@shared/constant/regex.constant';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, finalize, forkJoin, map, of, startWith, Observable } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-assignment-ticket',
  templateUrl: './assignment-ticket.component.html',
  styleUrls: ['./assignment-ticket.component.scss']
})
export class AssignmentTicketComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isVisible = false;
  @Output() changeVisible = new EventEmitter<any>();
  @Output() submitData = new EventEmitter<any>();
  @Input() enableAssignParams = {};
  REGEX_PATTERN = cloneDeep(REGEX_PATTERN);
  formGroup: FormGroup;
  users: FormArray;
  userIndexes$: Observable<number[]>;
  userSearch: FormControl;
  setOfCheckedUser = new Set<any>();
  numOfEnableTicket = 0;
  isSubmit = false;
  currentTicketNumber = 0;
  currentMaxTicketNumber = 0;
  destroy$ = new Subject<null>();
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private evaluationService: EvaluationService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.initFormGroup();
    this.initUserSearchIndex();
  }

  hideDialog() {
    this.isVisible = false;
    this.changeVisible.emit(this.isVisible);
  }

  addCheckedListener(formGroup: AbstractControl) {
    formGroup.get('isChecked')?.valueChanges.subscribe(isChecked => {
      const qcId = formGroup.get('_id')?.value;
      const qcUsername = formGroup.get('username')?.value;
      const qcUserCode = formGroup.get('userCode')?.value;
      if (isChecked) {
        this.setOfCheckedUser.add({ qcId, qcUsername, qcUserCode });
        return;
      }
      const obj = [...this.setOfCheckedUser].find(obj => obj.qcId === qcId);
      this.setOfCheckedUser.delete(obj);
    });
  }

  initUserSearchIndex() {
    this.userIndexes$ = this.userSearch.valueChanges.pipe(
      startWith(''),
      debounceTime(200),
      map((searchTerm: string): number[] => {
        const sanitizedSearch = searchTerm.toLowerCase().trim();
        const controlIndexes: number[] = this.users.controls.reduce((result: number[], formGroup: AbstractControl, index) => {
          const { username, userCode } = formGroup.value;
          if (username?.toLowerCase().includes(sanitizedSearch) || userCode?.toLowerCase().includes(sanitizedSearch)) {
            result.push(index);
          }
          return result;
        }, []);
        return controlIndexes;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isVisible'] && !changes['isVisible'].isFirstChange() && this.isVisible) {
      this.resetData();
      this.getEnableNumberAndListQC();
    }
  }

  resetData() {
    this.isSubmit = false;
    this.setOfCheckedUser.clear();
    this.userSearch.patchValue('', { emitEvent: false });
    this.numOfEnableTicket = 0;
  }

  initFormGroup() {
    this.userSearch = this.formBuilder.control('');
    this.formGroup = this.formBuilder.group({
      isTotal: [false],
      ticketNumber: [
        undefined,
        [Validators.required, Validators.pattern(this.REGEX_PATTERN.NUMBER), Validators.max(this.numOfEnableTicket), Validators.min(1)]
      ],
      users: this.formBuilder.array([])
    });
    this.users = this.formGroup.controls['users'] as FormArray;
  }

  handleSubmit() {
    this.isSubmit = true;
    if (!this.formGroup.get('isTotal')?.value && this.formGroup.get('ticketNumber')?.invalid) {
      return;
    }
    if (!this.setOfCheckedUser.size) {
      return;
    }
    let data = this.formGroup.value;
    data = {
      ...this.enableAssignParams,
      isTotal: data.isTotal,
      numOfEvaluationAssign: Number(data.ticketNumber),
      qcs: [...this.setOfCheckedUser]
    };
    this.submitData.emit(data);
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  getEnableNumberAndListQC() {
    this.spinner.show();
    this.clearFormArray(this.users);
    forkJoin([this.evaluationService.getNumOfEnableAssign(this.enableAssignParams), this.userService.getListQC()])
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe({
        next: (res: any) => {
          if (!res[0].data) {
            return;
          }
          this.numOfEnableTicket = res[0].data.numOfEnableAssign;
          this.initFormGroup();
          this.initUserSearchIndex();
          if (!res[1].data) {
            return;
          }
          res[1].data?.forEach(d => {
            this.users.push(this.formBuilder.group({ ...d, isChecked: false }));
          });
          this.users.controls.forEach((formGroup: AbstractControl, i) => {
            this.addCheckedListener(formGroup);
          });
          this.userSearch.patchValue('');
        }
      });
  }
}
