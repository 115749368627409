export class LatestEvaluationData {
  id: string;
  version: number;
  updatedAt: string;

  constructor(data: { _id: string; version: number; updatedAt: string }) {
    this.id = data._id;
    this.version = data.version;
    this.updatedAt = data.updatedAt || '';
  }
}

export class ListReportViolations {
  contactDate?: string;
  totalEvaluatedCalls: number;
  totalEvaluatedCallDurationsMS: number;
  totalViolatedCalls: number;
  totalAgents: number;
  totalViolatedAgents: number;
  totalViolatedWords: number;
  totalEvaluatedCallDurationsHour: number;
  violatedCallRate: string;
  violatedAgentRate: string;

  constructor(data: any) {
    this.contactDate = data.contactDate;
    this.totalEvaluatedCalls = data.totalEvaluatedCalls;
    this.totalEvaluatedCallDurationsMS = data.totalEvaluatedCallDurationsMS;
    this.totalViolatedCalls = data.totalViolatedCalls;
    this.totalAgents = data.totalAgents;
    this.totalViolatedAgents = data.totalViolatedAgents;
    this.totalViolatedWords = data.totalViolatedWords;
    this.totalEvaluatedCallDurationsHour = data.totalEvaluatedCallDurationsHour;
    this.violatedCallRate = (data.violatedCallRate * 100).toFixed(2) + '%';
    this.violatedAgentRate = (data.violatedAgentRate * 100).toFixed(2) + '%';
  }
}
