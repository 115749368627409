export class StringHelper {
  public static convertLineBreakToBr(text: string): string {
    if (!text) return '';
    return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }

  public static stringToArrayByCommaWithoutEmptySpace(str: string): string[] {
    return str.split(/[ ,]+/).filter(element => element !== '');
  }

  public static formatId(value: string | undefined): string {
    if (!value) {
      return '';
    }
    let newStr: string = '';
    for (var i = value.length - 7; i < value.length; i++) {
      newStr += value.charAt(i);
    }
    return newStr;
  }

  public static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  public static formatNumber(number: number, decimalNum: number) {
    return number.toLocaleString(undefined, {
      maximumFractionDigits: Number.isInteger(number) ? 0 : decimalNum
    });
  }
}
