import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from '@shared/service/base.service';

import { ENDPOINT } from '@shared/config/endpoint';
import { HttpParams } from '@angular/common/http';
import { IUpdateUser } from '@admin/models/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private baseService: BaseService) {}

  getUsersByEngineIds(params: {}) {
    const paramsString = new HttpParams().appendAll(params).toString();
    return this.baseService.getData(`${ENDPOINT.user.getListUsers}?${paramsString}`);
  }

  getUsers(params: {}) {
    const paramsString = new HttpParams().appendAll(params).toString();
    return this.baseService.getData(`${ENDPOINT.user.getUsers}?${paramsString}`);
  }

  getUserById(id) {
    const requestUrl = `${ENDPOINT.user.root}/${id}/${ENDPOINT.user.getDetailUser}`;
    return this.baseService.getData(requestUrl);
  }

  getListQC() {
    return this.baseService.getData(`${ENDPOINT.user.getListQC}`);
  }

  createUser(data) {
    const requestUrl = `${ENDPOINT.user.createUser}`;
    return this.baseService.postData(requestUrl, data);
  }

  updateUserById(id, data: IUpdateUser) {
    const requestUrl = `${ENDPOINT.user.root}/${id}/${ENDPOINT.user.updateUser}`;
    return this.baseService.putData(requestUrl, data);
  }

  async updateUserStatusById(id, data) {
    const requestUrl = `${ENDPOINT.user.root}/${id}/${ENDPOINT.user.updateStatus}`;
    return await this.baseService.putData(requestUrl, data);
  }

  updateUserStatusByIds(data) {
    const requestUrl = `${ENDPOINT.user.updateStatusByUserIds}`;
    return this.baseService.putData(requestUrl, data);
  }

  resetPassword(id, data) {
    const requestUrl = `${ENDPOINT.user.root}/${id}/${ENDPOINT.user.resetPassword}`;
    return this.baseService.putData(requestUrl, data);
  }

  deleteUserByIds(data) {
    const requestUrl = `${ENDPOINT.user.deleteUsers}`;
    return this.baseService.delete(requestUrl, [], data);
  }
}
