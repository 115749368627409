import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LoginComponent } from '@core/auth/components/login/login.component';
import { PartialModule } from '@partial/partial.module';
import { NgZorroAppModule } from '@ng-zorro/ng-zorro-app.module';

import { AuthRoutingModule } from '@core/auth/auth-routing.module';
import { AuthNotificationComponent } from './components/auth-notification/auth-notification.component';
import { TwoFactorAuthenticationComponent } from './components/two-factor-authentication/two-factor-authentication.component';
import { AuthService } from '@core/auth/services/auth.service';

@NgModule({
  declarations: [LoginComponent, AuthNotificationComponent, TwoFactorAuthenticationComponent],
  imports: [
    CommonModule,
    RouterModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forChild(),
    NgZorroAppModule,
    PartialModule
  ],
  providers: [AuthService]
})
export class AuthModule {}
