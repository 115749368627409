import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorGlobalService {
  private errorSubject: Subject<string> = new Subject<string>();

  constructor(private modalService: NzModalService, private translateService: TranslateService) {}

  get error$(): Observable<string> {
    return this.errorSubject.asObservable();
  }

  showError(message: string): void {
    this.errorSubject.next(message);
    this.modalService.error({
      nzTitle: 'Error',
      nzContent: this.translateService.instant(message)
    });
  }
}
