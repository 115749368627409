import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@core/auth/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '@admin/services/admin/admin.service';
import { Router } from '@angular/router';
import { SocketService } from '@shared/service/socket.service';
import { finalize } from 'rxjs';
import { ERROR_RESPONE_CODE } from '@shared/constant/respone-code.constant';
import { AUTH_NOTIFICATION_TYPE } from '@core/config/notification/auth-notification.config';
import { StorageService } from '@shared/service/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  logInForm: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl('')
  });
  showPassword = false;
  isUserOrPasswordIncorrect = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private loading: NgxSpinnerService,
    private adminService: AdminService,
    private socketService: SocketService,
    private storageService: StorageService,
    private router: Router
  ) {}

  get f(): { [key: string]: AbstractControl } {
    return this.logInForm.controls;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.logInForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.maxLength(50)]],
      password: ['', [Validators.required, Validators.maxLength(50)]]
    });
    this.logInForm.valueChanges.subscribe(x => {
      this.isUserOrPasswordIncorrect = false;
    });
  }

  // vnlp@123   fresher.team
  onSubmit(): void {
    if (!this.logInForm.valid) {
      return;
    }
    this.loading.show();
    this.authService
      .login(this.logInForm.value)
      .pipe(
        finalize(() => {
          this.loading.hide();
        })
      )
      .subscribe({
        next: res => {
          if (res['data']['id'] && res['data']['email']) {
            this.router.navigate(['auth/2fa'], {
              queryParams: { id: res['data']['id'], email: res['data']['email'] }
            });
            return;
          }
          this.adminService.setUserInfo(res.data);
          this.adminService.setStorageV1(res.data);
          this.adminService.setToken(res.data.accessToken.token);
          this.storageService.updateStorageChanged();
          this.adminService.isLogin(true);
          this.socketService.init();
          this.router.navigate(['/admin/org-owns']);
        },
        error: err => {
          if (!err.errors) {
            return;
          }
          switch (err.errors.errorCode) {
            case ERROR_RESPONE_CODE.USER_OR_PASSWORD_INCORRECT: {
              this.isUserOrPasswordIncorrect = true;
              break;
            }
            case ERROR_RESPONE_CODE.EMAIL_NOT_EXIST: {
              this.router.navigate(['auth/auth-notification'], {
                state: {
                  authNotificationType: AUTH_NOTIFICATION_TYPE.NO_EMAIL_ACCOUNT
                }
              });
              break;
            }
            default: {
              break;
            }
          }
        }
      });
  }

  toggleType(): void {
    this.showPassword = !this.showPassword;
  }
}
