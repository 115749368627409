import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

const USER_INFO = 'user_info';
const locale = 'locale';
const TOKEN = 'token';
const ENGINE_V1 = 'engine';
const USER_V1 = 'user';
const ORGANIZATION_V1 = 'org';
const ROLE_V1 = 'role';
const AVATAR_V1 = 'avatar';
const AUTHMODE_V1 = 'authMode';
const LANG_V1 = 'lang';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storageChanged$ = new BehaviorSubject(true);

  private currentLanguage$ = new BehaviorSubject('1');

  constructor() {}

  getRefreshToken() {
    const userInfoString = localStorage.getItem(USER_INFO);
    const userInfo = userInfoString ? JSON.parse(userInfoString) : {};
    return userInfo.refreshToken || '';
  }

  getCurrentLanguageSubject() {
    return this.currentLanguage$.asObservable();
  }

  setCurrentLanguageSubject(lang: string) {
    return this.currentLanguage$.next(lang);
  }

  getStorageChanged() {
    return this.storageChanged$.asObservable();
  }

  updateStorageChanged() {
    this.storageChanged$.next(true);
  }

  getUserInfo() {
    const user: any = localStorage.getItem(USER_INFO);
    return JSON.parse(user);
  }

  setUserInfo(user: any) {
    user = user && typeof user === 'object' ? JSON.stringify(user) : JSON.stringify({});
    return localStorage.setItem(USER_INFO, user);
  }

  removeUserInfo() {
    return localStorage.removeItem(USER_INFO);
  }

  setLang(lang: string) {
    return localStorage.setItem(locale, lang);
  }

  getLang() {
    return localStorage.getItem(locale);
  }

  setToken(value: string) {
    localStorage.setItem(TOKEN, value);
  }

  getToken() {
    return localStorage.getItem(TOKEN);
  }

  removeToken() {
    return localStorage.removeItem(TOKEN);
  }

  setEngineV1(value: string) {
    localStorage.setItem(ENGINE_V1, value);
  }

  removeEngineV1() {
    return localStorage.removeItem(ENGINE_V1);
  }

  setUserV1(value: string) {
    localStorage.setItem(USER_V1, value);
  }

  removeUserV1() {
    return localStorage.removeItem(USER_V1);
  }

  setOrgV1(value: string) {
    localStorage.setItem(ORGANIZATION_V1, value);
  }

  removeOrgV1() {
    return localStorage.removeItem(ORGANIZATION_V1);
  }

  setRoleV1(value: string) {
    localStorage.setItem(ROLE_V1, value);
  }

  getRoleV1() {
    return JSON.parse(localStorage.getItem(ROLE_V1) || '');
  }

  removeRoleV1() {
    return localStorage.removeItem(ROLE_V1);
  }

  setAvatarV1(value: string) {
    localStorage.setItem(AVATAR_V1, value);
  }

  removeAvatarV1() {
    return localStorage.removeItem(AVATAR_V1);
  }

  setAuthModeV1(value: string) {
    localStorage.setItem(AUTHMODE_V1, value);
  }

  removeAuthModeV1() {
    return localStorage.removeItem(AUTHMODE_V1);
  }

  setLangV1(value: string) {
    localStorage.setItem(LANG_V1, value);
  }

  removeLangV1() {
    return localStorage.removeItem(LANG_V1);
  }
}
