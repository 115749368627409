import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { POPUP_TYPE } from '@shared/constant/popup.constant';

@Component({
  selector: 'app-assignment-handling',
  templateUrl: './assignment-handling.component.html',
  styleUrls: ['./assignment-handling.component.scss']
})
export class AssignmentHandlingComponent implements OnInit {
  @Input() isVisible = false;
  @Input() title = '';
  @Input() description = '';
  @Input() popupType: POPUP_TYPE = POPUP_TYPE.INPROGRESS;
  @Output() isVisibleChange = new EventEmitter<any>();
  @Output() confirmEnter = new EventEmitter<any>();
  POPUP_TYPE = POPUP_TYPE;
  constructor() {}

  ngOnInit(): void {}

  handleHideModal() {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }
}
