import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-audio-metadata-modal',
  templateUrl: 'audio-metadata-modal.component.html',
  styleUrls: ['audio-metadata-modal.component.scss']
})

export class AudioMetadataModalComponent {

    @Input() isVisible = false;
    @Input() set audioToEdit(audio: FormGroup) { 
      this.formGroup = audio ? audio.controls['metadata'] as FormGroup : new FormGroup({});
    }
    @Input() metadataConfig: { key: string, name: string }[] = [];
    @Output() changeVisible = new EventEmitter<boolean>();
    @Output() submitData = new EventEmitter<boolean>();

    protected formGroup: FormGroup;

    stopPropagation(event: MouseEvent): void {
      event.stopPropagation();
    }

    handleHideDialog(): void {
      this.changeVisible.emit(false);
    }
    handleSave(): void {
      this.submitData.emit(false);
    }
}
