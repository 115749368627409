import { Injectable } from '@angular/core';
import { BaseService } from '@shared/service/base.service';
import { ENDPOINT } from '@shared/config/endpoint';

const VOLUMN_TIMEOUT = 120 * 1000;

@Injectable()
export class VolumnService {
  constructor(private baseService: BaseService) {}

  getListVolumn(queryParam: { reportBy: string; showBy: string; dateFrom?: Date | ''; dateTo?: Date | ''; engineIds?: string[] }) {
    return this.baseService.postData(`${ENDPOINT.report.volumn}`, queryParam, undefined, VOLUMN_TIMEOUT);
  }

  exportVolumn(queryParam = {}) {
    return this.baseService.postData(`${ENDPOINT.report.volumn}/export`, queryParam, undefined, VOLUMN_TIMEOUT);
  }
}
