import { Injectable } from '@angular/core';
import { BaseService } from '@shared/service/base.service';
import { ENDPOINT } from '@shared/config/endpoint';

const VIOLATION_REPORT_TIMEOUT = 120 * 1000;

@Injectable()
export class ReportSummaryService {
  constructor(private baseService: BaseService) {}

  getLatestEvaluationData() {
    return this.baseService.getData(ENDPOINT.report.violationSummary);
  }

  // checkLatestEvaluationData() {
  //   return this.baseService.getData(ENDPOINT.report.violationSearchEngine).pipe(
  //     mergeMap((res: any) => {
  //       if (res.data.filter.length > 0) {
  //         const requests$ = res.data.filter.map((item: any) => {
  //           const queryString = `?field=${item.field}`;
  //           return this.baseService.getData(`${ENDPOINT.report.selectViolation}${queryString}`).pipe(
  //             map((response: any) => {
  //               response.field = item.field;
  //               return response;
  //             })
  //           );
  //         });
  //         return forkJoin(requests$).pipe(
  //           map((responses: any) => {
  //             return { selectData: responses, selectSearchEngine: res.data.filter };
  //           })
  //         );
  //       } else {
  //         return of(null);
  //       }
  //     })
  //   );
  // }

  getListViolationSummary(queryParam: {
    showBy: string;
    contactDateFrom?: string;
    contactDateTo?: string;
    keywordVersion?: number;
    filter?: {};
    engineIds: string[];
  }) {
    return this.baseService.postData(`${ENDPOINT.report.reportViolationSummary}`, queryParam, undefined, VIOLATION_REPORT_TIMEOUT);
  }

  exportViolationSummary(queryParam: {}) {
    const validParams = Object.keys(queryParam).reduce((value, key) => {
      if (queryParam[key] !== undefined) {
        return { ...value, [key]: queryParam[key] };
      }
      return value;
    }, {});
    return this.baseService.postData(`${ENDPOINT.report.reportViolationSummary}/export`, validParams, undefined, VIOLATION_REPORT_TIMEOUT);
  }
}
