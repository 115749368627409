import { AudioHistory } from '@admin/models/audio-manager/audio-history.model';
import { AudioService } from '@admin/services/audio-manager/audio-manager.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-audio-history',
  templateUrl: './audio-history.component.html',
  styleUrls: ['./audio-history.component.scss']
})
export class AudioHistoryComponent implements OnInit {
  @Input() isVisible: boolean;

  @Output() changeVisible = new EventEmitter();

  @Input() audioId: string;

  audioHistory = new AudioHistory();

  constructor(private audioService: AudioService, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isVisible'] && changes['isVisible'].currentValue) {
      this.getListData(changes['audioId'].currentValue);
    }
  }

  getListData(audioId: string) {
    if (!audioId) {
      return;
    }
    this.spinner.show();
    this.audioService.getAudioHistory(audioId).subscribe({
      next: (res: any) => {
        this.audioHistory = new AudioHistory(res.data)
        this.spinner.hide();
      },
      error: (err: any) => {
        this.spinner.hide();
        this.audioHistory = new AudioHistory();
      }
    });
  }
}
