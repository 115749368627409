import { Injectable } from '@angular/core';
import { forkJoin, map, mergeMap, of } from 'rxjs';

import { BaseService } from '@shared/service/base.service';
import { ENDPOINT } from '@shared/config/endpoint';

import { IParam } from '@admin/models/report-internal/violation-by-agent';

@Injectable()
export class ViolationByAgentReportService {
  constructor(private baseService: BaseService) {}

  getLatestEvaluationData() {
    return this.baseService.getData(ENDPOINT.report.violationSummary);
  }

  checkLatestEvaluationData() {
    return this.baseService.getData(ENDPOINT.report.violationSearchEngineByAgentReport).pipe(
      mergeMap((res: any) => {
        if (res.data.filter.length > 0) {
          const requests$ = res.data.filter.map((item: any) => {
            const queryString = `?field=${item.field}`;
            return this.baseService.getData(`${ENDPOINT.report.selectViolationByAgentReport}${queryString}`).pipe(
              map((response: any) => {
                response.field = item.field;
                return response;
              })
            );
          });
          return forkJoin(requests$).pipe(
            map((responses: any) => {
              return { selectData: responses, selectSearchEngine: res.data.filter };
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  getListViolationByAgentReport(queryParam: IParam) {
    return this.baseService.postData(`${ENDPOINT.report.reportViolationByAgentReport}`, queryParam);
  }

  exportViolationSummaryByAgentReport(queryParam: {}) {
    return this.baseService.postData(`${ENDPOINT.report.reportViolationByAgentReport}/export`, queryParam);
  }
}
