import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { startOfToday, subDays } from 'date-fns';

import { HEADER_TITLE, IContentTable, IParam, ListReportViolationByAgent } from '@admin/models/report-internal/violation-by-agent';
import { ViolationByAgentReportService } from '@admin/services/report-internal/violation-by-agent-report.service';
import { DataFormService } from '@admin/services/report-internal/dataForm.service';

@Component({
  selector: 'app-violation-by-agent',
  templateUrl: './violation-by-agent.component.html',
  styleUrls: ['./violation-by-agent.component.scss']
})
export class ViolationByAgentComponent implements OnInit {
  lastSyncAt: string;
  colSettings: IContentTable[] = HEADER_TITLE;
  reportData: any[] = [];
  reportTotalData: {};
  defaultEndDate = startOfToday();
  defaultStartDate = subDays(this.defaultEndDate, 6);
  pageSize = 50;
  pageIndex: any = 1;
  eventFilter: any;

  constructor(
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private dataFormService: DataFormService,
    private violationByAgentReportService: ViolationByAgentReportService
  ) {}

  ngOnInit(): void {}

  handleFilterTicket(event: IParam) {
    if (event) {
      this.pageIndex = event.page;
      this.eventFilter = {
        page: event?.['page'],
        contactDateFrom: event?.['contactDateFrom'],
        contactDateTo: event?.['contactDateTo'],
        keywordVersion: event?.['keywordVersion'],
        agentIds: event?.['agentIds'],
        filter: event?.['filter'],
        engineIds: event?.['engineIds']
      };
      this.getReport(this.eventFilter);
    }
  }

  getRecordData(record, data): string {
    if (record.isNumber && data[record.field]) {
      return data[record.field].toLocaleString();
    }
    return data[record.field];
  }

  getFooterData(footer, data): string {
    if (footer.title) {
      return this.translateService.instant(footer.title);
    }
    if (footer.isNumber && data[footer.field]) {
      return data[footer.field].toLocaleString() || '';
    }
    return data[footer.field];
  }

  getReport(param: IParam) {
    this.spinner.show();
    const queryParam: IParam = {
      page: param.page ? param.page : this.pageIndex,
      maxSize: this.pageSize,
      contactDateFrom: param.contactDateFrom ? param.contactDateFrom : this.defaultStartDate.toISOString(),
      contactDateTo: param.contactDateTo ? param.contactDateTo : this.defaultEndDate.toISOString(),
      agentIds: param.agentIds?.length ? param.agentIds : [],
      keywordVersion: param.keywordVersion,
      filter: param.filter,
      engineIds: param.engineIds
    };
    const params: any = this.dataFormService.removeEmptyValues(queryParam);
    this.violationByAgentReportService.getListViolationByAgentReport(params).subscribe({
      next: (res: any) => {
        this.reportData = res.data.rows.map((item: any) => {
          return new ListReportViolationByAgent(item);
        });
        this.reportTotalData = res.data.summary;
        this.lastSyncAt = res.data.lastSyncAt;
        this.spinner.hide();
      },
      error: (err: any) => {
        if (err) {
          this.reportData = [];
          this.reportTotalData = {};
          this.spinner.hide();
        }
      }
    });
  }

  handleReset(event) {
    if (event) {
      this.getReport({ page: this.pageIndex });
    }
  }

  previousPage() {
    if (this.pageIndex > 1) {
      this.pageIndex--;
      if (this.eventFilter) {
        this.handleFilterTicket({ ...this.eventFilter, page: this.pageIndex });
      } else {
        this.getReport({ page: this.pageIndex });
      }
    }
  }

  nextPage() {
    this.pageIndex++;
    if (this.eventFilter) {
      this.handleFilterTicket({ ...this.eventFilter, page: this.pageIndex });
    } else {
      this.getReport({ page: this.pageIndex });
    }
  }
}
