import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, Subscription } from 'rxjs';

import { LANGUAGE } from '@shared/constant/language';
import { StorageService } from '@shared/service/storage.service';
import { SocketService } from '@shared/service/socket.service';
import { ErrorGlobalService } from '@shared/service/errorGlobal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  errorMessage: string;
  errorSubscription: Subscription;

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private titleService: Title,
    private socketSerVice: SocketService,
    private storageService: StorageService,
    private errorGlobalService: ErrorGlobalService
  ) {
    const currentLang = storageService.getLang();
    if (currentLang) {
      translateService.setDefaultLang(currentLang === LANGUAGE.EN ? LANGUAGE.VI : LANGUAGE.EN);
      translateService.use(currentLang);
      storageService.setCurrentLanguageSubject(currentLang);
      return;
    }

    translateService.setDefaultLang(LANGUAGE.EN);
    translateService.use(LANGUAGE.VI);
    storageService.setLang(LANGUAGE.VI);
    storageService.setCurrentLanguageSubject(LANGUAGE.VI);
    storageService.setLangV1(LANGUAGE.VI);
  }

  ngOnInit(): void {
    this.subscribeTitle();
    this.socketSerVice.init();
    this.errorSubscription = this.errorGlobalService.error$.subscribe(message => {
      this.errorMessage = message;
    });
  }

  subscribeTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      this.setTitle();
    });
  }

  setTitle() {
    const titles = this.getTitle(this.router.routerState, this.router.routerState.root);
    const title = titles && titles.length > 0 && titles[0];
    if (!title) {
      return;
    }
    this.translateService.get(title).subscribe((res: string) => {
      this.titleService.setTitle(`${res} - VNLP Virtual QC`);
    });
  }

  getTitle(state, parent) {
    const data: any[] = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }
    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  ngOnDestroy() {
    this.errorSubscription.unsubscribe();
  }
}
