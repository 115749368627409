import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @Input() maxSize = 10;
  @Input() page = 1;
  @Input() totalResponse = 0;
  @Output() maxSizeChange = new EventEmitter<any>();
  @Output() pageChange = new EventEmitter<any>();

  maxSizeList: { value: any; label: string }[] = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' }
  ];
  constructor() {}

  ngOnInit(): void {}

  nextPageHandle() {
    this.page += 1;
    this.pageChange.emit(this.page);
  }

  previousPageHandle() {
    this.page -= 1;
    this.pageChange.emit(this.page);
  }
}
