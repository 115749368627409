import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AudioRoutingModule } from './audio-routing.module';
import { AudioManagementComponent } from './audio-management/audio-management.component';
import { AudioTotalComponent } from './components/audio-total/audio-total.component';
import { AudioMetadataComponent } from './components/audio-metadata/audio-metadata.component';
import { AudioMetadataModalComponent } from './components/audio-metadata/components/audio-metadata-modal/audio-metadata-modal.component';
import { AudioErrorComponent } from './components/audio-error/audio-error.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgZorroAppModule } from '@ng-zorro/ng-zorro-app.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from '@shared/components/dialog/dialog.module';
import { SearchAudioComponent } from './components/search-audio/search-audio.component';
import { AudioHistoryComponent } from './components/audio-history/audio-history.component';
import { SearchAudioErrorComponent } from './components/search-audio-error/search-audio-error.component';
import { AudioService } from '@admin/services/audio-manager/audio-manager.service';

@NgModule({
  declarations: [
    AudioManagementComponent,
    AudioTotalComponent,
    AudioMetadataComponent,
    AudioMetadataModalComponent,
    AudioErrorComponent,
    SearchAudioComponent,
    AudioHistoryComponent,
    SearchAudioErrorComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    FormsModule,
    AudioRoutingModule,
    NgZorroAppModule,
    DialogModule,
    ReactiveFormsModule
  ],
  providers: [AudioService]
})
export class AudioModule {}
