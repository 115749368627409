import { BaseService } from '@shared/service/base.service';
import { Injectable } from '@angular/core';
import { ENDPOINT } from '@shared/config/endpoint';
import { filter, forkJoin, from, map, mergeMap, of, toArray } from 'rxjs';
import { IAttitudeKwBucket } from '@admin/models/report-internal/attitude-kw-bucket';

@Injectable()
export class AttitudeKwBucketService {
  constructor(private baseService: BaseService) {}

  getReportAttitudeKwBucket(params: IAttitudeKwBucket) {
    return this.baseService.postData(`${ENDPOINT.report.attitudeKwBucket}`, params);
  }

  getSearchEngine() {
    return this.baseService.getData(ENDPOINT.report.attitudeKwBucketSearchEngine).pipe(
      mergeMap((res: any) => {
        if (res.data.filter.length > 0) {
          const requests$ = res.data.filter.map((item: any) => {
            const queryString = `?field=${item.field}`;
            return this.baseService.getData(`${ENDPOINT.report.valueSelectAttitudeKwBucket}${queryString}`).pipe(
              map((response: any) => {
                // Thêm queryString vào response trả về tương ứng
                response.field = item.field;
                return response;
              })
            );
          });
          return forkJoin(requests$).pipe(
            map((responses: any) => {
              return { selectData: responses, selectSearchEngine: res.data.filter };
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  findMatchingFields(selectSearchEngine: any[], selectData: any[]) {
    return from(selectSearchEngine).pipe(
      mergeMap(searchData => {
        const { field } = searchData;
        return from(selectData).pipe(
          filter((data: any) => data.field === field),
          map(data => ({
            field: data.field,
            valueField: data.data.map((d: any) => ({ value: d.value }))
          }))
        );
      }),
      toArray()
    );
  }

  exportReportAttitudeKwBucket(queryParam: {}) {
    return this.baseService.postData(`${ENDPOINT.report.attitudeKwBucket}/export`, queryParam);
  }
}
