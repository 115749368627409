import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  yourSpinnerConfig = {
    bdColor: 'rgba(255, 255, 255, 0.7)',
    size: 'medium',
    color: '#007bff',
    type: 'square-jelly-box',
    fullScreen: false
  };

  constructor() {}

  ngOnInit(): void {}
}
