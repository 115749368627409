import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  @Input() idTicketDetail: string;
  typeInput = true;
  formComments: FormGroup = new FormGroup({
    comments: new FormControl('')
  });
  submitted = false;
  isEdit = false;
  isExpanded = false;
  comments: any;
  commentPrev = '';

  constructor(private formBuilder: FormBuilder, private evaluationService: EvaluationService, private spinner: NgxSpinnerService) {}

  get f(): { [key: string]: AbstractControl } {
    return this.formComments.controls;
  }

  ngOnInit() {
    this.initComments();
    this.getComments();
  }

  initComments() {
    this.formComments = this.formBuilder.group({
      comments: ['', []]
    });
    this.comments = this.formComments.get('comments');
  }

  getComments() {
    this.evaluationService.isSuccessAPI(false);
    this.evaluationService.getComments(this.idTicketDetail).subscribe({
      next: result => {
        if (result['data']?.['evaluationContentPlainText']) {
          const commentsControl = this.formComments.get('comments');
          if (commentsControl) {
            commentsControl.patchValue(result['data']?.['evaluationContentPlainText']);
          }
          this.typeInput = false;
          this.comments.disable();
        }
        this.evaluationService.isSuccessAPI(true);
      },
      error: err => {
        this.evaluationService.isSuccessAPI(true);
      }
    });
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.formComments.invalid) {
      return;
    } else {
      const comments = this.comments.value.trim();
      this.comments.setValue(comments);
      const params = {
        evaluationContentPlainText: comments,
        evaluationContent: ''
      };
      this.evaluationService.isSuccessAPI(false);
      this.evaluationService.changeComments(this.idTicketDetail, params).subscribe({
        next: result => {
          if (result) {
            this.typeInput = false;
            this.isExpanded = false;
            this.isEdit = false;
            this.comments.disable();
          }
          this.evaluationService.isSuccessAPI(true);
        },
        error: err => {
          this.evaluationService.isSuccessAPI(true);
        }
      });
    }
  }

  handleExpand(isExpanded: boolean) {
    this.isExpanded = isExpanded;
  }

  handleEdit(isEdit: boolean) {
    this.isEdit = isEdit;
    if (isEdit) {
      this.comments.enable();
      this.commentPrev = this.comments.value;
    } else {
      this.comments.disable();
      this.comments.setValue(this.commentPrev);
    }
  }
}
