import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthNotificationComponent } from './components/auth-notification/auth-notification.component';
import { TwoFactorAuthenticationComponent } from './components/two-factor-authentication/two-factor-authentication.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'auth-notification', component: AuthNotificationComponent },
  { path: '2fa', component: TwoFactorAuthenticationComponent, data: { title: '2fa' } },
  { path: 'login', component: LoginComponent, data: {title : 'login.title'}}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
