import { SHOW_BY } from '@admin/constant/report/violation-sumary';
import { REPORT_BY } from '@admin/constant/report/volumn';
import { AssignedCallReportModal } from '@admin/models/report-internal/assigned-call-report';
import { AssignedCallReportService } from '@admin/services/report-internal/assigned-call-report.service';
import { Component, OnInit } from '@angular/core';
import { FormatTimeService } from '@shared/service/formatTime.service';
import { startOfToday, subDays } from 'date-fns';
import { NgxSpinnerService } from 'ngx-spinner';
interface FilterParams {
  reportBy: string;
  showBy: string;
  dateFrom?: Date | '';
  dateTo?: Date | '';
  engineIds?: string[];
}
@Component({
  selector: 'app-assigned-call-report',
  templateUrl: './assigned-call-report.component.html',
  styleUrls: ['./assigned-call-report.component.scss']
})
export class AssignedCallReportComponent implements OnInit {
  lastSyncAt: string;
  volumnData: AssignedCallReportModal[] = [];
  volumnTotalData: {};
  headerList: { title: string; width: string }[] = [];
  defaultEndDate = startOfToday();
  defaultStartDate = subDays(this.defaultEndDate, 6);

  constructor(
    private spinner: NgxSpinnerService,
    public formatTimeService: FormatTimeService,
    private assignedCallReportService: AssignedCallReportService
  ) {}

  ngOnInit(): void {}

  handleFilterTicket(event: FilterParams) {
    const { reportBy, showBy, dateFrom, dateTo } = event;
    const param: FilterParams = {
      reportBy: reportBy?.length > 0 ? reportBy : '',
      showBy: showBy?.length > 0 ? showBy : '',
      dateFrom: dateFrom ? dateFrom : '',
      dateTo: dateTo ? dateTo : '',
      engineIds: event.engineIds
    };
    this.getVolumn(param);
  }

  getVolumn(param: FilterParams) {
    this.spinner.show();
    const queryParam: FilterParams = {
      reportBy: param.reportBy ? param.reportBy : REPORT_BY.UPLOAD_DATE,
      showBy: param.showBy ? param.showBy : SHOW_BY.DAY,
      dateFrom: param.dateFrom ? param.dateFrom : this.defaultStartDate,
      dateTo: param.dateTo ? param.dateTo : this.defaultEndDate,
      engineIds: param.engineIds
    };
    Object.keys(queryParam).forEach(key => queryParam[key] === '' && delete queryParam[key]);
    this.assignedCallReportService.getAssignedCallReport(queryParam).subscribe({
      next: (res: any) => {
        this.headerList = [
          {
            title:
              queryParam.reportBy === REPORT_BY.UPLOAD_DATE
                ? 'report.volumn.report_by.updated_date'
                : 'report.volumn.report_by.contact_date',
            width: '100px'
          },
          { title: 'common.table.assigned_call.total_success_calls', width: '150px' },
          { title: 'common.table.assigned_call.total_assigned_calls', width: '150px' },
          { title: 'common.table.assigned_call.total_qcs', width: '150px' },
          { title: 'common.table.assigned_call.total_completed_calls', width: '150px' },
          { title: 'common.table.assigned_call.total_assigned_calls_ratio', width: '150px' },
          { title: 'common.table.assigned_call.total_completed_calls_ratio', width: '150px' }
        ];
        this.volumnData = res.data.rows.map((item: any) => new AssignedCallReportModal(item));
        this.volumnTotalData = res.data.summary;
        this.lastSyncAt = res.data.lastSyncAt;
        this.spinner.hide();
      },
      error: (err: any) => {
        if (err) {
          this.volumnData = [];
          this.volumnTotalData = {};
          this.spinner.hide();
        }
      }
    });
  }
}
