import { RIGHTS_CODE } from '@shared/constant/rights-code';
import { cloneDeep } from 'lodash';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { EVALUATION_STATUS } from '@admin/constant/evaluation/evaluation.constant';
import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { ConvertFileZipService } from '@shared/service/convertFileZip.service';
import { TicketList } from '@admin/models/evaluation/ticketList';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription, finalize } from 'rxjs';
import { OrganizationsGlobalService } from '@shared/service/organizations-global.service';
import { POPUP_TYPE } from '@shared/constant/popup.constant';
import { StatusCodeConstants } from '@shared/constant/status-code';
import { PermissionsService } from '@shared/service/permission.service';

@Component({
  selector: 'app-ticket-unassigned',
  templateUrl: './ticket-unassigned.component.html',
  styleUrls: ['./ticket-unassigned.component.scss', '../ticket-list/ticket-list.component.scss']
})
export class TicketUnassignedComponent implements OnInit, OnDestroy {
  EVALUATION_STATUS = EVALUATION_STATUS;
  RIGHTS_CODE = RIGHTS_CODE;
  isFilter = false;
  ticketList = [];
  pageSize = 10;
  pageIndex = 1;
  filterParams: any = {};
  isDataSelectedAll = false;
  isPageSelectedAll = false;
  isShowAssignment = false;
  isShowAssignmentHandling = false;
  popupType = POPUP_TYPE.INPROGRESS;
  enableAssignParams = {};
  headerList = [
    { title: 'common.table.index', width: '80px' },
    { title: 'common.table.file_name', width: '180px' },
    { title: 'common.table.call_id', width: '180px' },
    { title: 'common.table.agent_id', width: '120px' },
    { title: 'common.table.agent_name', width: '120px' },
    { title: 'common.table.auto_score', width: '120px' },
    { title: 'common.table.qc_score', width: '120px' },
    { title: 'common.table.qc_username', width: '120px' },
    { title: 'common.table.duration', width: '120px' },
    { title: 'common.table.status', width: '120px' },
    { title: 'common.table.contact_date', width: '120px' },
    { title: 'common.table.evaluation_date', width: '120px' },
    { title: 'common.table.updated_date', width: '120px' },
    { title: 'common.table.bucket', width: '120px' },
    { title: 'common.table.product', width: '120px' },
    { title: 'common.table.engineName', width: '120px' }
  ];
  indeterminate = false;
  setOfCheckedId = new Set<any>();
  setOfExcludeId = new Set<any>();
  listAudioDownload: any;
  dataOrganizations: string[];
  private subscription: Subscription;
  isChangedPage: boolean = false;

  constructor(
    private evaluationService: EvaluationService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private convertFileZipService: ConvertFileZipService,
    private notification: NzNotificationService,
    private translateService: TranslateService,
    private organizationsGlobalService: OrganizationsGlobalService,
    public permissionsService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.subscription = this.organizationsGlobalService.valueOrganizations$.subscribe({
      next: result => {
        if (result) {
          this.dataOrganizations = result.org;
          if (this.filterParams) {
            this.handleFilterTicket(this.filterParams);
          } else {
            this.getTicketList(1);
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
      this.setOfExcludeId.delete(id);
      return;
    }
    this.setOfCheckedId.delete(id);
    this.setOfExcludeId.add(id);
  }

  onItemChecked(data: any, checked: boolean): void {
    this.updateCheckedSet(data['id'], checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.ticketList.forEach(({ id }) => {
      if (this.isChangedPage && this.setOfExcludeId.has(id)) {
        return;
      }
      this.updateCheckedSet(id, checked);
    });
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.isPageSelectedAll = !this.ticketList.length ? false : this.ticketList.every(({ id }) => this.setOfCheckedId.has(id));
    this.indeterminate = this.ticketList.some(({ id }) => this.setOfCheckedId.has(id)) && !this.isPageSelectedAll;
  }

  resetCheckbox(): void {
    this.isPageSelectedAll = false;
    this.isDataSelectedAll = false;
    this.indeterminate = false;
    this.setOfCheckedId.clear();
    this.refreshCheckedStatus();
  }

  assignTicket(data) {
    this.isShowAssignmentHandling = true;
    this.evaluationService
      .assignEvaluation(data)
      .pipe(
        finalize(() => {
          this.isShowAssignment = false;
          this.getTicketList(1);
          this.resetCheckbox();
        })
      )
      .subscribe(res => {
        if (res && res.statusCode && res.statusCode === 204) {
          this.popupType = POPUP_TYPE.SUCCESS;
          return;
        }
        this.isShowAssignmentHandling = false;
      });
  }

  changeAssignmentVisible(value: boolean) {
    if (value) {
      this.enableAssignParams = {
        ...this.filterParams,
        isAssignByListId: !this.isDataSelectedAll,
        evaluationIdsAssign: [...this.setOfCheckedId],
        evaluationIdsExclude: [...this.setOfExcludeId]
      };
    }
    this.isShowAssignment = value;
  }

  getTicketList(pageIndex?: number): void {
    const params = {
      ...this.filterParams,
      page: pageIndex,
      maxSize: this.pageSize
    };
    this.spinner.show();
    this.evaluationService.getListUnassigned(params).subscribe({
      next: res => {
        this.ticketList = res?.['data'].data.map((item: any) => {
          if (item.status) {
            item.status = `evaluation.ticket_details.statusTicket.${item.status.toLowerCase()}`;
          }
          return new TicketList(item);
        });
        if (this.isDataSelectedAll) {
          this.isChangedPage = true;
          this.onAllChecked(true);
          this.isChangedPage = false;
        }
        this.refreshCheckedStatus();
        this.spinner.hide();
      },
      error: err => {
        if (err) {
          this.ticketList = [];
          this.spinner.hide();
        }
      }
    });
  }

  handleFilterTicket(event: any) {
    this.pageIndex = 1;
    this.filterParams = event;
    this.resetSelected();
    this.getTicketList(this.pageIndex);
  }

  handleShowFilter() {
    this.isFilter = !this.isFilter;
  }

  handleInfoItem(item: any) {
    const url = this.router.serializeUrl(this.router.createUrlTree([`/admin/evaluation/ticket-info/${item.id}`]));
    window.open(url, '_blank');
  }

  previousPage() {
    if (this.pageIndex <= 1) {
      return;
    }
    this.pageIndex--;
    this.getTicketList(this.pageIndex);
  }

  nextPage() {
    this.pageIndex++;
    this.getTicketList(this.pageIndex);
  }

  downloadTicketInfo() {
    this.spinner.show();
    this.evaluationService.downloadTicketInfo({ evaluationIds: [...this.setOfCheckedId] }).subscribe({
      next: (res: any) => {
        this.convertFileZipService.downloadZipFileFromString(res.data?.fileBuffer, res.data.fileName);
        this.spinner.hide();
        this.refreshCheckedStatus();
        this.resetCheckbox();
      },
      error: (err: any) => {
        this.spinner.hide();
        this.refreshCheckedStatus();
        this.resetCheckbox();
        this.notification.create(
          'error',
          this.translateService.instant('common.message.err_download_ticket_title'),
          this.translateService.instant('common.message.err_download_ticket')
        );
      }
    });
  }

  downLoadAudio() {
    this.spinner.show();
    const audioDownload: any[] = [];
    this.ticketList.forEach(item => {
      if (this.setOfCheckedId.has(item?.['id'])) {
        audioDownload.push({
          id: item?.['id'],
          name: item?.['fileName'],
          fileUrl: item?.['fileUrl'],
          callId: item?.['callId']
        });
      }
    });
    if (!audioDownload.length) {
      return;
    }
    this.convertFileZipService.downloadAudioZip(audioDownload);
    this.logDownloadAudioAction(audioDownload);
    this.refreshCheckedStatus();
    this.resetCheckbox();
    this.spinner.hide();
  }

  logDownloadAudioAction(urlAudioDownload) {
    const evaluationIds = urlAudioDownload.map(u => {
      if (u.id) {
        return u.id;
      }
    });
    if (!evaluationIds) {
      return;
    }
    this.evaluationService.logDownloadAudioAction({ evaluationIds }).subscribe({
      error: err => {
        this.notification.create(
          'error',
          this.translateService.instant('action_history.log_action.download_evaluation_audio.title'),
          this.translateService.instant('action_history.log_action.download_evaluation_audio.error')
        );
      }
    });
  }

  handleSelectAllTicket() {
    this.isDataSelectedAll = true;
    this.onAllChecked(true);
  }

  resetSelected() {
    this.isDataSelectedAll = false;
    this.setOfCheckedId.clear();
    this.setOfExcludeId.clear();
    this.refreshCheckedStatus();
  }
}
