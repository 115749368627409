import { Injectable } from '@angular/core';
import { BaseService } from '@shared/service/base.service';
import { ENDPOINT } from '@shared/config/endpoint';

@Injectable()
export class AssignedCallReportService {
  constructor(private baseService: BaseService) {}

  getAssignedCallReport(queryParam: { reportBy: string; showBy: string; dateFrom?: Date | ''; dateTo?: Date | ''; engineIds?: string[] }) {
    return this.baseService.postData(`${ENDPOINT.report.assignedCallReport}`, queryParam);
  }

  exportReport(queryParam = {}) {
    return this.baseService.postData(`${ENDPOINT.report.assignedCallReport}/export`, queryParam);
  }
}
