import { Router } from '@angular/router';
import { IAuthNotification } from '@core/models/notification/auth-notification.model';

export enum AUTH_NOTIFICATION_TYPE {
  NO_EMAIL_ACCOUNT = 'NO_EMAIL_ACCOUNT',
  TWO_FACTOR_AUTHENTICATION = 'TWO_FACTOR_AUTHENTICATION'
}

export const AUTH_NOTIFICATION_CONFIG: {
  [field: string]: IAuthNotification;
} = {
  [AUTH_NOTIFICATION_TYPE.NO_EMAIL_ACCOUNT]: {
    browserTitle: 'auth_notification.no_email_authen.browser_title',
    title: 'auth_notification.no_email_authen.title',
    description: [
      {
        text: { content: 'auth_notification.no_email_authen.description1', style: 'color-gray-2 txt-sm my-1' }
      },
      {
        text: { content: 'auth_notification.no_email_authen.description2', style: 'color-gray-2 txt-sm my-1' }
      }
    ],
    listContent: [
      {
        style: 'd-flex align-items-center pt-2 pb-2',
        icon: { style: 'vnlp-icon vnlp-icon-phone bg-gray-2 mr-3 txt-sm' },
        text: {
          style: 'color-gray-2 txt-sm',
          content: 'auth_notification.no_email_authen.phone_number'
        }
      },
      {
        style: 'd-flex align-items-center pb-2',
        icon: { style: 'vnlp-icon vnlp-icon-email bg-gray-2 mr-3 txt-sm' },
        text: {
          style: 'color-gray-2 txt-sm',
          content: 'auth_notification.no_email_authen.email'
        }
      }
    ],
    listButton: [
      {
        style: 'form-submit btn btn-text color-secondary',
        text: {
          style: '',
          content: 'auth_notification.no_email_authen.relogin_button'
        },
        function: (router: Router) => {
          router.navigate(['/auth/login']);
        }
      }
    ]
  }
};
