import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';

import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NgxSpinnerService } from 'ngx-spinner';
import { startOfToday, subDays } from 'date-fns';

import { SHOW_BY } from '@admin/constant/report/violation-sumary';
import { REPORT_BY } from '@admin/constant/report/volumn';
import { VolumnData } from '@admin/models/report-internal/volumn';

import { SocketService } from '@shared/service/socket.service';
import { DownloadService } from '@shared/service/download.service';
import { FormatTimeService } from '@shared/service/formatTime.service';
import { VolumnService } from '@admin/services/report-internal/volumn.service';
import { ReportSummaryService } from '@admin/services/report-internal/report-summary.service';

interface FilterParams {
  reportBy: string;
  showBy: string;
  dateFrom?: Date | '';
  dateTo?: Date | '';
  engineIds?: string[];
}

@Component({
  selector: 'app-volumn',
  templateUrl: './volumn.component.html',
  styleUrls: ['./volumn.component.scss']
})
export class VolumnComponent implements OnInit {
  lastSyncAt: string;
  volumnData: VolumnData[] = [];
  volumnTotalData: {};
  headerList: { title: string; width: string }[] = [];
  defaultEndDate = startOfToday();
  defaultStartDate = subDays(this.defaultEndDate, 6);

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public formatTimeService: FormatTimeService,
    private reportInternalService: ReportSummaryService,
    private downloadService: DownloadService,
    private socketService: SocketService,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
    private volumnService: VolumnService
  ) {}

  ngOnInit(): void {}

  handleFilterTicket(event: FilterParams) {
    const { reportBy, showBy, dateFrom, dateTo } = event;
    const param: FilterParams = {
      reportBy: reportBy?.length > 0 ? reportBy : '',
      showBy: showBy?.length > 0 ? showBy : '',
      dateFrom: dateFrom ? dateFrom : '',
      dateTo: dateTo ? dateTo : '',
      engineIds: event.engineIds
    };
    this.getVolumn(param);
  }

  getVolumn(param: FilterParams) {
    this.spinner.show();
    const queryParam: FilterParams = {
      reportBy: param.reportBy ? param.reportBy : REPORT_BY.UPLOAD_DATE,
      showBy: param.showBy ? param.showBy : SHOW_BY.DAY,
      dateFrom: param.dateFrom ? param.dateFrom : this.defaultStartDate,
      dateTo: param.dateTo ? param.dateTo : this.defaultEndDate,
      engineIds: param.engineIds
    };
    Object.keys(queryParam).forEach(key => queryParam[key] === '' && delete queryParam[key]);
    this.volumnService.getListVolumn(queryParam).subscribe({
      next: (res: any) => {
        this.headerList = [
          {
            title:
              queryParam.reportBy === REPORT_BY.UPLOAD_DATE
                ? 'report.volumn.report_by.updated_date'
                : 'report.volumn.report_by.contact_date',
            width: '100px'
          },
          { title: 'common.table.volume.number_calls', width: '150px' },
          { title: 'common.table.volume.total_call_duration', width: '150px' },
          { title: 'common.table.volume.num_success_calls', width: '150px' },
          { title: 'common.table.volume.total_success_call_duration', width: '150px' },
          { title: 'common.table.volume.total_silent_calls', width: '150px' },
          { title: 'common.table.volume.total_silent_call_durations_hour', width: '150px' },
          { title: 'common.table.volume.total_error_calls', width: '150px' },
          { title: 'common.table.volume.total_error_call_durations_hour', width: '150px' }
        ];
        this.volumnData = res.data.rows.map((item: any) => new VolumnData(item));
        this.volumnTotalData = res.data.summary;
        this.lastSyncAt = res.data.lastSyncAt;
        this.spinner.hide();
      },
      error: (err: any) => {
        if (err) {
          this.volumnData = [];
          this.volumnTotalData = {};
          this.spinner.hide();
        }
      }
    });
  }
}
