import io from 'socket.io-client';
import { Injectable } from '@angular/core';
import { ENDPOINT } from '@shared/config/endpoint';
import { StorageService } from './storage.service';
import { DownloadService } from './download.service';
import { SOCKET_KEY } from '@shared/constant/socket';

let reportSocket;
@Injectable({ providedIn: 'root' })
export class SocketService {
  constructor(private storageService: StorageService, private downloadService: DownloadService) {}

  init() {
    const token = this.storageService.getToken();
    if (!token) {
      return;
    }
    if (reportSocket && reportSocket.connected) {
      return;
    }
    reportSocket = io(`${ENDPOINT.root}`, {
      transports: ['websocket'],
      reconnection: true,
      query: {
        token
      },
      path: '/report-service/socket.io'
    });
    reportSocket.on('connect', function () {});
  }

  reportEmitRoom(uuid: string) {
    if (!uuid || !reportSocket) return;
    reportSocket.on(`${SOCKET_KEY.EXPORT}-${uuid}`, data => {
      this.downloadService.downloadFileNext(data);
    });
    reportSocket.emit(SOCKET_KEY.JOIN_ROOM_WITH_EXPORT_ID, uuid);
  }

  reportLeaveRoom(uuid: string) {
    if (!uuid || !reportSocket) return;
    reportSocket.emit(SOCKET_KEY.LEAVE_ROOM_WITH_EXPORT_ID, uuid);
    reportSocket.off(`${SOCKET_KEY.EXPORT}-${uuid}`);
  }

  disconnect() {
    if (reportSocket && reportSocket.connected) {
      reportSocket.disconnect();
      reportSocket = null;
    }
  }
}
