import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-primary-layout',
  templateUrl: './primary-layout.component.html',
  styleUrls: ['./primary-layout.component.scss']
})
export class PrimaryLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
