import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { MainLayoutComponent } from './main-layout/main-layout.component';
import { PrimaryLayoutComponent } from './primary-layout/primary-layout.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '@core/layout/components/header/header.component';
import { FooterComponent } from '@core/layout/components/footer/footer.component';
import { SidebarComponent } from '@core/layout/components/sidebar/sidebar.component';
import { NgZorroAppModule } from '@ng-zorro/ng-zorro-app.module';
import { PartialModule } from '@partial/partial.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  declarations: [MainLayoutComponent, PrimaryLayoutComponent, HeaderComponent, FooterComponent, SidebarComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgZorroAppModule,
    PartialModule,
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule
  ],
  exports: [MainLayoutComponent, PrimaryLayoutComponent],
  providers: []
})
export class LayoutAllModule {}
