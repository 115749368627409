import { FLAG_EVALUATION, FLAG_EVALUATION_ARRAY } from '@admin/constant/evaluation/evaluation.constant';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FromGroupHelper } from '@shared/helper/form-group.helper';
import { ValidatorFormService } from '@shared/service/validatorForm.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search-ticket-complete-assigned',
  templateUrl: './search-ticket-complete-assigned.component.html',
  styleUrls: ['./search-ticket-complete-assigned.component.scss']
})
export class SearchTicketCompleteAssignedComponent implements OnInit {
  @Output() submitFilter = new EventEmitter<any>();
  FLAG_EVALUATION = FLAG_EVALUATION;
  FLAG_EVALUATION_ARRAY = FLAG_EVALUATION_ARRAY;
  formGroup: FormGroup;
  constructor(private formBuilder: FormBuilder, private validatorFormService: ValidatorFormService) {}

  ngOnInit(): void {
    this.initForm();
    this.subscribeFilterChange();
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      callId: ['', [this.validatorFormService.commaSeparatedStringsValidator]],
      flags: [null, [this.validatorFormService.commaSeparatedStringsValidator]]
    });
  }

  subscribeFilterChange() {
    this.formGroup.controls['callId'].valueChanges.pipe(debounceTime(800)).subscribe(x => {
      this.handleSubmit();
    });
    this.formGroup.controls['flags'].valueChanges.pipe(debounceTime(0)).subscribe(x => {
      this.handleSubmit();
    });
  }

  handleSubmit() {
    if (this.formGroup.invalid) {
      return;
    }
    const data = FromGroupHelper.trimInputData(this.formGroup);
    data.callId = data.callId ? data.callId.split(/[ ,]+/) : [];
    this.submitFilter.emit(data);
  }
}
