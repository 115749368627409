import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription, take, takeUntil, timer } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { startOfToday, subDays } from 'date-fns';

import { DOWNLOAD } from '@shared/constant/download';
import { SHOW_BY } from '@admin/constant/report/violation-sumary';
import { SocketService } from '@shared/service/socket.service';
import { DownloadService } from '@shared/service/download.service';
import { FormatTimeService } from '@shared/service/formatTime.service';
import { ValidatorFormService } from '@shared/service/validatorForm.service';
import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { ReportSummaryService } from '@admin/services/report-internal/report-summary.service';
import { LatestEvaluationData } from '@admin/models/report-internal/violation-summary';
import { DataFormService } from '@admin/services/report-internal/dataForm.service';
import { OrganizationsGlobalService } from '@shared/service/organizations-global.service';
import { RIGHTS_CODE } from '@shared/constant/rights-code';
import { PermissionsService } from '@shared/service/permission.service';

@Component({
  selector: 'app-search-violation-summary',
  templateUrl: './search-violation-summary.component.html',
  styleUrls: ['./search-violation-summary.component.scss']
})
export class SearchViolationSummaryComponent implements OnInit, OnDestroy {
  @Output() dataSubmitForm = new EventEmitter<any>();
  @Input() lastSyncAt: string;
  RIGHTS_CODE = RIGHTS_CODE;
  searchViolationForm: FormGroup;
  submitted = false;
  isCheckEngine = true;

  listProduct: string[] = [];
  listAgency: string[] = [];
  listBucket: string[] = [];
  listUnitHead: string[] = [];
  listTeamLeader: string[] = [];
  listKeyWordVersion: LatestEvaluationData[] = [];
  listShowBy: { label: string; value: string }[] = [
    { label: 'report.violation.show_by.day', value: SHOW_BY.DAY },
    { label: 'report.violation.show_by.month', value: SHOW_BY.MONTH },
    { label: 'report.violation.show_by.quarter', value: SHOW_BY.QUARTER }
  ];
  isShowFilter = false;

  destroyObservable = new Subject<any>();
  exportId = uuidv4();
  exportParams = {};
  defaultEndDate = startOfToday();
  defaultStartDate = subDays(this.defaultEndDate, 6);
  dataOrganizations: string[];
  isDisabledKW = false;
  private subscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private socketService: SocketService,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
    public formatTimeService: FormatTimeService,
    private evaluationService: EvaluationService,
    private validatorFormService: ValidatorFormService,
    private reportInternalService: ReportSummaryService,
    public downloadService: DownloadService,
    private dataFormService: DataFormService,
    private organizationsGlobalService: OrganizationsGlobalService,
    public permissionsService: PermissionsService
  ) {}

  get f(): { [key: string]: AbstractControl } {
    return this.searchViolationForm.controls;
  }

  ngOnInit(): void {
    this.initSearchViolationForm();
    this.checkOrganizations();
    this.handleAutoGetDataSelect('showBy');
    this.handleAutoGetDataSelect('kwVersion');
    this.initDownloadService();
  }

  handleShowFilter() {
    if (this.isCheckEngine) {
      this.isShowFilter = !this.isShowFilter;
    }
  }

  initSearchViolationForm(): void {
    this.searchViolationForm = this.formBuilder.group({
      contactDate: [[this.defaultStartDate, this.defaultEndDate]],
      showBy: [SHOW_BY.DAY],
      products: [[]],
      agency: [[]],
      bucket: [[]],
      teamLeader: [[]],
      unitHead: [[]],
      kwVersion: [null]
    });
  }

  getValueFilterAdvanced() {
    const newParam = {
      fields: ['agency', 'bucket', 'team_leader', 'unit_head', 'product', 'keyword_version'],
      engineIds: this.dataOrganizations
    };
    const dataForm = this.dataFormService.getDataFormViolationSummary(this.searchViolationForm, newParam);
    const params = this.dataFormService.removeEmptyValues(dataForm);
    this.dataFormService.getValueFilterAdvanced(params).subscribe({
      next: (res: any) => {
        this.listAgency = res.data.filterValues?.['agency'];
        this.listBucket = res.data.filterValues?.['bucket'];
        this.listTeamLeader = res.data.filterValues?.['team_leader'];
        this.listUnitHead = res.data.filterValues?.['unit_head'];
        this.listProduct = res.data.filterValues?.['product'];
        this.listKeyWordVersion = res.data.filterValues?.['keyword_version'];
      },
      error: (error: any) => {
        this.listAgency = [];
        this.listProduct = [];
        this.listBucket = [];
        this.listUnitHead = [];
        this.listTeamLeader = [];
        this.listKeyWordVersion = [];
      }
    });
  }

  emitDataForm(): void {
    this.getValueFilterAdvanced();
    const newParam = {
      engineIds: this.dataOrganizations
    };
    const dataForm = this.dataFormService.getDataFormViolationSummary(this.searchViolationForm, newParam);
    this.dataSubmitForm.emit(dataForm);
  }

  checkOrganizations() {
    this.subscription = this.organizationsGlobalService.valueOrganizations$.pipe(takeUntil(this.destroyObservable)).subscribe({
      next: result => {
        if (result.org) {
          this.dataOrganizations = result.org;
          this.isDisabledKW = result.org.length > 1 ? true : false;
          if (result.org.length > 1) {
            this.searchViolationForm.controls['kwVersion'].patchValue(null);
            this.searchViolationForm.controls['showBy'].patchValue(SHOW_BY.DAY);
          } else {
            this.emitDataForm();
          }
        }
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    this.emitDataForm();
  }

  handleAutoGetDataSelect(selectForm: string): void {
    this.searchViolationForm
      ?.get(selectForm)
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe(() => {
        this.emitDataForm();
      });
  }

  resetForm() {
    this.submitted = false;
    const defaultValues = {
      showBy: SHOW_BY.DAY,
      contactDate: [this.defaultStartDate, this.defaultEndDate],
      products: [],
      agency: [],
      bucket: [],
      teamLeader: [],
      unitHead: [],
      kwVersion: null
    };
    this.searchViolationForm.patchValue(defaultValues);
    this.submitted = false;
  }

  setupParams() {
    const newParam = {
      engineIds: this.dataOrganizations
    };
    const dataForm = this.dataFormService.getDataFormViolationSummary(this.searchViolationForm, newParam);
    return dataForm;
  }

  initDownloadService() {
    this.downloadService.isDownloadHandling = false;
    this.downloadService.downloadFileObservable.pipe(takeUntil(this.destroyObservable)).subscribe(data => {
      this.downloadService.handleDownloadData(data);
      this.socketService.reportLeaveRoom(this.exportId);
      timer(DOWNLOAD.DELAY_TIME)
        .pipe(take(1))
        .subscribe(() => {
          this.downloadService.isDownloadHandling = false;
        });
    });
  }

  async exportToExcel() {
    this.socketService.reportEmitRoom(this.exportId);
    this.exportParams = this.setupParams();
    if (this.exportId) {
      this.exportParams['exportId'] = this.exportId;
    }
    this.reportInternalService.exportViolationSummary(this.exportParams).subscribe({
      next: (res: any) => {
        this.downloadService.isDownloadHandling = true;
      },
      error: (err: any) => {
        this.notificationService.create(
          'error',
          this.translateService.instant('report.export.title'),
          this.translateService.instant('report.export.error')
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyObservable.next(null);
    this.destroyObservable.complete();
    this.socketService.reportLeaveRoom(this.exportId);
    this.downloadService.isDownloadHandling = false;
    this.subscription.unsubscribe();
  }
}
