export class TicketDetailModel {
  id: string;
  audioId: string;
  statusTicket: string;
  transcriptId: string;
  callId: string;
  callTime: string;
  callStart: string;
  callEnd: string;
  agentId: string;
  agentName: string;
  duration: string;
  customerId: string;
  customerName: string;
  phoneNumber: string;
  totalScoreAuto: number;
  totalScoreByQc: number;
  fileUrl: string;
  fileName: string;
  evaluatedDate: string;
  callerPhoneNumber: string;
  dialedPhoneNumber: string;
  agency: string;
  product: string;
  bucket: string;
  teamLeader: string;
  unitHead: string;
  updatedAt: string;
  qcName: string;
  qcId: string;
  supervisorName: string;
  supervisorId: string;
  status: string;
  automationStatus: string;
  qcStatus: string;
  qcUsername: string;
  metadata: {
    key: string;
    value: string;
  }[];

  constructor(data: {
    _id: string;
    audioId: string;
    transcriptId: string;
    callId: string;
    contactDate: string;
    contactEndTime: string;
    agentId: string;
    agentName: string;
    callDuration: string;
    customerId: string;
    customerName: string;
    phoneNumber: string;
    totalScoreAuto: number;
    totalScoreByQc: number;
    fileUrl: string;
    fileName: string;
    evaluatedDate: string;
    callerPhoneNumber: string;
    dialedPhoneNumber: string;
    agency: string;
    product: string;
    bucket: string;
    teamLeader: string;
    unitHead: string;
    updatedAt: string;
    qcName: string;
    qcId: string;
    supervisorName: string;
    supervisorId: string;
    status: string;
    automationStatus: string;
    qcStatus: string;
    qcUsername: string;
    metadata: {
      key: string;
      value: string;
    }[];
  }) {
    this.id = data._id || '';
    this.audioId = data.audioId || '';
    this.statusTicket = data.status || '';
    this.transcriptId = data.transcriptId || '';
    this.callId = data.callId || '';
    this.callTime = data.contactDate || '';
    this.callStart = data.contactDate || '';
    this.callEnd = data.contactEndTime || '';
    this.agentId = data.agentId || '';
    this.agentName = data.agentName || '';
    this.duration = data.callDuration || '';
    this.customerId = data.customerId || '';
    this.customerName = data.customerName || '';
    this.phoneNumber = data.phoneNumber || '';
    this.totalScoreAuto = data.totalScoreAuto || 0;
    this.totalScoreByQc = data.totalScoreByQc || 0;
    this.fileUrl = data.fileUrl || '';
    this.fileName = data.fileName || '';
    this.evaluatedDate = data.evaluatedDate || '';
    this.callerPhoneNumber = data.callerPhoneNumber || '';
    this.dialedPhoneNumber = data.dialedPhoneNumber || '';
    this.agency = data.agency || '';
    this.product = data.product || '';
    this.bucket = data.bucket || '';
    this.teamLeader = data.teamLeader || '';
    this.unitHead = data.unitHead || '';
    this.updatedAt = data.updatedAt || '';
    this.qcName = data.qcName || '';
    this.qcId = data.qcId || '';
    this.supervisorName = data.supervisorName || '';
    this.supervisorId = data.supervisorId || '';
    this.status = data.status || '';
    this.automationStatus = data.automationStatus || '';
    this.qcStatus = data.qcStatus || '';
    this.qcUsername = data.qcUsername || '';
    this.metadata = [];
  }
}
