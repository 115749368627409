import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-keywords',
  templateUrl: './keywords.component.html',
  styleUrls: ['./keywords.component.scss']
})
export class KeywordsComponent implements OnInit, OnChanges {
  @Input() idTicketDetail: string;
  listKeyWords: { keyword: string; numOfShowUps: number; keywordType: string }[] = [];
  isChecker: boolean;
  totalKeywords = 0;

  constructor(private evaluationService: EvaluationService, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {}

  getListKeywords() {
    this.evaluationService.getKeywordsAttitude(this.idTicketDetail).subscribe({
      next: (result: any) => {
        if (!result || !result.data || !result.data.attitudeKeywordResults || !result.data.totalAttitudeKeywordResults) {
          return;
        }
        this.listKeyWords = result.data.attitudeKeywordResults;
        this.totalKeywords = result.data.totalAttitudeKeywordResults;
        this.evaluationService.isSuccessAPI(true);
      },
      error: (err: {}) => {
        if (err) {
          this.listKeyWords = [];
        }
        this.evaluationService.isSuccessAPI(true);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['idTicketDetail'] && changes['idTicketDetail'].currentValue) {
      this.getListKeywords();
    }
  }

  checkChange(event: boolean): void {
    this.isChecker = event;
  }

  HandleShowKeyword(event: any) {
    const data = { keyword: event.keyword, startTime: event.startTime };
    this.evaluationService.sendKeyword(data);
  }
}
