import { Injectable } from '@angular/core';
import { BaseService } from '@shared/service/base.service';
import { HttpClient } from '@angular/common/http';
import { ENDPOINT } from '@shared/config/endpoint';

@Injectable()
export class ModalAssignmentService {
  constructor(private baseService: BaseService, private http: HttpClient) {}

  getListQC() {
    return this.baseService.getData(ENDPOINT.auth.listQC);
  }
}
