import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '@core/auth/auth.module';
import { HttpClientModule } from '@angular/common/http';
import { AuthRoutingModule } from '@core/auth/auth-routing.module';
import { LayoutAllModule } from '@core/layout/layout-all.module';
import { AuthService } from '@core/auth/services/auth.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule, AuthRoutingModule, ReactiveFormsModule, HttpClientModule, AuthModule, LayoutAllModule],
  providers: [AuthService]
})
export class CoreModule {}
