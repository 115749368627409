export enum FORM_CONTROL_STATUS {
  VALID = 'VALID',
  INVALID = 'INVALID'
}

export const INPUT_VALIDATE = {
  EMAIL: {
    MAX_LENGTH: 50
  },
  PHONE: {
    MAX_LENGTH: 20
  },
  USER_CODE: {
    MAX_LENGTH: 50
  },
  INPUT: {
    MAX_LENGTH: 100
  },
  PASSWORD: {
    MIN_LENGTH: 8,
    MAX_LENGTH: 32
  }
};

export enum FORM_TYPE {
  VIEW = 'VIEW',
  CREATE = 'CREATE',
  EDIT = 'EDIT'
}
