import { Injectable } from '@angular/core';
import { ENDPOINT } from '@shared/config/endpoint';
import { BaseService } from '@shared/service/base.service';

@Injectable()
export class AudioService {
  constructor(private baseService: BaseService) {}

  getListAudio(params) {
    const paramsObj = this.baseService.paramStringArray(params);
    return this.baseService.getData(`${ENDPOINT.audio.getListData}?${paramsObj.toString()}`);
  }

  getAudioHistory(audioId: string) {
    const requestUrl = `${ENDPOINT.audio.getHistory}/${audioId}/history`;
    return this.baseService.getData(requestUrl);
  }

  getMetadataConfig() {
    const requestUrl = `${ENDPOINT.audio.getMetadataConfig}`;
    return this.baseService.getData(requestUrl);
  }

  validateUpload(formData: FormData) {
    return this.baseService.postUpload(ENDPOINT.audio.validateUpload, formData);
  }

  manualUploadAudio(formData: FormData) {
    return this.baseService.postUpload(ENDPOINT.audio.uploadManual, formData);
  }

  getAudioError(params: any) {
    const queryString = this.baseService.paramStringArray(params);
    return this.baseService.getData(`${ENDPOINT.audio.audioError}?${queryString.toString()}`);
  }

  reEvaluateAudio(params: []) {
    return this.baseService.putData(ENDPOINT.audio.reEvaluateAudio, { audioStatusIds: params });
  }
}
