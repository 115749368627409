import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { startOfToday, subDays } from 'date-fns';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { SocketService } from '@shared/service/socket.service';
import { FormatTimeService } from '@shared/service/formatTime.service';
import { ReportSummaryService } from '@admin/services/report-internal/report-summary.service';
import { ListReportViolations } from '@admin/models/report-internal/violation-summary';
import { SHOW_BY } from '@admin/constant/report/violation-sumary';
import { OrganizationsGlobalService } from '@shared/service/organizations-global.service';
import { DataFormService } from '@admin/services/report-internal/dataForm.service';

@Component({
  selector: 'app-violation-summary',
  templateUrl: './violation-summary.component.html',
  styleUrls: ['./violation-summary.component.scss']
})
export class ViolationSummaryComponent implements OnInit {
  lastSyncAt: string;
  headerList = [
    { title: 'common.table.violation_summary.call_date', width: '100px' },
    { title: 'common.table.violation_summary.total_evaluated_calls', width: '150px' },
    { title: 'common.table.violation_summary.total_call_duration_ms', width: '150px' },
    { title: 'common.table.violation_summary.total_call_duration_hours', width: '150px' },
    { title: 'common.table.violation_summary.total_agents', width: '150px' },
    { title: 'common.table.violation_summary.total_violation_calls', width: '150px' },
    { title: 'common.table.violation_summary.violation_call_ratio', width: '150px' },
    { title: 'common.table.violation_summary.violation_agent', width: '150px' },
    { title: 'common.table.violation_summary.violation_agent_ratio', width: '150px' },
    { title: 'common.table.violation_summary.total_attitude_kw', width: '150px' }
  ];

  reportData: ListReportViolations[] = [];
  reportTotalData: {};
  defaultEndDate = startOfToday();
  defaultStartDate = subDays(this.defaultEndDate, 6);

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public formatTimeService: FormatTimeService,
    private socketService: SocketService,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
    private reportInternalService: ReportSummaryService,
    private organizationsGlobalService: OrganizationsGlobalService,
    private dataFormService: DataFormService
  ) {}

  ngOnInit(): void {}

  handleFilterTicket(event: {}) {
    if (event) {
      const param = {
        showBy: event?.['showBy'],
        contactDateFrom: event?.['contactDateFrom'],
        contactDateTo: event?.['contactDateTo'],
        keywordVersion: event?.['keywordVersion'],
        filter: event?.['filter'],
        engineIds: event?.['engineIds']
      };
      this.getValueViolationSummary(param);
    }
  }

  getValueViolationSummary(param: {
    showBy: string;
    contactDateFrom?: any;
    contactDateTo?: any;
    keywordVersion?: number;
    filter?: {};
    engineIds?: string[];
  }) {
    this.spinner.show();
    const queryParam = {
      showBy: param.showBy ? param.showBy : SHOW_BY.DAY,
      contactDateFrom: param.contactDateFrom ? param.contactDateFrom : this.defaultStartDate.toISOString(),
      contactDateTo: param.contactDateTo ? param.contactDateTo : this.defaultEndDate.toISOString(),
      keywordVersion: param.keywordVersion,
      filter: param.filter,
      engineIds: param.engineIds
    };
    const params: any = this.dataFormService.removeEmptyValues(queryParam);
    this.reportInternalService.getListViolationSummary(params).subscribe({
      next: (res: any) => {
        this.reportData = res.data.rows.map((item: any) => new ListReportViolations(item));
        this.reportTotalData = res.data.summary;
        this.lastSyncAt = res.data.lastSyncAt;
        this.spinner.hide();
      },
      error: (err: any) => {
        if (err) {
          this.reportData = [];
          this.reportTotalData = {};
          this.spinner.hide();
        }
      }
    });
  }
}
