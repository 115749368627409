import { StorageService } from '@shared/service/storage.service';
import { Injectable } from '@angular/core';
import { BaseService } from '@shared/service/base.service';
import { HttpClient } from '@angular/common/http';
import { ENDPOINT } from '@shared/config/endpoint';
import { SocketService } from '@shared/service/socket.service';

@Injectable()
export class AuthService extends BaseService {
  constructor(http: HttpClient, private socketService: SocketService, private storageService: StorageService) {
    super(http);
  }

  login(data: {}) {
    return this.postDataNoAuthen(ENDPOINT.auth.login, data);
  }

  logout() {
    this.socketService.disconnect();
    const refreshToken = this.storageService.getRefreshToken();
    return this.putData(ENDPOINT.auth.logout, { refreshToken });
  }

  verify(verifyCode: string, id: string) {
    const data = JSON.stringify({ code: verifyCode, id });
    return this.postData(ENDPOINT.auth.verify2faCode, data);
  }

  resend2faCode(id: string) {
    const data = JSON.stringify({ id });
    return this.postData(ENDPOINT.auth.resend2faCode, data);
  }
}
