import { Component, OnInit } from '@angular/core';

import { ReEvaluationModel } from '@admin/models/evaluation/re-evaluation';
import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { REVALUATION_STATUS } from '@admin/constant/evaluation/evaluation.constant';
import { NgxSpinnerService } from 'ngx-spinner';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { RIGHTS_CODE } from '@shared/constant/rights-code';
import { PermissionsService } from '@shared/service/permission.service';

@Component({
  selector: 'app-ticket-re-evaluation',
  templateUrl: './ticket-re-evaluation.component.html',
  styleUrls: ['./ticket-re-evaluation.component.scss']
})
export class TicketReEvaluationComponent implements OnInit {
  RIGHTS_CODE = RIGHTS_CODE;
  REVALUATION_STATUS = REVALUATION_STATUS;
  pageIndex = 1;
  pageSize = 10;
  header = [
    { title: 'common.table.index', width: '80px' },
    { title: 'common.table.contact_date', width: '180px' },
    { title: 'common.table.createdAt', width: '180px' },
    { title: 'common.table.status', width: '180px' }
  ];
  ticketList: [];
  isVisible = false;
  submitted = false;
  reEvaluationForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private evaluationService: EvaluationService,
    private spinner: NgxSpinnerService,
    public permissionsService: PermissionsService
  ) {}

  get f(): { [key: string]: AbstractControl } {
    return this.reEvaluationForm.controls;
  }

  ngOnInit(): void {
    this.getListRevaluationJobs(this.pageIndex);
    this.reEvaluationForm = this.formBuilder.group({
      dateRange: [[]]
    });
  }

  getListRevaluationJobs(pageIndex?: number) {
    const params = {
      page: pageIndex,
      maxSize: this.pageSize
    };
    this.spinner.show();
    this.evaluationService.getRevaluationJobs(params).subscribe({
      next: (result: any) => {
        this.ticketList = result?.['data'].data.map((item: any) => {
          return new ReEvaluationModel(item);
        });
        this.spinner.hide();
      },
      error: (error: any) => {
        if (error) {
          this.ticketList = [];
          this.spinner.hide();
        }
      }
    });
  }

  reEvaluationAll() {
    this.isVisible = true;
    this.reEvaluationForm.reset();
  }

  nextPage() {
    this.pageIndex++;
    this.getListRevaluationJobs(this.pageIndex);
  }

  previousPage() {
    if (this.pageIndex > 1) {
      this.pageIndex--;
      this.getListRevaluationJobs(this.pageIndex);
    }
  }

  handleClose() {
    this.isVisible = false;
    this.reEvaluationForm.reset();
  }

  handleConfirm() {
    const datetimeFrom = Date.parse(this.reEvaluationForm.get('dateRange')?.value?.['0']);
    const datetimeTo = Date.parse(this.reEvaluationForm.get('dateRange')?.value?.['1']);
    const dataForm = {
      datetimeFrom: datetimeFrom.toString(),
      datetimeTo: datetimeTo.toString()
    };
    this.spinner.show();
    if (dataForm) {
      this.evaluationService.RevaluationJobsMulti(dataForm).subscribe({
        next: result => {
          this.getListRevaluationJobs(this.pageIndex);
          this.reEvaluationForm.reset();
          this.isVisible = false;
          this.spinner.hide();
        },
        error: err => {
          this.reEvaluationForm.reset();
          this.isVisible = false;
          this.spinner.hide();
        }
      });
    }
  }

  getStatusText(status: any): string {
    switch (status) {
      case REVALUATION_STATUS.OPEN:
        return 're_evaluation.status.open';
      case REVALUATION_STATUS.IN_PROGRESS:
        return 're_evaluation.status.in_progress';
      case REVALUATION_STATUS.DONE:
        return 're_evaluation.status.done';
      case REVALUATION_STATUS.FAILED:
        return 're_evaluation.status.failed';
      default:
        return '';
    }
  }
}
