import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-trace-table',
  templateUrl: './trace-table.component.html',
  styleUrls: ['./trace-table.component.scss']
})
export class TraceTableComponent implements OnInit {
  @Input() traces: any[] = [];
  @Input() traceCols: any[] = [];
  @Input() supCols: any[] = [];
  @Input() tableWidth = 0;
  showNumOfKeyword = false;
  traceDetails: any[] = [];

  constructor(
    private evaluationService: EvaluationService,
    private notification: NzNotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  hyperLink(data) {
    let startTime;
    if (data && data.startTime) {
      startTime = data.startTime;
    } else if (data && data.startTranscript && data.startTranscript.startTime) {
      startTime = data.startTranscript.startTime;
    } else if (data && data.endTranscript && data.endTranscript.startTime) {
      startTime = data.endTranscript.startTime;
    }
    if (!startTime) {
      this.notification.create(
        'error',
        this.translateService.instant('kpi_result.traces.error.hyperlink.title'),
        this.translateService.instant('kpi_result.traces.error.hyperlink.message')
      );
    }
    this.evaluationService.sendKeyword({ keyword: '', startTime });
  }

  openNumberKeyword(details) {
    this.traceDetails = details;
  }

  closePopover(): void {
    this.showNumOfKeyword = false;
  }
}
