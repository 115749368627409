export class AssignedCallByQcReportModal {
  qcId: string;
  qcUsername: string;
  totalAssignedCalls: number;
  totalCompletedCalls: number;
  totalCompletedCallsRatio: string;

  constructor(data: any) {
    this.qcId = (data && data.qcId) || '';
    this.qcUsername = (data && data.qcUsername) || '';
    this.totalAssignedCalls = (data && data.totalAssignedCalls) || 0;
    this.totalCompletedCalls = (data && data.totalCompletedCalls) || 0;
    this.totalCompletedCallsRatio = (data && data.totalCompletedCallsRatio) || '';
  }
}
