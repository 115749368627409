import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgZorroAppModule } from '@ng-zorro/ng-zorro-app.module';
import { EvaluationRoutingModule } from '@admin/components/evaluation/evaluation-routing.module';
import { DialogModule } from '@shared/components/dialog/dialog.module';

import { TicketListComponent } from './ticket-list/ticket-list.component';
import { TicketDetailComponent } from './ticket-detail/ticket-detail.component';
import { KpiEstimationComponent } from '@admin/components/evaluation/components/kpi-estimation/kpi-estimation.component';
import { TranscriptComponent } from '@admin/components/evaluation/components/transcript/transcript.component';
import { KeywordsComponent } from '@admin/components/evaluation/components/keywords/keywords.component';
import { TicketInformationComponent } from '@admin/components/evaluation/components/ticket-information/ticket-information.component';
import { HistoryComponent } from '@admin/components/evaluation/components/history/history.component';
import { WaveformTicketComponent } from '@admin/components/evaluation/components/waveform-ticket/waveform-ticket.component';
import { CommentsComponent } from '@admin/components/evaluation/components/comments/comments.component';
import { SearchTicketComponent } from './components/search-ticket/search-ticket.component';
import { HistoryEvaluatedComponent } from './components/history-evaluated/history-evaluated.component';

import { ValidatorFormService } from '@shared/service/validatorForm.service';
import { ConvertFileZipService } from '@shared/service/convertFileZip.service';
import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { WaveformAudioService } from '@admin/services/evaluation/waveformAudio.service';
import { KpiResultComponent } from './components/kpi-result/kpi-result.component';
import { TicketReEvaluationComponent } from './ticket-re-evaluation/ticket-re-evaluation.component';
import { TraceContainerComponent } from './components/trace-container/trace-container.component';
import { TraceTableComponent } from './components/trace-table/trace-table.component';
import { SubTraceContainerComponent } from './components/sub-trace-container/sub-trace-container.component';
import { KpiReasonComponent } from './components/kpi-reason/kpi-reason.component';
import { TicketUnassignedComponent } from './ticket-unassigned/ticket-unassigned.component';
import { AssigmentModule } from '@shared/components/dialog/assignment/assigment.module';
import { TicketAssignedComponent } from './ticket-assigned/ticket-assigned.component';
import { TicketCompleteAssignedComponent } from './ticket-complete-assigned/ticket-complete-assigned.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { AssignmentTicketComponent } from './components/assignment-ticket/assignment-ticket.component';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { AssignmentHandlingComponent } from './components/assignment-handling/assignment-handling.component';
import { AssignmentUnassignedComponent } from './components/assignment-unassigned/assignment-unassigned.component';
import { SearchTicketCompleteAssignedComponent } from './components/search-ticket-complete-assigned/search-ticket-complete-assigned.component';
import { UpdateTicketStatusComponent } from './components/update-ticket-status/update-ticket-status.component';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { EmptyContentComponent } from './components/empty-content/empty-content.component';

@NgModule({
  declarations: [
    TicketListComponent,
    TicketDetailComponent,
    KpiEstimationComponent,
    TranscriptComponent,
    KeywordsComponent,
    TicketInformationComponent,
    HistoryComponent,
    WaveformTicketComponent,
    CommentsComponent,
    SearchTicketComponent,
    HistoryEvaluatedComponent,
    KpiResultComponent,
    TicketReEvaluationComponent,
    TraceContainerComponent,
    TraceTableComponent,
    SubTraceContainerComponent,
    KpiReasonComponent,
    TicketUnassignedComponent,
    TicketAssignedComponent,
    TicketCompleteAssignedComponent,
    AssignmentTicketComponent,
    AssignmentHandlingComponent,
    AssignmentUnassignedComponent,
    SearchTicketCompleteAssignedComponent,
    UpdateTicketStatusComponent,
    EmptyContentComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    NzButtonModule,
    NzDividerModule,
    EvaluationRoutingModule,
    NgZorroAppModule,
    FormsModule,
    DialogModule,
    ReactiveFormsModule,
    NzRadioModule,
    NzCollapseModule,
    AssigmentModule
  ],
  providers: [EvaluationService, ValidatorFormService, ConvertFileZipService, WaveformAudioService]
})
export class EvaluationModule {}
