import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportInternalRoutingModule } from './report-internal-routing.module';
import { ViolationSummaryComponent } from './violation-summary/violation-summary.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchViolationSummaryComponent } from '@admin/components/report-internal/components/search-violation-summary/search-violation-summary.component';
import { SearchVolumnComponent } from './components/search-volumn/search-volumn.component';
import { CallAnalysisComponent } from './call-analysis/call-analysis.component';
import { VolumnService } from '@admin/services/report-internal/volumn.service';
import { SearchCallAnalysisComponent } from './components/search-call-analysis/search-call-analysis.component';

import { ViolationByAgentComponent } from '@admin/components/report-internal/violation-by-agent/violation-by-agent.component';
import { SearchViolationByAgentComponent } from './components/search-violation-by-agent/search-violation-by-agent.component';
import { AttitudeKwBucketComponent } from '@admin/components/report-internal/attitude-kw-bucket/attitude-kw-bucket.component';
import { SearchAttitudeKwBucketComponent } from '@admin/components/report-internal/components/search-attitude-kw-bucket/search-attitude-kw-bucket.component';
import { AttitudeKwBucketService } from '@admin/services/report-internal/attitude-kw-bucket.service';
import { FormatTimeService } from '@shared/service/formatTime.service';
import { NgZorroAppModule } from '@ng-zorro/ng-zorro-app.module';
import { ViolationByAgentReportService } from '@admin/services/report-internal/violation-by-agent-report.service';
import { ReportSummaryService } from '@admin/services/report-internal/report-summary.service';
import { DataFormService } from '@admin/services/report-internal/dataForm.service';
import { CallAnalysisService } from '@admin/services/report-internal/call-analysis.service';
import { VolumnComponent } from '@admin/components/report-internal/volumn/volumn.component';
import { AssignedCallReportComponent } from './assigned-call-report/assigned-call-report.component';
import { AssignedCallReportService } from '@admin/services/report-internal/assigned-call-report.service';
import { SearchAssignedCallReportComponent } from './components/search-assigned-call-report/search-assigned-call-report.component';
import { AssignedCallByQcReportComponent } from './assigned-call-by-qc-report/assigned-call-by-qc-report.component';
import { SearchAssignedCallByQcReportComponent } from './components/search-assigned-call-by-qc-report/search-assigned-call-by-qc-report.component';
import { AssignedCallByQcReportService } from '@admin/services/report-internal/assigned-call-by-qc-report.service';

@NgModule({
  declarations: [
    ViolationSummaryComponent,
    SearchViolationSummaryComponent,
    VolumnComponent,
    SearchVolumnComponent,
    ViolationByAgentComponent,
    SearchViolationByAgentComponent,
    CallAnalysisComponent,
    SearchCallAnalysisComponent,
    AttitudeKwBucketComponent,
    SearchAttitudeKwBucketComponent,
    AssignedCallReportComponent,
    SearchAssignedCallReportComponent,
    AssignedCallByQcReportComponent,
    SearchAssignedCallByQcReportComponent
  ],
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, FormsModule, NgZorroAppModule, ReportInternalRoutingModule],
  providers: [
    ReportSummaryService,
    CallAnalysisService,
    VolumnService,
    AttitudeKwBucketService,
    FormatTimeService,
    ViolationByAgentReportService,
    DataFormService,
    AssignedCallReportService,
    AssignedCallByQcReportService
  ]
})
export class ReportInternalModule {}
