import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormatTimeService } from '@shared/service/formatTime.service';
import { ValidatorFormService } from '@shared/service/validatorForm.service';
import { EvaluationService } from '@admin/services/evaluation/evaluation.service';

@Component({
  selector: 'app-search-ticket',
  templateUrl: './search-ticket.component.html',
  styleUrls: ['./search-ticket.component.scss']
})
export class SearchTicketComponent implements OnInit {
  @Output() dataSubmitForm = new EventEmitter<any>();
  searchTicketForm: FormGroup;
  submitted = false;
  statusTicket: Array<{ label: string; value: string }> = [
    { label: 'evaluation.ticket_details.statusTicket.auto_evaluated', value: 'AUTO_EVALUATED' },
    { label: 'evaluation.ticket_details.statusTicket.assigned', value: 'ASSIGNED' },
    // { label: 'evaluation.ticket_details.statusTicket.transfer_to_super', value: 'TRANSFER_TO_SUPERVISOR' },
    // { label: 'evaluation.ticket_details.statusTicket.verifying', value: 'VERIFYING' },
    // { label: 'evaluation.ticket_details.statusTicket.responded', value: 'RESPONDED' },
    // { label: 'evaluation.ticket_details.statusTicket.verified', value: 'VERIFIED' },
    { label: 'evaluation.ticket_details.statusTicket.done', value: 'DONE' },
    // { label: 'evaluation.ticket_details.statusTicket.reevaluating', value: 'REEVALUATING' },
    // { label: 'evaluation.ticket_details.statusTicket.qc_evaluating', value: 'QC_EVALUATING' }
  ];
  listBucket: [];
  listProduct: [];

  constructor(
    private formBuilder: FormBuilder,
    private formatTimeService: FormatTimeService,
    private validatorFormService: ValidatorFormService,
    private evaluationService: EvaluationService
  ) {}

  get f(): { [key: string]: AbstractControl } {
    return this.searchTicketForm.controls;
  }

  callIdError() {
    return (
      this.searchTicketForm.get('callId')?.invalid &&
      (this.searchTicketForm.get('callId')?.dirty || this.searchTicketForm.get('callId')?.touched)
    );
  }

  ngOnInit(): void {
    this.getBucketAndProduct();
    this.searchTicketForm = this.formBuilder.group({
      fileName: [''],
      callId: [[], [this.validatorFormService.commaSeparatedStringsValidator]],
      agentId: [''],
      agentName: [''],
      qcUsername: [''],
      status: [[]],
      autoScore: this.formBuilder.group(
        {
          autoScoreFrom: ['', [Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
          autoScoreTo: ['', [Validators.pattern(/^\d+(\.\d{1,2})?$/)]]
        },
        { validator: this.validatorFormService.rangeValidator('autoScoreFrom', 'autoScoreTo') }
      ),
      qcScore: this.formBuilder.group(
        {
          qcScoreFrom: ['', [Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
          qcScoreTo: ['', [Validators.pattern(/^\d+(\.\d{1,2})?$/)]]
        },
        { validator: this.validatorFormService.rangeValidator('qcScoreFrom', 'qcScoreTo') }
      ),
      duration: this.formBuilder.group(
        {
          durationFrom: [null],
          durationTo: [null]
        },
        { validator: this.validatorFormService.timeRangeValidator('durationFrom', 'durationTo') }
      ),
      contactDate: [null],
      evaluatedDate: [null],
      updateDate: [null],
      bucket: [[]],
      products: [[]]
    });
  }

  onSubmit() {
    this.submitted = true;
    this.validatorFormService.trimInputs(this.searchTicketForm.controls);
    // const callIdArray = this.searchTicketForm.get('callId')?.value;
    // const trimmedCallIdArray = callIdArray.map((value: string) => value.trim());
    // this.searchTicketForm.get('callId')?.setValue(trimmedCallIdArray);
    // Duration form
    const durationFrom =
      this.searchTicketForm.get('duration')?.get('durationFrom')?.value > 0
        ? this.searchTicketForm.get('duration')?.get('durationFrom')?.value
        : '';
    const durationFromMs = this.formatTimeService.formatToMilliseconds2(durationFrom);
    // Duration to
    const durationTo =
      this.searchTicketForm.get('duration')?.get('durationTo')?.value > 0
        ? this.searchTicketForm.get('duration')?.get('durationTo')?.value
        : '';
    const durationToMs = this.formatTimeService.formatToMilliseconds2(durationTo);

    // Contact date
    const contactDateFromMs = Date.parse(this.searchTicketForm.get('contactDate')?.value?.['0']);
    const contactDateToMs = Date.parse(this.searchTicketForm.get('contactDate')?.value?.['1']);
    // Evaluated Date
    const evaluatedDateFromMs = Date.parse(this.searchTicketForm.get('evaluatedDate')?.value?.['0']);
    const evaluatedDateToMs = Date.parse(this.searchTicketForm.get('evaluatedDate')?.value?.['1']);
    // Update Date
    const updateDateFromMs = Date.parse(this.searchTicketForm.get('updateDate')?.value?.['0']);
    const updateDateToMs = Date.parse(this.searchTicketForm.get('updateDate')?.value?.['1']);

    //call id
    const callIdValue = this.searchTicketForm.get('callId')?.value;
    const callIds = typeof callIdValue === 'string' && callIdValue ? callIdValue.split(/[ ,]+/) : [];
    const dataForm = {
      page: 0,
      maxSize: 10,
      fileName: this.searchTicketForm.get('fileName')?.value || '',
      callId: callIds,
      agentId: this.searchTicketForm.get('agentId')?.value || '',
      agentName: this.searchTicketForm.get('agentName')?.value || '',
      qcUsername: this.searchTicketForm.get('qcUsername')?.value || '',
      statuses: this.searchTicketForm.get('status')?.value || null,
      autoScoreFrom: parseFloat(this.searchTicketForm.get('autoScore')?.get('autoScoreFrom')?.value) || null,
      autoScoreTo: parseFloat(this.searchTicketForm.get('autoScore')?.get('autoScoreTo')?.value) || null,
      qcScoreFrom: parseFloat(this.searchTicketForm.get('qcScore')?.get('qcScoreFrom')?.value) || null,
      qcScoreTo: parseFloat(this.searchTicketForm.get('qcScore')?.get('qcScoreTo')?.value) || null,
      callDurationFrom: durationFromMs ? durationFromMs : 0,
      callDurationTo: durationToMs ? durationToMs : 0,
      evaluatedDateStart: evaluatedDateFromMs ? evaluatedDateFromMs : null,
      evaluatedDateEnd: evaluatedDateToMs ? evaluatedDateToMs : null,
      updatedAtStart: updateDateFromMs ? updateDateFromMs : null,
      updatedAtEnd: updateDateToMs ? updateDateToMs : null,
      contactDateStart: contactDateFromMs ? contactDateFromMs : null,
      contactDateEnd: contactDateToMs ? contactDateToMs : null,
      bucket: this.searchTicketForm.get('bucket')?.value || null,
      product: this.searchTicketForm.get('products')?.value || null
    };
    this.dataSubmitForm.emit(dataForm);
  }

  resetForm() {
    this.submitted = false;
    this.searchTicketForm.reset();
  }

  getBucketAndProduct() {
    this.evaluationService.getBucketAndProduct().subscribe({
      next: res => {
        this.listBucket = res?.['data']?.['buckets'];
        this.listProduct = res?.['data']?.['products'];
      },
      error: err => {
        this.listBucket = [];
        this.listProduct = [];
      }
    });
  }
}
