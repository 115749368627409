export class AudioHistory {
  id: string;
  callId: string;
  contactDate: Date;
  agentId: string;
  agentName: string;
  numOfDone: number;
  audioStatus: any[];
  audioHistories: any[];

  constructor(data: any = {}) {
    this.id = data?._id;
    this.callId = data?.callId || '';
    this.agentId = data?.agentId || '';
    this.agentName = data?.agentName || '';
    this.contactDate = data?.contactDate || undefined;
    this.numOfDone = data?.numOfDone || 0;
    this.audioStatus = data?.audioStatus || [];
    this.audioHistories = data?.audioHistories || [];
  }
}
