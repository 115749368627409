import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AdminRoutingModule } from './admin-routing.module';
import { EvaluationModule } from '@admin/components/evaluation/evaluation.module';
import { AudioModule } from '@admin/components/audio/audio.module';
import { ReportInternalModule } from '@admin/components/report-internal/report-internal.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule, AudioModule, AdminRoutingModule, EvaluationModule, ReportInternalModule]
})
export class AdminModule {}
