import { cloneDeep } from 'lodash';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { EstimationKpiModel } from '@admin/models/evaluation/est-kpi';

@Component({
  selector: 'app-kpi-estimation',
  templateUrl: './kpi-estimation.component.html',
  styleUrls: ['./kpi-estimation.component.scss']
})
export class KpiEstimationComponent implements OnChanges, OnInit {
  @Input() idTicketDetail: string;
  @Input() totalKpi: any;
  @Input() isChangeStatus: any;
  @Output() changeStatusQC: EventEmitter<any> = new EventEmitter();

  newStatus: { id: string; status: string }[] = [];
  isShow = false;
  listOfData: EstimationKpiModel[] = [];
  listOfDataOriginal: EstimationKpiModel[] = [];
  selectedValues: any = {};
  statusQC = [
    { value: 'NA', label: 'NA' },
    { value: 'PASS', label: 'Pass' },
    { value: 'FAIL', label: 'Fail' }
  ];
  kpiId = '';
  showReason = false;

  constructor(private evaluationService: EvaluationService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['totalKpi'] && changes['totalKpi'].currentValue?.totalScoreAuto) {
      this.totalKpi = changes['totalKpi'].currentValue;
    }
    if (changes['isChangeStatus'] && changes['isChangeStatus']?.currentValue) {
      this.isChangeStatus = changes['isChangeStatus'].currentValue;
    }
    if (changes['idTicketDetail'] && changes['idTicketDetail']?.currentValue) {
      this.handleGetEstKpi(this.idTicketDetail);
    }
    if (this.isChangeStatus === 'change') {
      const param = {
        evaluationId: this.idTicketDetail,
        kpiResults: this.newStatus
      };
      this.evaluationService.isSuccessAPI(false);
      this.evaluationService.changeStatusQCKpi(param).subscribe({
        next: result => {
          this.changeStatusQC.emit('open');
          this.newStatus = [];
          this.handleGetEstKpi(this.idTicketDetail);
          this.evaluationService.isSuccessAPI(true);
        },
        error: err => {
          this.changeStatusQC.emit('close');
          this.evaluationService.isSuccessAPI(true);
        }
      });
    }
    if (this.isChangeStatus === 'close') {
      this.listOfData = cloneDeep(this.listOfDataOriginal);
    }
  }

  ngOnInit(): void {
    this.handleGetEstKpi(this.idTicketDetail);
  }

  handleGetEstKpi(id) {
    this.evaluationService.isSuccessAPI(false);
    this.evaluationService.getEstKpi(id).subscribe({
      next: (result: any) => {
        this.listOfData = result['data'].map((data: any) => {
          return new EstimationKpiModel(data);
        });
        this.listOfDataOriginal = cloneDeep(this.listOfData);
        this.evaluationService.isSuccessAPI(true);
      },
      error: (error: any) => {
        this.evaluationService.isSuccessAPI(true);
      }
    });
  }

  selectStatus(value: string, id: string, indexEstimation: number, indexKpi: number) {
    this.selectedValues[id] = value;
    for (const key in this.selectedValues) {
      if (Object.prototype.hasOwnProperty.call(this.selectedValues, key)) {
        this.newStatus.push({ status: this.selectedValues[key], id: key });
      }
    }
    this.selectedValues = {};
    this.listOfData[indexEstimation].kpis[indexKpi].isPassNaByQc = value === 'NA';
    if (value !== 'NA') {
      this.listOfData[indexEstimation].kpis[indexKpi].statusByQc = value;
    }
  }

  openReason(item) {
    this.kpiId = item.id;
    this.showReason = true;
  }

  hideReason() {
    this.showReason = false;
  }
}
