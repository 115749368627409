import { AdminService } from '@admin/services/admin/admin.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@core/auth/services/auth.service';
import { AUTH_NOTIFICATION_TYPE } from '@core/config/notification/auth-notification.config';
import { TranslateService } from '@ngx-translate/core';
import { ERROR_RESPONE_CODE } from '@shared/constant/respone-code.constant';
import { SocketService } from '@shared/service/socket.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { timer, Subject } from 'rxjs';
import { map, takeUntil, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-two-factor-authentication',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.scss']
})
export class TwoFactorAuthenticationComponent implements OnInit {
  id: string;
  email = '';
  disabledResend = true;
  isInvalidCode = false;
  VERIFY_CODE_MAXLENGTH = 6;
  RESEND_SECONDS = 60;
  seconds = this.RESEND_SECONDS;
  stopCountDown = new Subject();
  AUTH_NOTIFICATION_TYPE = AUTH_NOTIFICATION_TYPE;
  formGroup: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private authService: AuthService,
    private adminService: AdminService,
    private socketService: SocketService,
    private notification: NzNotificationService
  ) {}

  ngOnInit(): void {
    this.initTwoFactorAuthInfo();
    this.initForm();
    this.countDown();
  }

  initTwoFactorAuthInfo() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params['id'];
      this.email = params['email'];
      if (!this.id || !this.email) {
        this.router.navigate(['/auth/login']);
        return;
      }
    });
  }

  redirectToLogin() {
    this.router.navigate(['auth/login']);
  }

  countDown() {
    timer(1000, 1000)
      .pipe(
        takeUntil(timer(this.seconds * 1000)),
        takeUntil(this.stopCountDown),
        map(() => {
          this.seconds -= 1;
        }),
        finalize(() => {
          if (this.seconds === 1) {
            this.disabledResend = false;
          }
        })
      )
      .subscribe();
  }

  initForm() {
    this.formGroup = this.formBuilder.group({
      verifyCode: ['', [Validators.required, Validators.maxLength(this.VERIFY_CODE_MAXLENGTH)]]
    });
    const verifyCode = this.formGroup.get('verifyCode');
    if (!verifyCode) {
      return;
    }
    verifyCode.valueChanges.subscribe(x => {
      this.isInvalidCode = false;
    });
  }

  onSubmit(): void {
    const verifyCode = this.formGroup.get('verifyCode');
    if (!verifyCode) {
      return;
    }
    if (!verifyCode.value || !this.id) {
      this.isInvalidCode = true;
      return;
    }
    this.authService.verify(verifyCode.value, this.id).subscribe({
      next: res => {
        this.adminService.setUserInfo(res.data);
        this.adminService.setStorageV1(res.data);
        this.adminService.setToken(res.data.accessToken.token);
        this.adminService.isLogin(true);
        this.socketService.init();
        this.router.navigate(['/admin/audio-management']);
      },
      error: err => {
        if (err.errors && err.errors.errorCode === ERROR_RESPONE_CODE.VERIFY_CODE_IS_INVALID) {
          this.isInvalidCode = true;
        }
      }
    });
  }

  resetCountDown() {
    this.disabledResend = true;
    this.seconds = this.RESEND_SECONDS; 
    this.countDown();
  }

  resendCode(): void {
    if (!this.id) {
      this.router.navigate(['/auth/login']);
      return;
    }
    this.authService.resend2faCode(this.id).subscribe({
      next: res => {
        this.notification.create(
          'success',
          this.translateService.instant('two_factor_authen.resend_code_notification'),
          this.translateService.instant('two_factor_authen.resend_code_success')
        );
        this.resetCountDown();
      },
      error: err => {
        if (err.errors && err.errors.errorCode === ERROR_RESPONE_CODE.OTP_STILL_ALIVE_CANT_SEND_MAIL) {
          this.notification.create(
            'error',
            this.translateService.instant('two_factor_authen.resend_code_notification'),
            this.translateService.instant('two_factor_authen.resend_code_still_alive')
          );
          return;
        }
        this.notification.create(
          'error',
          this.translateService.instant('two_factor_authen.resend_code_notification'),
          this.translateService.instant('two_factor_authen.resend_code_error')
        );
        return;
      }
    });
  }
}
