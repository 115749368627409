import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import { AccountBookFill, AlertFill, AlertOutline } from '@ant-design/icons-angular/icons';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';

const icons: IconDefinition[] = [AccountBookFill, AlertOutline, AlertFill];

const modules: any = [
  NzIconModule.forRoot(icons),
  NzSwitchModule,
  NgxSpinnerModule,
  NzTableModule,
  NzDividerModule,
  NzPopconfirmModule,
  NzTreeSelectModule,
  NzDatePickerModule,
  NzInputModule,
  NzMenuModule,
  NzToolTipModule,
  NzLayoutModule,
  NzBreadCrumbModule,
  NzEmptyModule,
  NzSelectModule,
  NzModalModule,
  NzUploadModule,
  NzMessageModule,
  NzTabsModule,
  NzDropDownModule,
  NzTagModule,
  NzCheckboxModule,
  NzInputNumberModule,
  NzTimePickerModule,
  ScrollingModule,
  NzPaginationModule,
  NzNotificationModule,
  NzPopoverModule,
  NzWaveModule
];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...modules],
  providers: [{ provide: NZ_I18N, useValue: en_US }],
  exports: [...modules]
})
export class NgZorroAppModule {}
