import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-sub-trace-container',
  templateUrl: './sub-trace-container.component.html',
  styleUrls: ['./sub-trace-container.component.scss']
})
export class SubTraceContainerComponent implements OnInit {
  @Input() traceDetails: any[] = [];
  traces: any[] = [];
  traceCols: any[] = [];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['traceDetails'] && changes['traceDetails'].currentValue) {
      this.setUpTable();
    }
  }

  setUpTable() {
    this.traces = this.traceDetails;
    this.traceCols = [
      { title: 'kpi_result.traces.index', field: 'index', textAlign: 'center', template: 'DefaultCol', width: '80px' },
      { title: 'kpi_result.traces.time_stamp', field: 'timestamp', textAlign: 'center', template: 'Hyperlink', width: '' }
    ];
  }
}
