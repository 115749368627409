import { Component, Input, OnInit } from '@angular/core';
import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { FormatTimeService } from '@shared/service/formatTime.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-history-evaluated',
  templateUrl: './history-evaluated.component.html',
  styleUrls: ['./history-evaluated.component.scss']
})
export class HistoryEvaluatedComponent implements OnInit {
  @Input() data: any;

  constructor(private evaluationService: EvaluationService, public formatTimeService: FormatTimeService, private router: Router) {}

  ngOnInit(): void {}

  handleDetails(value: any) {
    const url = this.router.createUrlTree([`/admin/evaluation/ticket-info/${value?.['evaluationId']}`]);
    window.open(this.router.serializeUrl(url), '_blank');
  }
}
