import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { endOfDay, startOfDay } from 'date-fns';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataFormService } from '@admin/services/report-internal/dataForm.service';
import { FormatTimeService } from '@shared/service/formatTime.service';
import { StringHelper } from '@shared/helper/string.helper';
import { IParamCallAnalysis } from '@admin/models/report-internal/call-analysis';
import { CallAnalysisService } from '@admin/services/report-internal/call-analysis.service';

@Component({
  selector: 'app-call-analysis',
  templateUrl: './call-analysis.component.html',
  styleUrls: ['./call-analysis.component.scss']
})
export class CallAnalysisComponent implements OnInit {
  reportData: any = [];
  lastSyncAt: string;
  colSettings: {
    header: {
      title: string;
      width: string;
      align: 'center' | 'left' | 'right';
      leftCol: boolean;
    };
    record: {
      align: 'center' | 'left' | 'right';
      field: string;
      isNumber?: boolean;
      isHyperlink?: boolean;
      style?: string;
      leftCol: boolean;
    };
  }[] = [];
  currentDate = new Date();
  pageIndex = 1;
  pageSize = 50;
  eventFilter: any;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private dataFormService: DataFormService,
    public formatTimeService: FormatTimeService,
    private callAnalysisService: CallAnalysisService
  ) {}

  ngOnInit() {}

  getColSettings(fields: any[]) {
    const staticCols = [
      {
        header: { title: 'common.table.call_id', width: '240px', leftCol: true },
        record: { field: 'call_id', isHyperlink: true, leftCol: true }
      },
      {
        header: { title: 'common.table.contact_date', width: '150px', align: 'right' },
        record: { align: 'right', field: 'contact_date' }
      },
      {
        header: { title: 'common.table.evaluated_date', width: '150px', align: 'right' },
        record: { align: 'right', field: 'evaluated_date' }
      },
      {
        header: { title: 'common.table.call_analysis.contact_time', width: '150px', align: 'right' },
        record: { align: 'right', field: 'contact_time' }
      },
      {
        header: { title: 'common.table.call_analysis.evaluation_date', width: '150px', align: 'right' },
        record: { align: 'right', field: 'evaluation_date' }
      },
      {
        header: { title: 'common.table.call_analysis.dialed_phone_number', width: '150px', align: 'right' },
        record: { align: 'right', field: 'dialed_phone_number' }
      },
      {
        header: { title: 'common.table.call_analysis.phone_number', width: '150px', align: 'right' },
        record: { align: 'right', field: 'phone_number' }
      },
      {
        header: { title: 'common.table.call_analysis.caller_phone_number', width: '150px', align: 'right' },
        record: { align: 'right', field: 'caller_phone_number' }
      },
      {
        header: { title: 'common.table.product', width: '150px', align: 'right' },
        record: { align: 'right', field: 'product' }
      },
      {
        header: { title: 'common.filter.violation_summary.agency', width: '150px', align: 'right' },
        record: { align: 'right', field: 'agency' }
      },
      {
        header: { title: 'common.table.bucket', width: '150px', align: 'right' },
        record: { align: 'right', field: 'bucket' }
      },
      {
        header: { title: 'common.table.call_analysis.leader', width: '150px', align: 'right' },
        record: { align: 'right', field: 'leader' }
      },
      {
        header: { title: 'common.filter.violation_summary.unit_head', width: '150px', align: 'right' },
        record: { align: 'right', field: 'unit_head' }
      },
      {
        header: { title: 'common.table.agent_id', width: '150px', align: 'left' },
        record: { align: 'left', field: 'agent_id' }
      },
      {
        header: { title: 'common.table.agent_name', width: '150px', align: 'left' },
        record: { align: 'left', field: 'agent_name' }
      },
      {
        header: { title: 'common.table.call_analysis.call_duration', width: '150px', align: 'right' },
        record: { align: 'right', field: 'call_duration' }
      },
      {
        header: { title: 'common.table.call_analysis.total_violated_words', width: '150px', align: 'right' },
        record: { align: 'right', field: 'total_violated_words', isNumber: true }
      },
      {
        header: { title: 'common.table.call_analysis.attitude_keywords', width: '320px', align: 'left' },
        record: { align: 'left', field: 'attitude_keywords' }
      },
      {
        header: { title: 'common.table.call_analysis.keyword_status', width: '150px', align: 'right' },
        record: { align: 'right', field: 'keyword_status' }
      },
      {
        header: { title: 'common.table.call_analysis.total_kpi_score', width: '150px', align: 'right' },
        record: { align: 'right', field: 'total_kpi_score', isNumber: true }
      },
      {
        header: { title: 'common.table.engineName', width: '150px', align: 'right' },
        record: { align: 'right', field: 'engine_name' }
      }
    ];
    const colSettings: any[] = [];
    fields.forEach(f => {
      const col = staticCols.find(c => c.record.field === f);
      colSettings.push(
        col
          ? col
          : {
              header: { title: f, width: '150px', align: 'right' },
              record: { align: 'right', field: f }
            }
      );
    });
    return colSettings;
  }

  getRecordData(record, data): string {
    if (record.isNumber && data[record.field]) {
      return data[record.field].toLocaleString();
    }
    if (record.field === 'attitude_keywords') {
      return StringHelper.convertLineBreakToBr(data[record.field]);
    }
    return data[record.field];
  }

  handleFilterTicket(event: {}) {
    if (event) {
      this.pageIndex = event?.['page'];
      this.eventFilter = {
        page: event?.['page'],
        contactDateFrom: event?.['contactDateFrom'],
        contactDateTo: event?.['contactDateTo'],
        callId: event?.['callId'],
        keywordStatus: event?.['keywordStatus'],
        evaluatedDateFrom: event?.['evaluatedDateFrom'],
        evaluatedDateTo: event?.['evaluatedDateTo'],
        kpiName: event?.['kpiName'],
        kpiStatus: event?.['kpiStatus'],
        kpiVersion: event?.['kpiVersion'],
        keywordVersion: event?.['keywordVersion'],
        filter: event?.['filter'],
        engineIds: event?.['engineIds']
      };
      this.getCallAnalysis(this.eventFilter);
    }
  }

  getCallAnalysis(param: IParamCallAnalysis) {
    this.spinner.show();
    const queryParam = {
      page: param?.page ? param.page : this.pageIndex,
      maxSize: this.pageSize,
      contactDateFrom: param?.contactDateFrom ? param?.contactDateFrom : startOfDay(this.currentDate).toISOString(),
      contactDateTo: param?.contactDateTo ? param?.contactDateTo : endOfDay(this.currentDate).toISOString(),
      callId: param?.callId,
      keywordStatus: param?.keywordStatus,
      evaluatedDateFrom: param?.evaluatedDateFrom,
      evaluatedDateTo: param?.evaluatedDateTo,
      kpiName: param?.kpiName,
      kpiStatus: param?.kpiStatus,
      kpiVersion: param?.kpiVersion,
      keywordVersion: param?.keywordVersion,
      filter: param?.filter,
      engineIds: param?.engineIds
    };
    this.spinner.show();
    const params: any = this.dataFormService.removeEmptyValues(queryParam);
    this.callAnalysisService.getListCallAnalysis(params).subscribe({
      next: (result: any) => {
        this.colSettings = this.getColSettings(result.data.fields);
        this.reportData = result.data.rows;
        this.lastSyncAt = result.data.lastSyncAt;
        this.spinner.hide();
      },
      error: err => {
        this.reportData = [];
        this.spinner.hide();
      }
    });
  }

  navigateTicketDetail(evaluationId) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/admin/evaluation/ticket-info', evaluationId]));
    window.open(url, '_blank');
  }

  previousPage() {
    if (this.pageIndex > 1) {
      this.pageIndex--;
      if (this.eventFilter) {
        this.handleFilterTicket({ ...this.eventFilter, page: this.pageIndex });
      } else {
        this.getCallAnalysis({ page: this.pageIndex });
      }
    }
  }

  nextPage() {
    this.pageIndex++;
    if (this.eventFilter) {
      this.handleFilterTicket({ ...this.eventFilter, page: this.pageIndex });
    } else {
      this.getCallAnalysis({ page: this.pageIndex });
    }
  }
}
