import { KpiResult, TraceReason } from '@admin/models/evaluation/est-kpi';
import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-kpi-result',
  templateUrl: './kpi-result.component.html',
  styleUrls: ['./kpi-result.component.scss']
})
export class KpiResultComponent implements OnInit {
  @Input() kpiId: any;
  @Output() hideReason: EventEmitter<boolean> = new EventEmitter();
  kpiResultInfo: any[] = [];
  kpiResultReasons: any[] = [];
  showReasonTraces = false;
  kpiType = '';
  traceReasons: TraceReason[] = [];

  constructor(
    private evaluationService: EvaluationService,
    private spinner: NgxSpinnerService,
    private notification: NzNotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['kpiId'] && changes['kpiId'].currentValue) {
      this.getKpiResult(changes['kpiId'].currentValue);
    }
  }

  getKpiResult(kpiId) {
    this.spinner.show();
    this.evaluationService
      .getKpiResult(kpiId)
      .subscribe({
        next: (result: any) => {
          const kpiResult = new KpiResult(result['data']);
          this.kpiResultReasons = kpiResult.kpiResultReasons;
          this.kpiResultInfo = [
            { title: 'kpi_result.kpi_name', content: kpiResult?.kpiSetting?.name || '' },
            { title: 'kpi_result.minus_when', content: this.getMinusWhen(kpiResult) },
            { title: 'kpi_result.status', content: 'kpi_result.status_value.' + kpiResult?.status.toLocaleLowerCase() || '' },
            { title: 'kpi_result.reason', content: '' }
          ];
        },
        error: (error: any) => {
          this.notification.create(
            'error',
            this.translateService.instant('kpi_result.error.title'),
            this.translateService.instant('kpi_result.error.message')
          );
        }
      })
      .add(() => {
        this.spinner.hide();
      });
  }

  getMinusWhen(kpiResult: KpiResult) {
    const condition = kpiResult?.condition === 'AND' ? 0 : 1;
    const minusWhen = kpiResult?.kpiSetting?.kpiType === 'BONUS' ? !kpiResult.kpiSetting.when : kpiResult.kpiSetting.when;
    if (condition) {
      if (minusWhen) {
        return 'kpi_result.minus_description.one_of_pass_groups';
      }
      return 'kpi_result.minus_description.all_fail_groups';
    }
    if (minusWhen) {
      return 'kpi_result.minus_description.all_pass_groups';
    }
    return 'kpi_result.minus_description.one_of_fail_groups';
  }

  hyperLink(data) {
    this.evaluationService.sendKeyword({ keyword: '', startTime: data.startTranscript.startTime });
  }

  backToEvaluation() {
    this.hideReason.emit();
  }

  openDetail(kpiType: string, traceReasons: TraceReason[]) {
    this.kpiType = kpiType;
    this.traceReasons = traceReasons;
  }

  hasDetailButton(kpiType: string, traceReasons: TraceReason[]): boolean {
    if (kpiType === 'SILENT') {
      return !!traceReasons.length && !!traceReasons[0].traces.length;
    }
    if (kpiType === 'KEYWORD') {
      return !!traceReasons.length && !! traceReasons[0].traces.length;
    }
    if (kpiType === 'NLP') {
      return !!traceReasons.length && !!traceReasons[0].traces.length;
    }
    return false;
  }

  closeDetail(): void {
    this.showReasonTraces = false;
  }
}
