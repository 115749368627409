import { LANGUAGE } from '@shared/constant/language';
interface Reason {
  name: string;
  status: string;
  reasons: any[];
  errorReasons: any[];
}

interface KpiSettings {
  name: string;
  description: string | null;
  score: number;
  when: any;
  kpiType: string;
  isSerious: boolean;
}

interface Kpi {
  id: string;
  kpiSettings: KpiSettings;
  status: string;
  statusByQc: string;
  isPassNaByQc: any;
  updateStatus: any;
  updateKpiNa: any;
  isNotAvailable: any;
  isQaScore: any;
  condition: string;
  reasons: Reason[];
}

class KpiResultReason {
  kpiReasons: KpiReason[];
  constructor(data: any) {
    this.kpiReasons = data.kpiReasons ? data.kpiReasons.map(k => new KpiReason(k)) : [];
  }
}

export class KpiReason {
  kpiType: string;
  messages: {
    [LANGUAGE.EN]: string;
    [LANGUAGE.VI]: string;
  };
  reasonStatus: string;
  traceReasons: TraceReason[];
  constructor(data: any) {
    this.kpiType = data.kpiType;
    this.messages = data.messages;
    this.reasonStatus = data.reasonStatus;
    this.traceReasons = data.traceReasons ? data.traceReasons.map(r => new TraceReason(r)) : [];
  }
}

export class TraceReason {
  traceType: string;
  traces: any[];
  constructor(data: any) {
    this.traceType = data.traceType;
    this.traces = data.traces;
  }
}

class Kpi {
  id: string;
  kpiSettings: KpiSettings;
  status: string;
  statusByQc: string;
  isPassNaByQc: any;
  updateStatus: any;
  updateKpiNa: any;
  isNotAvailable: any;
  isQaScore: any;
  condition: string;
  reasons: Reason[];

  constructor(data: any) {
    this.id = data.id;
    this.kpiSettings = data.kpiSettings;
    this.status = data.status;
    this.statusByQc = data.statusByQc;
    this.isPassNaByQc = data.isPassNaByQc;
    this.updateStatus = data.updateStatus;
    this.updateKpiNa = data.updateKpiNa;
    this.isNotAvailable = data.isNotAvailable;
    this.isQaScore = data.isQaScore;
    this.condition = data.condition;
    this.reasons = data.reasons ? data.reasons.map(reason => new Reasons(reason)) : [];
  }
}

class Reasons {
  name: string;
  status: string;
  reasons: Reason[];
  errorReasons: any[];

  constructor(data: any) {
    this.name = data.name;
    this.status = data.status;
    this.reasons = data.reasons ? data.reasons.map(reason => new Reasons(reason)) : [];
    this.errorReasons = data.errorReasons || [];
  }
}

export class EstimationKpiModel {
  name: string;
  score: number;
  kpis: Kpi[];

  constructor(data: any) {
    this.name = data.name;
    this.score = data.score;
    this.kpis = data.kpis ? data.kpis.map(kpi => new Kpi(kpi)) : [];
  }
}

export class KpiResult {
  status: string;
  condition: string;
  kpiSetting: KpiSettings;
  kpiResultReasons: KpiResultReason[];

  constructor(data: any) {
    this.status = data.status;
    this.condition = data.condition;
    this.kpiSetting = data.kpiSetting;
    this.kpiResultReasons = data.kpiResultReasons ? data.kpiResultReasons.map(k => new KpiResultReason(k)) : [];
  }
}
