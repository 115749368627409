import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalAssignmentService } from '@shared/service/dialog/modal-assignment.service';

@Component({
  selector: 'app-modal-accept-assignment',
  templateUrl: './modal-accept-assignment.component.html',
  styleUrls: ['./modal-accept-assignment.component.scss']
})
export class ModalAcceptAssignmentComponent implements OnInit {
  isVisible = true;
  isConfirmLoading = false;
  radioValue = 'A';
  form: FormGroup;
  searchName = '';
  filteredData: any = [];
  listOfData: any = [];

  constructor(private formBuilder: FormBuilder, private modalAssignmentService: ModalAssignmentService) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      fileName: ['', Validators.required],
      radioValue: ['A']
    });

    this.modalAssignmentService.getListQC().subscribe({
      next: result => {
        this.listOfData = result?.['data'];
        this.filteredData = this.listOfData;
      },
      error: error => {}
    });
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isConfirmLoading = true;
    setTimeout(() => {
      this.isVisible = false;
      this.isConfirmLoading = false;
    }, 1000);
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  filterTable() {
    if (!this.searchName) {
      this.filteredData = this.listOfData;
      return;
    }

    this.filteredData = this.listOfData.filter((person: any) => person.name.toLowerCase().includes(this.searchName.toLowerCase()));
  }
}
