import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGE } from '@shared/constant/language';
import { StorageService } from '@shared/service/storage.service';

@Component({
  selector: 'app-switch-language',
  templateUrl: './switch-language.component.html',
  styleUrls: ['./switch-language.component.scss']
})
export class SwitchLanguageComponent implements OnInit {
  switchValue = false;

  constructor(private translateService: TranslateService, private storageService: StorageService) {}

  ngOnInit(): void {
    this.switchValue = this.storageService.getLang() !== LANGUAGE.VI;
  }

  changeLang(lang: boolean): void {
    // const currentLanguage = this.translateService.currentLang;
    const selectedLang = !lang ? LANGUAGE.VI : LANGUAGE.EN;
    this.translateService.use(selectedLang);
    this.storageService.setLang(selectedLang);
    this.storageService.setCurrentLanguageSubject(selectedLang);
    this.storageService.setLangV1(selectedLang);
    this.switchValue = !this.switchValue;
  }
}
