import { KpiReason, TraceReason } from '@admin/models/evaluation/est-kpi';
import { Component, OnInit, Input } from '@angular/core';
import { StorageService } from '@shared/service/storage.service';

@Component({
  selector: 'app-kpi-reason',
  templateUrl: './kpi-reason.component.html',
  styleUrls: ['./kpi-reason.component.scss']
})
export class KpiReasonComponent implements OnInit {
  @Input() kpiReason: KpiReason;

  kpiType = '';

  traceReasons: TraceReason[] = [];

  showReasonTraces = false;

  constructor(public storageService: StorageService) {}

  ngOnInit(): void {}

  openDetail(kpiType: string, traceReasons: TraceReason[]) {
    this.kpiType = kpiType;
    this.traceReasons = traceReasons;
  }

  hasDetailButton(kpiType: string, traceReasons: TraceReason[]): boolean {
    if (kpiType === 'SILENT') {
      return !!traceReasons.length && !!traceReasons[0].traces.length;
    }
    if (kpiType === 'KEYWORD') {
      return !!traceReasons.length && !!traceReasons[0].traces.length && traceReasons[0].traces.find(t => t.numOfShowUps);
    }
    if (kpiType === 'NLP') {
      return !!traceReasons.length && !!traceReasons[0].traces.length && traceReasons[0].traces.find(t => t.numOfShowUps);
    }
    if (kpiType === 'REFER_INFORMATION') {
      return !!traceReasons.length && !!traceReasons[0].traces.length;
    }
    return false;
  }

  closeDetail(): void {
    this.showReasonTraces = false;
  }
}
