export enum SUCCESS_CODE {
  SUCCESS = 'EVAL-00000000'
}

export enum ERROR_RESPONE_CODE {
  USER_OR_PASSWORD_INCORRECT = 100,
  VERIFY_CODE_IS_INVALID = 111,
  OTP_STILL_ALIVE_CANT_SEND_MAIL = 115,
  EMAIL_NOT_EXIST = 209
}
