import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { AudioService } from '@admin/services/audio-manager/audio-manager.service';
import { AudioErrorModel } from '@admin/models/audio-manager/audio-error';
import { OrganizationsGlobalService } from '@shared/service/organizations-global.service';
import { Subscription } from 'rxjs';
import { PermissionsService } from '@shared/service/permission.service';
import { RIGHTS_CODE } from '@shared/constant/rights-code';

@Component({
  selector: 'app-audio-error',
  templateUrl: './audio-error.component.html',
  styleUrls: ['./audio-error.component.scss']
})
export class AudioErrorComponent implements OnInit {
  RIGHTS_CODE = RIGHTS_CODE;
  listAudioError: AudioErrorModel[] = [];

  listHeader = [
    { title: 'common.table.index', width: '80px' },
    { title: 'common.table.file_name', width: '180px' },
    { title: 'common.table.call_id', width: '180px' },
    { title: 'common.table.audio_error.cause', width: '180px' },
    { title: 'common.table.agent_id', width: '120px' },
    { title: 'common.table.contact_date', width: '120px' },
    { title: 'common.table.upload_date', width: '120px' },
    { title: 'common.table.updated_date', width: '120px' },
    { title: 'common.table.audio_error.kpi_version', width: '120px' },
    { title: 'common.table.engineName', width: '120px' }
  ];
  isFilter = false;
  pageIndex = 1;
  pageSize = 10;
  isShowDialog = false;
  total: number;
  valueFilter: any;

  setOfCheckedId = new Set<string>();
  checked = false;
  indeterminate = false;
  listOfCurrentPageData: any = [];
  disableButton = true;
  dataOrganizations: string[];
  private subscription: Subscription;

  constructor(
    private audioService: AudioService,
    private spinner: NgxSpinnerService,
    private organizationsGlobalService: OrganizationsGlobalService,
    public permissionsService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.subscription = this.organizationsGlobalService.valueOrganizations$.subscribe({
      next: result => {
        if (result) {
          this.dataOrganizations = result.org;
          if (this.valueFilter) {
            this.handleFilterTicket(this.valueFilter);
          } else {
            this.getListAudioError(1);
          }
        }
      }
    });
  }

  getListAudioError(pageIndex: number, reset = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    const params = {
      page: pageIndex,
      maxSize: this.pageSize,
      engineIds: this.dataOrganizations
    };
    this.spinner.show();
    this.audioService.getAudioError(params).subscribe({
      next: result => {
        this.total = result?.['data'].total;
        this.listAudioError = result?.['data'].data.map((item: any) => {
          return new AudioErrorModel(item);
        });
        this.spinner.hide();
      },
      error: (err: any) => {
        if (err) {
          this.listAudioError = [];
          this.spinner.hide();
        }
      }
    });
  }

  isDisable(count) {
    return +count >= 3;
  }

  onCurrentPageDataChange(listOfCurrentPageData: any): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.listOfCurrentPageData.filter(({ disabled }) => !disabled);
    this.checked = listOfEnabledData.length ? listOfEnabledData.every(({ id }) => this.setOfCheckedId.has(id)) : false;
    this.indeterminate = listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
    this.disableButton = listOfEnabledData.length === 0;
  }

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onAllChecked(checked: boolean): void {
    this.listOfCurrentPageData
      .filter(({ disabled }) => !disabled)
      .forEach(({ reevaluatingCount, id }) => {
        if (reevaluatingCount === 3 && id) {
          this.updateCheckedSet(id, false);
        } else {
          this.updateCheckedSet(id, checked);
        }
      });
    this.refreshCheckedStatus();
  }

  checkSingle(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  showDialog() {
    this.isShowDialog = !this.isShowDialog;
  }

  handleEvaluatedAudio(event: boolean) {
    if (event) {
      const audioStatusIds: any = [];
      this.listAudioError.filter(data => {
        if (this.setOfCheckedId.has(data?.['id'])) {
          audioStatusIds.push(data?.['id']);
        }
      });
      this.spinner.show();
      this.audioService.reEvaluateAudio(audioStatusIds).subscribe({
        next: (result: any) => {
          if (result) {
            this.isShowDialog = false;
            this.setOfCheckedId.clear();
            this.refreshCheckedStatus();
            this.getListAudioError(1);
            this.spinner.hide();
          }
        },
        error: (err: any) => {
          if (err) {
            this.isShowDialog = false;
            this.spinner.hide();
          }
        }
      });
    }
  }

  handleFilterTicket(event: any) {
    this.pageIndex = 1;
    this.valueFilter = event;
    this.handleSearchAudioError(1);
  }

  handleShowFilter() {
    this.isFilter = !this.isFilter;
  }

  handleSearchAudioError(pageIndex: any) {
    this.valueFilter.page = pageIndex;
    this.valueFilter.engineIds = this.dataOrganizations;
    this.spinner.show();
    this.audioService.getAudioError(this.valueFilter).subscribe({
      next: result => {
        this.total = result?.['data'].total;
        this.listAudioError = result?.['data'].data.map((item: any) => {
          return new AudioErrorModel(item);
        });
        this.spinner.hide();
      },
      error: (err: any) => {
        if (err) {
          this.listAudioError = [];
          this.spinner.hide();
        }
      }
    });
  }

  previousPage() {
    if (this.pageIndex > 1) {
      this.pageIndex--;
      if (this.valueFilter) {
        this.handleSearchAudioError(this.pageIndex);
      } else {
        this.getListAudioError(this.pageIndex);
      }
    }
  }

  nextPage() {
    this.pageIndex++;
    if (this.valueFilter) {
      this.handleSearchAudioError(this.pageIndex);
    } else {
      this.getListAudioError(this.pageIndex);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
