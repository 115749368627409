import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable()
export class ValidatorFormService {
  rangeValidator(fromControlName: string, toControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const fromControl = control.get(fromControlName);
      const toControl = control.get(toControlName);

      if (fromControl?.value !== '' && toControl?.value !== '') {
        const fromValue = parseFloat(fromControl?.value);
        const toValue = parseFloat(toControl?.value);

        if (fromValue > toValue) {
          return { range: true };
        }
      }

      return null;
    };
  }

  trimInputs(data: any): void {
    Object.keys(data).forEach(key => {
      const control = data[key];

      if (typeof control.value === 'string') {
        control.setValue(control.value.trim());
      }
    });
  }

  timeRangeValidator(startControlName: string, endControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const startControl = control.get(startControlName);
      const endControl = control.get(endControlName);
      if (!startControl || !endControl) {
        return null;
      }
      const startTime = startControl.value;
      const endTime = endControl.value;
      if (startTime && endTime && startTime >= endTime) {
        return { range: true };
      }
      return null;
    };
  }

  commaSeparatedStringsValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value == null) {
      return null;
    }
    // Check if the value is a string and contains at most 10 comma-separated strings
    if (typeof value === 'string') {
      const strings = value.split(',').map(str => str.trim());
      if (strings.length > 11) {
        return { checkValidatorArray: { value } };
      }
      return null;
    }
    // Check if the value is an array and contains at most 11 strings
    if (Array.isArray(value)) {
      if (value.length > 11) {
        return { checkValidatorArray: { value } };
      }
      return null;
    }
    // Return an error if the value is not a string or an array
    return { checkValidatorArray: { value } };
  }
}
