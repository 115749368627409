import { Component, OnInit } from '@angular/core';
import { RIGHTS_CODE } from '@shared/constant/rights-code';
import { PermissionsService } from '@shared/service/permission.service';

@Component({
  selector: 'app-audio-management',
  templateUrl: './audio-management.component.html',
  styleUrls: ['./audio-management.component.scss']
})
export class AudioManagementComponent implements OnInit {
  RIGHTS_CODE = RIGHTS_CODE;
  constructor(public permissionsService: PermissionsService) {}

  ngOnInit(): void {}
}
