import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-agree',
  templateUrl: './agree.component.html',
  styleUrls: ['./agree.component.scss']
})
export class AgreeComponent implements OnInit {
  @Input() isVisible = false;
  @Input() title = '';
  @Input() description = '';
  @Output() isVisibleChange = new EventEmitter<any>();
  @Output() agreeEnter = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

   handleHideModal() {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }
}
