import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'report', pathMatch: 'full' },
  {
    path: 'audio-management',
    loadChildren: () => import('./components/audio/audio.module').then(m => m.AudioModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'evaluation',
    loadChildren: () => import('./components/evaluation/evaluation.module').then(m => m.EvaluationModule)
  },
  {
    path: 'action-history',
    loadChildren: () => import('./components/action-history/action-history.module').then(m => m.ActionHistoryModule)
  },
  {
    path: 'right-role',
    loadChildren: () => import('./components/right-role/right-role.module').then(m => m.RightRoleModule)
  },
  {
    path: 'org-management',
    loadChildren: () => import('./components/org/org.module').then(m => m.OrgModule)
  },
  {
    path: 'user-management',
    loadChildren: () => import('./components/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'org-owns',
    loadChildren: () => import('./components/org-selection/org-owns.module').then(m => m.OrgOwnsModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./components/report/report.module').then(m => m.ReportModule)
  },
  {
    path: 'report-internal',
    loadChildren: () => import('./components/report-internal/report-internal.module').then(m => m.ReportInternalModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {}
