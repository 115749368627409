import { AssignedCallByQcReportModal } from '@admin/models/report-internal/assigned-call-by-qc-report';
import { AssignedCallByQcReportService } from '@admin/services/report-internal/assigned-call-by-qc-report.service';
import { Component, OnInit } from '@angular/core';
import { FormatTimeService } from '@shared/service/formatTime.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-assigned-call-by-qc-report',
  templateUrl: './assigned-call-by-qc-report.component.html',
  styleUrls: ['./assigned-call-by-qc-report.component.scss']
})
export class AssignedCallByQcReportComponent implements OnInit {
  reportData: any = [];
  lastSyncAt: string;
  headerList: { title: string; width: string }[] = [
    { title: 'common.table.assigned_call_by_qc.qc_id', width: '150px' },
    { title: 'common.table.assigned_call_by_qc.qc_username', width: '150px' },
    { title: 'common.table.assigned_call_by_qc.total_assigned_calls', width: '150px' },
    { title: 'common.table.assigned_call_by_qc.total_completed_calls', width: '150px' },
    { title: 'common.table.assigned_call_by_qc.total_completed_calls_ratio', width: '150px' }
  ];
  currentDate = new Date();
  pageIndex = 1;
  pageSize = 10;
  eventFilter: any;

  constructor(
    private spinner: NgxSpinnerService,
    public formatTimeService: FormatTimeService,
    private assignedCallByQcReportService: AssignedCallByQcReportService
  ) {}

  ngOnInit() {}

  handleFilterTicket(event: {}) {
    this.eventFilter = event;
    this.pageIndex = event['page'] || 1;
    this.getReportData(event);
  }

  getReportData(param: any) {
    this.spinner.show();
    this.assignedCallByQcReportService.getAssignedCallByQcReport({ ...param, maxSize: this.pageSize }).subscribe({
      next: (result: any) => {
        this.reportData = result.data.rows.map((item: any) => new AssignedCallByQcReportModal(item));
        this.lastSyncAt = result.data.lastSyncAt;
        this.spinner.hide();
      },
      error: err => {
        this.reportData = [];
        this.spinner.hide();
      }
    });
  }

  previousPage() {
    if (this.pageIndex > 1) {
      this.pageIndex--;
      if (this.eventFilter) {
        this.handleFilterTicket({ ...this.eventFilter, page: this.pageIndex });
      } else {
        this.getReportData({ page: this.pageIndex });
      }
    }
  }

  nextPage() {
    this.pageIndex++;
    if (this.eventFilter) {
      this.handleFilterTicket({ ...this.eventFilter, page: this.pageIndex });
    } else {
      this.getReportData({ page: this.pageIndex });
    }
  }
}
