import { finalize } from 'rxjs';
import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { POPUP_TYPE } from '@shared/constant/popup.constant';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-assignment-unassigned',
  templateUrl: './assignment-unassigned.component.html',
  styleUrls: ['./assignment-unassigned.component.scss']
})
export class AssignmentUnassignedComponent implements OnInit {
  @Input() isVisible = false;
  @Input() popupType: POPUP_TYPE = POPUP_TYPE.CONFIRM;
  @Input() enableAssignParams = {};
  @Output() isVisibleChange = new EventEmitter<any>();
  @Output() submitData = new EventEmitter<any>();
  POPUP_TYPE = POPUP_TYPE;
  formGroup: FormGroup;
  numOfAssigned = 0;
  isSubmit = false;
  constructor(private formBuilder: FormBuilder, private evaluationService: EvaluationService, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.initFormGroup();
  }

  initFormGroup() {
    this.formGroup = this.formBuilder.group({
      isTotal: [true],
      ticketNumber: [0, Validators.required]
    });
  }

  resetData() {
    this.formGroup.patchValue({
      isTotal: true,
      ticketNumber: 0
    });
    this.isSubmit = false;
    this.numOfAssigned = 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isVisible'] && !changes['isVisible'].isFirstChange() && this.isVisible) {
      this.resetData();
      this.getAssignedNumber();
    }
  }

  handleSubmit() {
    this.isSubmit = true;
    if (this.formGroup.invalid) {
      return;
    }
    let data = this.formGroup.value;
    data = {
      ...this.enableAssignParams,
      isTotal: data.isTotal,
      numOfEvaluationUnassign: Number(data.ticketNumber)
    };
    this.submitData.emit(data);
  }

  getAssignedNumber() {
    this.spinner.show();
    this.evaluationService
      .getNumOfAssigned(this.enableAssignParams)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(res => {
        if (!res || !res.data || !res.data.numOfAssigned) {
          return;
        }
        this.numOfAssigned = res.data.numOfAssigned;
        if (this.numOfAssigned >= 1000) {
          this.formGroup.get('isTotal')?.patchValue(false);
        }
      });
  }

  handleHideModal() {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }
}
