import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOWNLOAD } from '@shared/constant/download';
import { saveAs } from 'file-saver';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subject, timer, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DownloadService {
  private downloadFileSubject = new Subject<any>();
  downloadFileObservable = this.downloadFileSubject.asObservable();

  isDownloadHandling = false;

  constructor(
    private httpClient: HttpClient,
    private notificationService: NzNotificationService,
    private translateService: TranslateService
  ) {}

  downloadFileNext(value) {
    this.downloadFileSubject.next(value);
  }

  handleDownloadData(data) {
    let fileUrl = data?.fileUrl || '';
    if (!fileUrl) {
      return;
    }
    const fileName = fileUrl.slice(fileUrl.lastIndexOf('/') + 1);
    this.downloadFile(fileUrl, fileName);
  }

  downloadFile(url: string, fileName?: string) {
    const headers = new HttpHeaders().set('Content-Type', 'audio/mpeg');
    timer(DOWNLOAD.DELAY_TIME)
      .pipe(take(1))
      .subscribe(() => {
        this.httpClient.get(url, { headers, responseType: 'blob' }).subscribe({
          next: response => {
            const filename = `${fileName ? fileName : 'download_file'}`;
            saveAs(response, filename);
          },
          error: (err: any) => {
            this.notificationService.create(
              'error',
              this.translateService.instant('report.export.title'),
              this.translateService.instant('report.export.error')
            );
          }
        });
      });
  }
}
