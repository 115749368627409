import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsGlobalService {
  private valueOrganizations = new BehaviorSubject<{ org: string[]; IsOrg: boolean }>({ org: [], IsOrg: false });
  valueOrganizations$ = this.valueOrganizations.asObservable();

  constructor() {}

  sendOrganizations(data: { org: string[]; IsOrg: boolean }) {
    this.valueOrganizations.next(data);
  }
}
