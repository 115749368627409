export class AudioErrorModel {
  id: string;
  fileName: string;
  callId: string;
  cause: string;
  agentId: string;
  uploadData: string;
  contactDate: string;
  updateDate: string;
  kpiVersion: string;
  reevaluatingCount: number;
  engineName: string;

  constructor(data: any = {}) {
    this.id = data._id;
    this.fileName = data?.audio.fileName || '';
    this.callId = data?.audio.callId || '';
    this.cause = data?.errorType || '';
    this.agentId = data?.audio?.agentId || '';
    this.uploadData = data?.audio.uploadAt || '';
    this.contactDate = data?.audio.contactDate || '';
    this.updateDate = data?.updatedAt || '';
    this.kpiVersion = data?.kpiVersion || '';
    this.reevaluatingCount = data?.reevaluatingCount;
    this.engineName = data?.engineName || '';
  }
}
