export class AssignedCallReportModal {
  date: string;
  totalQcs: number;
  totalSuccessCalls: number;
  totalAssignedCalls: number;
  totalCompletedCalls: number;
  totalAssignedCallsRatio: number;
  totalCompletedCallsRatio: number;

  constructor(data: any) {
    this.date = (data && data.date) || '';
    this.totalQcs = (data && data.totalQcs) || 0;
    this.totalSuccessCalls = (data && data.totalSuccessCalls) || 0;
    this.totalAssignedCalls = (data && data.totalAssignedCalls) || 0;
    this.totalCompletedCalls = (data && data.totalCompletedCalls) || 0;
    this.totalAssignedCallsRatio = (data && data.totalAssignedCallsRatio) || '';
    this.totalCompletedCallsRatio = (data && data.totalCompletedCallsRatio) || '';
  }
}
