import { FLAG_EVALUATION, FLAG_EVALUATION_ARRAY, TICKET_STATUS } from './../../../../constant/evaluation/evaluation.constant';
import { EvaluationService } from '@admin/services/evaluation/evaluation.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-update-ticket-status',
  templateUrl: './update-ticket-status.component.html',
  styleUrls: ['./update-ticket-status.component.scss']
})
export class UpdateTicketStatusComponent implements OnInit {
  @Input() isVisible = false;
  @Output() isVisibleChange = new EventEmitter<any>();
  @Input() ticketId = '';
  @Input() currentStatus = '';
  @Output() changeStatusQC: EventEmitter<any> = new EventEmitter();
  FLAG_EVALUATION = FLAG_EVALUATION;
  FLAG_EVALUATION_ARRAY = FLAG_EVALUATION_ARRAY;
  formGroup: FormGroup;
  isSubmit = false;
  nextStatus = '';
  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private evaluationService: EvaluationService) {}

  ngOnInit(): void {
    this.initFormGroup();
    this.getNextStatus();
  }

  initFormGroup() {
    this.formGroup = this.formBuilder.group({
      status: [undefined],
      flagType: [FLAG_EVALUATION.NULL],
      flagNote: ['', Validators.required]
    });
  }

  resetData() {
    this.formGroup.patchValue({
      flagType: 'NULL',
      flagNote: ''
    });
    this.isSubmit = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isVisible'] && !changes['isVisible'].isFirstChange() && this.isVisible) {
      this.resetData();
    }
  }

  getNextStatus() {
    if (!this.ticketId || !this.currentStatus || this.currentStatus === TICKET_STATUS.DONE) {
      return;
    }
    this.spinner.show();
    this.evaluationService
      .getNextStatus(this.ticketId)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe((res: any) => {
        if (!res || !res.data?.length || !res.data[0].status) {
          return;
        }
        this.nextStatus = res.data[0].status;
        this.formGroup.patchValue({
          status: this.nextStatus
        });
      });
  }

  hideDialog() {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }

  handleSubmit() {
    this.isSubmit = true;
    if (!this.validateInput()) {
      return;
    }
    let data = this.formGroup.value;
    this.changeStatus(data);
  }

  validateInput(): boolean {
    if (this.formGroup.get('flagType')?.value !== FLAG_EVALUATION.NULL && this.formGroup.get('flagNote')?.invalid) {
      return false;
    }
    return true;
  }

  changeStatus(data: {}) {
    this.spinner.show();
    this.evaluationService
      .updateTicketStatus(data, this.ticketId)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe((res: any) => {
        this.hideDialog();
        this.changeStatusQC.emit(true);
      });
  }
}
