const domain = {
  root: 'https://apis-dev.vqc-new.vnlp.ai',
  botServer: 'https://bot-server-dev.gonjoy.com',
  botGnj: 'https://bot-gnj-dev.gonjoy.com',
  media: 'https://media-fe.vnlp.ai',
  staticRoot: 'http://gonjoy.io/static/roulette',
  spa_vqc: 'https://dev.vqc-new.vnlp.ai'
};
const version = {
  v0: 'v0',
  v1: 'v1',
  v2: 'v2'
};

export const environment = {
  production: false,
  vqc_v2: {
    root: `${domain.root}/`,
    auth: `${domain.root}/auth-service`,
    nlp: `${domain.root}/nlp-service`,
    report: `${domain.root}/report-service`,
    reportV1: `${domain.root}/report-service/api/${version.v1}`,
    toolReportV1: `${domain.root}/tool-service/api/${version.v1}`,
    kpi: `${domain.root}/kpi-service/${version.v0}`,
    kpiV1: `${domain.root}/kpi-service/${version.v1}`,
    evaluationV1: `${domain.root}/evaluation-service/api/${version.v1}`,
    evaluationV2: `${domain.root}/evaluation-service/api/${version.v2}`,
    botNlp: `${domain.root}/nlp-old-service`,
    vqc: `${domain.root}/vqc-service`
  },
  botServer: `${domain.botServer}`,
  botGnj: `${domain.botGnj}`,
  media: `${domain.media}`,
  staticRoot: `${domain.staticRoot}`,
  spa_vqc: `${domain.spa_vqc}`
};
