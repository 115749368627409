import { Injectable } from '@angular/core';
import { BaseService } from '@shared/service/base.service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '@shared/service/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends BaseService {
  loggedIn = new BehaviorSubject(false);

  constructor(http: HttpClient, public storage: StorageService) {
    super(http);
    this.loggedIn.next(!!this.storage.getUserInfo());
  }

  // USER
  getIsLoggedIn() {
    return this.loggedIn.getValue();
  }

  setUserInfo(data: {}) {
    this.storage.setUserInfo(data);
  }

  setToken(value: string) {
    return this.storage.setToken(value);
  }

  isLogin(value: boolean) {
    this.loggedIn.next(value);
  }

  setStorageV1(data: any) {
    if (!data) {
      return;
    }
    if (data.engine) {
      this.storage.setEngineV1(JSON.stringify(data.engine));
    }
    if (data.user) {
      this.storage.setUserV1(JSON.stringify(data.user));
    }
    if (data.org) {
      this.storage.setOrgV1(JSON.stringify(data.org));
    }
    if (data.role) {
      this.storage.setRoleV1(JSON.stringify(data.role));
    }
    if (data.avatar) {
      this.storage.setAvatarV1(JSON.stringify(data.avatar));
    }
    if (data.authMode) {
      this.storage.setAuthModeV1(JSON.stringify(data.authMode));
    }
  }

  removeStorageV1() {
    this.storage.removeEngineV1();
    this.storage.removeUserV1();
    this.storage.removeOrgV1();
    this.storage.removeRoleV1();
    this.storage.removeAvatarV1();
    this.storage.removeAuthModeV1();
  }
}
