import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorCodeConstants, StatusCodeConstants } from '@shared/constant/status-code';
import { ErrorGlobalService } from '@shared/service/errorGlobal.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private errorGlobalService: ErrorGlobalService) {}

  //
  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   return next.handle(request).pipe(
  //     catchError((error: HttpErrorResponse) => {
  //       let errorMessages: string[] = [];
  //       const statusCode = error.error.statusCode;
  //       if (error.error && Object.prototype.hasOwnProperty.call(error.error, 'statusCode') && Array.isArray(error.error.messageFields)) {
  //         const messageFields = error.error.messageFields;
  //
  //         if (Object.prototype.hasOwnProperty.call(StatusCodeConstants, statusCode) && StatusCodeConstants[statusCode]) {
  //           // const statusMessage = StatusCodeConstants[statusCode];
  //           for (const messageField of messageFields) {
  //             if (
  //               messageField &&
  //               Object.prototype.hasOwnProperty.call(messageField, 'errorCode') &&
  //               Object.prototype.hasOwnProperty.call(messageField, 'message')
  //             ) {
  //               const errorCode = messageField.errorCode;
  //               const errorMessage = messageField.message;
  //               if (Object.prototype.hasOwnProperty.call(ErrorCodeConstants, errorCode)) {
  //                 const errorMessageKey = ErrorCodeConstants[errorCode];
  //                 const finalErrorMessage = errorMessageKey ? errorMessageKey : errorMessage;
  //                 errorMessages.push(finalErrorMessage);
  //               } else {
  //                 // errorMessages = ['error_handling.error_default'];
  //                 errorMessages.push(errorMessage);
  //               }
  //             }
  //           }
  //         }
  //         if (errorMessages.length > 0) {
  //           this.errorGlobalService.showError(errorMessages[0]);
  //         }
  //       } else if (error.error && Object.prototype.hasOwnProperty.call(error.error, 'statusCode') && StatusCodeConstants[statusCode]) {
  //         console.log(error.error);
  //         const errorCode = error.error.errorCode;
  //         const errorMessage = error.error.message;
  //         if (Object.prototype.hasOwnProperty.call(ErrorCodeConstants, errorCode)) {
  //           const errorMessageKey = ErrorCodeConstants[errorCode];
  //           const finalErrorMessage = errorMessageKey ? errorMessageKey : errorMessage;
  //           errorMessages.push(finalErrorMessage);
  //         } else {
  //           errorMessages.push(errorMessage);
  //         }
  //         if (errorMessages.length > 0) {
  //           this.errorGlobalService.showError(errorMessages[0]);
  //         }
  //       }
  //       return throwError(errorMessages);
  //     })
  //   );
  // }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessages: string[] = [];
        const statusCode = error.error?.statusCode;
        const errorMessageFields = error.error?.messageFields;

        if (errorMessageFields && Array.isArray(errorMessageFields)) {
          if (statusCode && StatusCodeConstants[statusCode]) {
            for (const messageField of errorMessageFields) {
              const { errorCode, message } = messageField;
              const errorMessageKey = ErrorCodeConstants[errorCode];
              const finalErrorMessage = errorMessageKey || message;
              errorMessages.push(finalErrorMessage);
            }
          } else {
            for (const messageField of errorMessageFields) {
              const { message } = messageField;
              errorMessages.push(message);
            }
          }
        } else if (statusCode && StatusCodeConstants[statusCode]) {
          const errorCode = error.error?.errorCode;
          const errorMessage = error.error?.message;
          const errorMessageKey = ErrorCodeConstants[errorCode];
          const finalErrorMessage = errorMessageKey || errorMessage;
          errorMessages.push(finalErrorMessage);
        }

        if (errorMessages.length > 0) {
          this.errorGlobalService.showError(errorMessages[0]);
        }

        return throwError(errorMessages);
      })
    );
  }
}
