import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { startOfToday, subDays } from 'date-fns';
import { FormatTimeService } from '@shared/service/formatTime.service';

import { AttitudeKwBucketService } from '@admin/services/report-internal/attitude-kw-bucket.service';
import { ListReportViolations } from '@admin/models/report-internal/violation-summary';
import { ATTITUDE_KW } from '@admin/constant/report/attitude-kw-bucket';
import { IAttitudeKwBucket } from '@admin/models/report-internal/attitude-kw-bucket';
import { DataFormService } from '@admin/services/report-internal/dataForm.service';

@Component({
  selector: 'app-attitude-kw-bucket',
  templateUrl: './attitude-kw-bucket.component.html',
  styleUrls: ['./attitude-kw-bucket.component.scss']
})
export class AttitudeKwBucketComponent implements OnInit {
  ATTITUDE_KW = ATTITUDE_KW;
  lastSyncAt: string;
  headerList = [];
  changeHeaders: any = ATTITUDE_KW.TEAM_LEADER;
  reportData: ListReportViolations[] = [];
  reportTotalData: {};
  defaultEndDate = startOfToday();
  defaultEndDateTimeStamp = startOfToday();
  defaultStartDate = subDays(this.defaultEndDateTimeStamp, 6);
  pageSize = 50;
  pageIndex = 1;
  eventFilter: any;
  protected readonly Array = Array;

  constructor(
    private spinner: NgxSpinnerService,
    public formatTimeService: FormatTimeService,
    private dataFormService: DataFormService,
    private attitudeKwBucketService: AttitudeKwBucketService
  ) {}

  ngOnInit(): void {
    // this.getAttitudeKwBucket({ showBy: ATTITUDE_KW.TEAM_LEADER, page: this.pageIndex });
  }

  handleSearchEngine(event: IAttitudeKwBucket) {
    if (event) {
      this.pageIndex = event?.['page'];
      this.eventFilter = {
        page: event?.['page'],
        showBy: event?.['showBy'] ? event?.['showBy'] : ATTITUDE_KW.TEAM_LEADER,
        contactDateFrom: event?.['contactDateFrom'],
        contactDateTo: event?.['contactDateTo'],
        keywordVersion: event?.['keywordVersion'],
        filter: event?.['filter'],
        engineIds: event?.['engineIds']
      };
      this.getAttitudeKwBucket(this.eventFilter);
    }
  }

  getAttitudeKwBucket(param: IAttitudeKwBucket) {
    this.changeHeaders = param?.['showBy'];
    this.spinner.show();
    const queryParam: IAttitudeKwBucket = {
      page: param.page ? param.page : this.pageIndex,
      maxSize: this.pageSize,
      showBy: param.showBy ? param.showBy : ATTITUDE_KW.TEAM_LEADER,
      contactDateFrom: param.contactDateFrom ? param.contactDateFrom : this.defaultStartDate.toISOString(),
      contactDateTo: param.contactDateTo ? param.contactDateTo : this.defaultEndDate.toISOString(),
      keywordVersion: param.keywordVersion,
      filter: param.filter,
      engineIds: param.engineIds
    };
    const params: any = this.dataFormService.removeEmptyValues(queryParam);
    this.attitudeKwBucketService.getReportAttitudeKwBucket(params).subscribe({
      next: (res: any) => {
        this.headerList = res.data.fields;
        this.reportData = res.data.rows;
        this.lastSyncAt = res.data.lastSyncAt;
        this.spinner.hide();
      },
      error: (err: any) => {
        if (err) {
          this.reportData = [];
          this.reportTotalData = {};
          this.spinner.hide();
        }
      }
    });
  }

  previousPage() {
    if (this.pageIndex > 1) {
      this.pageIndex--;
      if (this.eventFilter) {
        this.handleSearchEngine({ ...this.eventFilter, page: this.pageIndex });
      } else {
        this.getAttitudeKwBucket({ page: this.pageIndex });
      }
    }
  }

  nextPage() {
    this.pageIndex++;
    if (this.eventFilter) {
      this.handleSearchEngine({ ...this.eventFilter, page: this.pageIndex });
    } else {
      this.getAttitudeKwBucket({ page: this.pageIndex });
    }
  }
}
