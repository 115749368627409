import { BaseService } from '@shared/service/base.service';
import { Injectable } from '@angular/core';
import { ENDPOINT } from '@shared/config/endpoint';
import { IParamCallAnalysis, IParamKpiName } from '@admin/models/report-internal/call-analysis';

const CALL_ANALYSIS_TIMEOUT = 150 * 1000;

@Injectable()
export class CallAnalysisService {
  constructor(private baseService: BaseService) {}

  getKpiVersion() {
    return this.baseService.getData(`${ENDPOINT.kpi.listVersion}`);
  }

  getListCallAnalysis(params: IParamCallAnalysis) {
    return this.baseService.postData(`${ENDPOINT.report.callAnalysis}`, params, undefined, CALL_ANALYSIS_TIMEOUT);
  }

  getKpiName(param: IParamKpiName) {
    return this.baseService.postData(`${ENDPOINT.report.kpiName}`, param, undefined, CALL_ANALYSIS_TIMEOUT);
  }

  getExportQueue() {
    return this.baseService.getData(`${ENDPOINT.report.callAnalysisToolReport}/list-jobs`);
  }

  exportCallAnalysis(queryParam: {}) {
    return this.baseService.postData(`${ENDPOINT.report.callAnalysisToolReport}/export`, queryParam, undefined, CALL_ANALYSIS_TIMEOUT);
  }
}
