import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { Subject, Subscription, timer } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';

import { v4 as uuidv4 } from 'uuid';

import { startOfToday, subDays } from 'date-fns';

import { TranslateService } from '@ngx-translate/core';

import { DOWNLOAD } from '@shared/constant/download';
import { ViolationByAgentReportService } from '@admin/services/report-internal/violation-by-agent-report.service';
import { AttitudeKwBucketService } from '@admin/services/report-internal/attitude-kw-bucket.service';
import { DataFormService } from '@admin/services/report-internal/dataForm.service';
import { DownloadService } from '@shared/service/download.service';
import { FormatTimeService } from '@shared/service/formatTime.service';
import { OrganizationsGlobalService } from '@shared/service/organizations-global.service';
import { SocketService } from '@shared/service/socket.service';
import { ValidatorFormService } from '@shared/service/validatorForm.service';
import { RIGHTS_CODE } from '@shared/constant/rights-code';
import { PermissionsService } from '@shared/service/permission.service';

@Component({
  selector: 'app-search-violation-by-agent',
  templateUrl: './search-violation-by-agent.component.html',
  styleUrls: ['./search-violation-by-agent.component.scss']
})
export class SearchViolationByAgentComponent implements OnInit, OnDestroy {
  @Output() dataSubmitForm = new EventEmitter<any>();
  @Output() resetData = new EventEmitter<any>();
  @Input() lastSyncAt: string;
  RIGHTS_CODE = RIGHTS_CODE;
  searchViolationForm: FormGroup;
  submitted = false;
  isCheckEngine = true;

  listProduct: string[] = [];
  listAgency: string[] = [];
  listBucket: string[] = [];
  listTeamLeader: string[] = [];
  listUnitHead: string[] = [];
  listKeyWordVersion: number[] = [];
  isShowFilter = false;

  destroyObservable = new Subject<any>();
  exportId = uuidv4();
  exportParams = {};

  defaultEndDate = startOfToday();
  defaultStartDate = subDays(this.defaultEndDate, 6);

  dataOrganizations: string[];
  isDisabledKW = false;
  private subscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private socketService: SocketService,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
    public formatTimeService: FormatTimeService,
    public downloadService: DownloadService,
    private validatorFormService: ValidatorFormService,
    private violationByAgentReportService: ViolationByAgentReportService,
    private attitudeKwBucketService: AttitudeKwBucketService,
    private dataFormService: DataFormService,
    private organizationsGlobalService: OrganizationsGlobalService,
    public permissionsService: PermissionsService
  ) {}

  get f(): { [key: string]: AbstractControl } {
    return this.searchViolationForm.controls;
  }

  ngOnInit(): void {
    this.initSearchViolationForm();
    this.checkOrganizations();
    this.handleAutoGetDataSelect('kwVersion');
    this.initDownloadService();
  }

  initSearchViolationForm(): void {
    this.searchViolationForm = this.formBuilder.group({
      contactDate: [null],
      agentId: [null, [this.validatorFormService.commaSeparatedStringsValidator]],
      products: [[]],
      agency: [[]],
      bucket: [[]],
      teamLeader: [[]],
      unitHead: [[]],
      kwVersion: [null]
    });
    this.searchViolationForm.patchValue({
      contactDate: [this.defaultStartDate, this.defaultEndDate]
    });
  }

  getValueFilterAdvanced() {
    const newParam = {
      fields: ['agency', 'bucket', 'team_leader', 'unit_head', 'product', 'keyword_version'],
      engineIds: this.dataOrganizations
    };
    const dataForm = this.dataFormService.getDataFormViolationAgent(this.searchViolationForm, newParam);
    const params: any = this.dataFormService.removeEmptyValues(dataForm);
    this.dataFormService.getValueFilterAdvanced(params).subscribe({
      next: (res: any) => {
        this.listAgency = res.data.filterValues?.['agency'];
        this.listBucket = res.data.filterValues?.['bucket'];
        this.listTeamLeader = res.data.filterValues?.['team_leader'];
        this.listUnitHead = res.data.filterValues?.['unit_head'];
        this.listProduct = res.data.filterValues?.['product'];
        this.listKeyWordVersion = res.data.filterValues?.['keyword_version'];
      },
      error: (error: any) => {
        this.listAgency = [];
        this.listProduct = [];
        this.listBucket = [];
        this.listUnitHead = [];
        this.listTeamLeader = [];
        this.listKeyWordVersion = [];
      }
    });
  }

  emitDataForm(): void {
    this.getValueFilterAdvanced();
    const newParam = {
      page: 1,
      engineIds: this.dataOrganizations
    };
    const dataForm = this.dataFormService.getDataFormViolationAgent(this.searchViolationForm, newParam);
    this.dataSubmitForm.emit(dataForm);
  }

  checkOrganizations() {
    this.subscription = this.organizationsGlobalService.valueOrganizations$.pipe(takeUntil(this.destroyObservable)).subscribe({
      next: result => {
        if (result.org) {
          this.dataOrganizations = result.org;
          this.isDisabledKW = result.org.length > 1 ? true : false;
          if (result.org.length > 1) {
            this.searchViolationForm.controls['kwVersion'].patchValue(null);
          } else {
            this.emitDataForm();
          }
        }
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    this.emitDataForm();
  }

  handleAutoGetDataSelect(selectForm: string): void {
    this.searchViolationForm
      ?.get(selectForm)
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe(() => {
        this.emitDataForm();
      });
  }

  handleShowFilter() {
    if (this.isCheckEngine) {
      this.isShowFilter = !this.isShowFilter;
    }
  }

  resetForm() {
    this.submitted = false;
    const defaultValues = {
      contactDate: [this.defaultStartDate, this.defaultEndDate],
      agentId: '',
      showBy: [],
      products: [],
      agency: [],
      bucket: [],
      teamLeader: [],
      unitHead: [],
      kwVersion: null
    };
    this.searchViolationForm.patchValue(defaultValues);
    this.submitted = false;
  }

  setupParams() {
    const newParam = {
      page: 1,
      engineIds: this.dataOrganizations
    };
    const dataForm = this.dataFormService.getDataFormViolationAgent(this.searchViolationForm, newParam);
    return dataForm;
  }

  initDownloadService() {
    this.downloadService.isDownloadHandling = false;
    this.downloadService.downloadFileObservable.pipe(takeUntil(this.destroyObservable)).subscribe(data => {
      this.downloadService.handleDownloadData(data);
      this.socketService.reportLeaveRoom(this.exportId);
      timer(DOWNLOAD.DELAY_TIME)
        .pipe(take(1))
        .subscribe(() => {
          this.downloadService.isDownloadHandling = false;
        });
    });
  }

  async exportToExcel() {
    this.socketService.reportEmitRoom(this.exportId);
    this.exportParams = this.setupParams();
    if (this.exportId) {
      this.exportParams['exportId'] = this.exportId;
    }
    this.violationByAgentReportService.exportViolationSummaryByAgentReport(this.exportParams).subscribe({
      next: (res: any) => {
        this.downloadService.isDownloadHandling = true;
      },
      error: (err: any) => {
        this.notificationService.create(
          'error',
          this.translateService.instant('report.export.title'),
          this.translateService.instant('report.export.error')
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyObservable.next(null);
    this.destroyObservable.complete();
    this.socketService.reportLeaveRoom(this.exportId);
    this.downloadService.isDownloadHandling = false;
    this.subscription.unsubscribe();
  }
}
