import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class WaveformAudioService {
  private currentTimeSubject = new BehaviorSubject<number>(0);

  setCurrentTime(currentTime: number) {
    this.currentTimeSubject.next(currentTime);
  }

  getCurrentTime() {
    return this.currentTimeSubject.asObservable();
  }
}
