import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { NgZorroAppModule } from '@ng-zorro/ng-zorro-app.module';

@NgModule({
  declarations: [LoaderComponent],
  imports: [CommonModule, NgZorroAppModule],
  exports: [LoaderComponent]
})
export class LoaderModule {}
