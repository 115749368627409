import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ConvertFileZipService {
  constructor(private http: HttpClient) {}

  downloadZipFileFromString(base64String: string, nameFile: string) {
    const decodedString = atob(base64String);
    const binaryArray = new Uint8Array(decodedString.length);
    for (let i = 0; i < decodedString.length; i++) {
      binaryArray[i] = decodedString.charCodeAt(i);
    }
    const blob = new Blob([binaryArray], { type: 'application/zip' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${nameFile}.zip`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  downloadAudioZip(audioData: { fileUrl: string; id: string; name: string; callId: string }[]) {
    const zip = new JSZip();
    const requests = audioData.map(({ fileUrl, name }) => {
      return this.http.get(fileUrl, { responseType: 'arraybuffer' });
    });
    forkJoin(requests).subscribe(dataArray => {
      dataArray.forEach((data, index) => {
        zip.file(`${audioData[index].callId}.mp3`, data, { binary: true });
      });
      zip.generateAsync({ type: 'blob' }).then(blob => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Audio.zip';
        link.click();
      });
    });
  }

  saveExcelFile(fileBuffer: string, fileName: string) {
    const dataString = 'data:application/octet-stream;base64,' + fileBuffer;
    saveAs(dataString, fileName);
  }
}
